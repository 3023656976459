/**
 * 2点の距離の差 メートル
 * @param lat1
 * @param lng1
 * @param lat2
 * @param lng2
 * @returns
 */
const hubeny = (inputlat1: number, inputlng1: number, inputlat2: number, inputlng2: number) => {
  function rad(deg: number) {
    return (deg * Math.PI) / 180;
  }
  // degree to radian
  const lat1 = rad(inputlat1);
  const lng1 = rad(inputlng1);
  const lat2 = rad(inputlat2);
  const lng2 = rad(inputlng2);

  // 緯度差
  const latDiff = lat1 - lat2;
  // 経度差算
  const lngDiff = lng1 - lng2;
  // 平均緯度
  const latAvg = (lat1 + lat2) / 2.0;
  // 赤道半径
  const a = 6378137.0;
  // 第一離心率^2
  const e2 = 0.00669438002301188;
  // 赤道上の子午線曲率半径
  const a1e2 = 6335439.32708317;

  const sinLat = Math.sin(latAvg);
  const W2 = 1.0 - e2 * (sinLat * sinLat);

  // 子午線曲率半径M
  const M = a1e2 / (Math.sqrt(W2) * W2);
  // 卯酉線曲率半径
  const N = a / Math.sqrt(W2);

  const t1 = M * latDiff;
  const t2 = N * Math.cos(latAvg) * lngDiff;
  return Math.sqrt(t1 * t1 + t2 * t2);
};
export default hubeny;
