import ReactDOM from 'react-dom/client';

import './index.css';
import "normalize.css"; 
import reportWebVitals from './reportWebVitals';
import Routing from './Routing';
import './i18n/configs';

// npm start

// Something is already running on port 3000. Probably:
// というエラーが出た場合
// npx kill-port 3000

// httpsでの接続
// ngrok http 3000 --domain=arframe-stripe.ngrok.dev

// package.json
//  "react-scripts": "5.0.1" を devDependencies に移動
// https://github.com/facebook/create-react-app/issues/11174

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Routing />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
