import './CodeForm.scss';

import { TextField, Button, Card, Alert } from '@aws-amplify/ui-react';
import { API} from 'aws-amplify';
import { useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

// 画像
import close from '../img/components/preview/close.png';

import GetItemResult from './GetItemResult';

type CodeFormProps = {
  hideCodeForm: () => void;
};

const CodeForm = (props: CodeFormProps) => {
  const { hideCodeForm } = props;
  const { ipParam } = useParams();
  // 検索パラメータ
  const [ipid, setIpid] = useState(ipParam);
  const [code, setCode] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [itemResult , setItemResult] = useState(false);

  const onSubmit = async () => {
    setBtnDisabled(true);
    setErrorMessage('');
    try {
      const result = await API.post('myitem', '/create-item-by-secret', {
        body: { ipid, code },
      })
      if (result.success) {
        setItemResult(true);
        setCode('');
      } else if (result.error === 'すでに登録されています。') {
        setErrorMessage('すでに取得済みです。');
      } else if (result.error === 'コードの有効期限が切れています。') {
        setErrorMessage('コードの有効期限が切れています。');
      } else {
        setErrorMessage('対象コンテンツまたはシークレットコードが正しくありません。');
      }
    } catch {
        setErrorMessage('不明なエラーが発生しました。');
    } finally {
      setBtnDisabled(false);
    }
  }

  const hideGetItemResult = () => {
    if (itemResult) {
      window.location.reload();
    } else {
      setItemResult(false);
    }
  }

  return (
    <div>
    {itemResult && <GetItemResult isOpen={itemResult} hideGetItemResult={hideGetItemResult} />}
    {!itemResult &&
    <Modal
      isOpen
      onRequestClose={hideCodeForm}
      style={{
        overlay: {
          backgroundColor: 'transparent',
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          inset: '0',
          backgroundColor: 'white',
          border: 'none',
          position: 'relative',
        },
      }}
    >
      <div className="CodeForm">
        {!itemResult && (
          <button type="button" className="CodeForm__close" onClick={hideCodeForm}>
            <img src={close} alt="シークレットコード入力画面を閉じるボタン" />
          </button>
        )}
        <h2 className='title'>
          シークレットコードの入力
        </h2>
        <Card variation="elevated">
          <TextField
            value={ipid}
            isRequired
            onChange={(e) => setIpid(e.target.value)}
            name="ipid"
            label="対象コンテンツ"
            placeholder="vv"
            marginBottom={20}
          />
          <TextField
            value={code}
            onChange={(e) => setCode(e.target.value)}
            name="code"
            label="シークレットコード"
            placeholder="コードを半角英数字で入力"
            marginBottom={20}
          />
          <Button variation="primary" disabled={btnDisabled} onClick={() => onSubmit()}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="click"
            data-amplify-analytics-attrs={`ipid:${ipid},page:codeForm,type:submit`}
          >ゲットする</Button>
        </Card>
      </div>
      {errorMessage && <Alert variation="error">{errorMessage}</Alert>}
    </Modal>
    }
    </div>
  );
};
export default CodeForm;
