// CSS
import './Start.scss';
import { CognitoUser } from '@aws-amplify/auth';
import { CheckboxField } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import box from '../img/components/start/flame_landscape.png';
import dot from '../img/components/start/oshidori_dotbird.png';
import notice from '../img/components/start/oshidori_hukidashi.png';
import logo from '../img/components/start/oshidori_logo.png';
import registrationButton from '../img/components/start/registration.png';
import startButton from '../img/components/start/start.png';

import LoggedIn from './LoggedIn';
import { UserContext } from './providers/UserProvider';
import Registration from './Registration';

type StartProps = {
  setStarted: (started: boolean) => void;
  loadItems: (contents: string) => void;
};

const Start = (props: StartProps) => {
  const { setStarted, loadItems } = props;
  const navigate = useNavigate()

  // ユーザーの取得
  const { setUser } = useContext(UserContext);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLoggedIn , setShowLoggedIn] = useState(false);
  const [tempUser, setTempUser] = useState();

  const [agree, setAgree] = useState(false);
  const { ipParam } = useParams();

  useEffect(() => {
    // 画像を読み込む
    if (ipParam) {
      loadItems(ipParam);
    }
    // ログイン、ログアウトを監視する
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          if (data) {
            // 認証後のリダイレクトに含まれるコンテンツデータの画像をロードする
            loadItems(data);
            navigate(`/contents/${data}`, {replace: true})
          }
          break;
        case 'signIn':
          setShowLoggedIn(true);
          setTempUser(data);
          break;
        case 'signOut':
          setUser(null);
          break;
        default:
          break;
      }
    });

    const checkStatus = async () => {
      // ログイン済みの場合はスタート画面をスキップする
      try {
        const user: CognitoUser | any = await Auth.currentAuthenticatedUser();
        if (user) {
          setUser(user);
        }
      } catch (e) {
        // DO NOTHING
      }
    };

    checkStatus();
    return unsubscribe;
  }, []);

  const hideRegistration = () => {
    setShowRegistration(false);
  };

  const hideLoggedIn = () => {
    setShowLoggedIn(false);
    if (tempUser) {
      setUser(tempUser);
    }
  };

  const tapAgree = () => {
    setAgree(!agree);
  };

  return (
    <div className="Start">
      {showLoggedIn && <LoggedIn isOpen={showLoggedIn} hideLoggedIn={hideLoggedIn}/>}
      {showRegistration && <Registration hideRegistration={hideRegistration} />}
      <div className="Start__block">
        <div className="Start__logo">
          <img src={logo} alt="推しドリロゴ" />
        </div>
        <div className="Start__contents">
          <div className="Start__contents-background">
            <img src={box} alt="開始ブロック" />
          </div>
          <div className="Start__items">
            <div className="Start__item">
              <button
                type="button"
                className="Start__registration-button Start__button"
                onClick={() => setShowRegistration(true)}
                disabled={!agree}
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="click"
                data-amplify-analytics-attrs={`ipid:${ipParam || process.env.REACT_APP_SUPERADMIN},page:start,type:registration`}
                style={{ backgroundImage: `${registrationButton}`}}
              >
                {' '}
              </button>
            </div>
            <div className="Start__item">
              <button 
                type="button"
                className="Start__start-button Start__button"
                disabled={!agree}
                onClick={() => setStarted(true)}
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="click"
                data-amplify-analytics-attrs={`ipid:${ipParam || process.env.REACT_APP_SUPERADMIN},page:start,type:guest`}
                style={{ backgroundImage: `${startButton}`}}
              >
                {' '}
              </button>
            </div>
            <div className="Start__item">
              <div className="Start__terms">
                <CheckboxField
                  checked={agree}
                  onClick={() => {
                    setAgree(!agree);
                  }}
                  label=""
                  name="term"
                  value="agree"
                  size="large"
                  className="Start__terms-check"
                />
                <a rel="noopener noreferrer" href="/terms" target="_blank">
                利用規約
                </a>
                <button type='button' onClick={tapAgree} className='Start__agree'>に同意する</button>
              </div>
            </div>
            <div className="Start__item">
              <div className="Start__notice">
                <img src={notice} alt="会員登録をすると有料アイテムの購入やマイアイテムの機能が使えます" />
              </div>
              <div className="Start__dot">
                <img src={dot} alt="ぴよぴよ" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Start;
