import { CognitoUser } from '@aws-amplify/auth';
import { API, graphqlOperation, Auth } from 'aws-amplify';

import { getUsers } from '../graphql/queries';
import { Users } from '../models';

// Dynamoに登録してあるユーザー情報を取得する
export const getDynamoUser = async () => {
  try {
    // 取得できるユーザーを限定
    const loginCognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    // DynamoDBから権限を取得する
    const loginUserResult: any = await API.graphql(
      graphqlOperation(getUsers, {
        id: loginCognitoUser.getUsername(),
      })
    );
    // DynamoDBに情報がある場合、isAdminがついているかチェックする
    if (loginUserResult.data && loginUserResult.data.getUsers) {
      return loginUserResult.data.getUsers as Users;
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      alert(`${e.message} ログアウトを行います。`)
      setTimeout(() => {
        document.location = `/${process.env.REACT_APP_ADMINPAGE}`;
      }, 500);
    } else {
      alert('重複ログインが発生しました。恐らく2つ以上のブラウザで推しドリを開いています。ログアウトを行います。')
      setTimeout(() => {
        document.location = `/${process.env.REACT_APP_ADMINPAGE}`;
      }, 500);
    }
  }
  return null;
};

// アドミンかどうかの確認
export const isAdmin = async () => {
  const user: Users | null = await getDynamoUser();
  if (user && user.isAdmin) {
    return true;
  }
  return false;
};

// スーパーアドミンかどうかの確認
export const isSuperAdmin = async () => {
  const user: Users | null = await getDynamoUser();
  if (user && user.isAdmin && user.ipid === process.env.REACT_APP_SUPERADMIN) {
    return true;
  }
  return false;
};

export const isAlphaNum = (str: string) => /^[a-zA-Z0-9]+$/.test(str);

export const isNum = (str: string) => /^[0-9]+$/.test(str);

/**
 * 文字の省略
 * @param str 省略対象
 * @param length 最大サイズ
 * @returns 
 */
export const cutString = (str:string, length: number) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
}