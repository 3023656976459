/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrders = /* GraphQL */ `
  mutation CreateOrders(
    $input: CreateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    createOrders(input: $input, condition: $condition) {
      id
      usersID
      customerID
      ipid
      name
      thumbnail
      productID
      amount
      orderStatus
      createdAt
      updatedAt
      settionID
      groups
    }
  }
`;
export const updateOrders = /* GraphQL */ `
  mutation UpdateOrders(
    $input: UpdateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    updateOrders(input: $input, condition: $condition) {
      id
      usersID
      customerID
      ipid
      name
      thumbnail
      productID
      amount
      orderStatus
      createdAt
      updatedAt
      settionID
      groups
    }
  }
`;
export const deleteOrders = /* GraphQL */ `
  mutation DeleteOrders(
    $input: DeleteOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    deleteOrders(input: $input, condition: $condition) {
      id
      usersID
      customerID
      ipid
      name
      thumbnail
      productID
      amount
      orderStatus
      createdAt
      updatedAt
      settionID
      groups
    }
  }
`;
export const createIPInfo = /* GraphQL */ `
  mutation CreateIPInfo(
    $input: CreateIPInfoInput!
    $condition: ModelIPInfoConditionInput
  ) {
    createIPInfo(input: $input, condition: $condition) {
      id
      name
      ipid
      company
      contract
      roy
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const updateIPInfo = /* GraphQL */ `
  mutation UpdateIPInfo(
    $input: UpdateIPInfoInput!
    $condition: ModelIPInfoConditionInput
  ) {
    updateIPInfo(input: $input, condition: $condition) {
      id
      name
      ipid
      company
      contract
      roy
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const deleteIPInfo = /* GraphQL */ `
  mutation DeleteIPInfo(
    $input: DeleteIPInfoInput!
    $condition: ModelIPInfoConditionInput
  ) {
    deleteIPInfo(input: $input, condition: $condition) {
      id
      name
      ipid
      company
      contract
      roy
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      name
      email
      isAdmin
      ipid
      createdAt
      updatedAt
      customerID
      Orders {
        nextToken
      }
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      name
      email
      isAdmin
      ipid
      createdAt
      updatedAt
      customerID
      Orders {
        nextToken
      }
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      name
      email
      isAdmin
      ipid
      createdAt
      updatedAt
      customerID
      Orders {
        nextToken
      }
    }
  }
`;
export const createItems = /* GraphQL */ `
  mutation CreateItems(
    $input: CreateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    createItems(input: $input, condition: $condition) {
      id
      ipid
      type
      name
      main
      thumbnail
      top
      bottom
      faceFlg
      premierFlg
      geoFlg
      size
      longitude
      latitude
      createdAt
      updatedAt
      dataType
      startDate
      endDate
      price
      productID
      groups
      jan
      getFlg
    }
  }
`;
export const updateItems = /* GraphQL */ `
  mutation UpdateItems(
    $input: UpdateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    updateItems(input: $input, condition: $condition) {
      id
      ipid
      type
      name
      main
      thumbnail
      top
      bottom
      faceFlg
      premierFlg
      geoFlg
      size
      longitude
      latitude
      createdAt
      updatedAt
      dataType
      startDate
      endDate
      price
      productID
      groups
      jan
      getFlg
    }
  }
`;
export const deleteItems = /* GraphQL */ `
  mutation DeleteItems(
    $input: DeleteItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    deleteItems(input: $input, condition: $condition) {
      id
      ipid
      type
      name
      main
      thumbnail
      top
      bottom
      faceFlg
      premierFlg
      geoFlg
      size
      longitude
      latitude
      createdAt
      updatedAt
      dataType
      startDate
      endDate
      price
      productID
      groups
      jan
      getFlg
    }
  }
`;
export const createLogs = /* GraphQL */ `
  mutation CreateLogs(
    $input: CreateLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    createLogs(input: $input, condition: $condition) {
      id
      ipid
      page
      func
      message
      memo
      createdAt
      updatedAt
    }
  }
`;
export const updateLogs = /* GraphQL */ `
  mutation UpdateLogs(
    $input: UpdateLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    updateLogs(input: $input, condition: $condition) {
      id
      ipid
      page
      func
      message
      memo
      createdAt
      updatedAt
    }
  }
`;
export const deleteLogs = /* GraphQL */ `
  mutation DeleteLogs(
    $input: DeleteLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    deleteLogs(input: $input, condition: $condition) {
      id
      ipid
      page
      func
      message
      memo
      createdAt
      updatedAt
    }
  }
`;
export const createMyItems = /* GraphQL */ `
  mutation CreateMyItems(
    $input: CreateMyItemsInput!
    $condition: ModelMyItemsConditionInput
  ) {
    createMyItems(input: $input, condition: $condition) {
      id
      ipid
      usersID
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const updateMyItems = /* GraphQL */ `
  mutation UpdateMyItems(
    $input: UpdateMyItemsInput!
    $condition: ModelMyItemsConditionInput
  ) {
    updateMyItems(input: $input, condition: $condition) {
      id
      ipid
      usersID
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const deleteMyItems = /* GraphQL */ `
  mutation DeleteMyItems(
    $input: DeleteMyItemsInput!
    $condition: ModelMyItemsConditionInput
  ) {
    deleteMyItems(input: $input, condition: $condition) {
      id
      ipid
      usersID
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const createItemSecrets = /* GraphQL */ `
  mutation CreateItemSecrets(
    $input: CreateItemSecretsInput!
    $condition: ModelItemSecretsConditionInput
  ) {
    createItemSecrets(input: $input, condition: $condition) {
      id
      ipid
      code
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const updateItemSecrets = /* GraphQL */ `
  mutation UpdateItemSecrets(
    $input: UpdateItemSecretsInput!
    $condition: ModelItemSecretsConditionInput
  ) {
    updateItemSecrets(input: $input, condition: $condition) {
      id
      ipid
      code
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteItemSecrets = /* GraphQL */ `
  mutation DeleteItemSecrets(
    $input: DeleteItemSecretsInput!
    $condition: ModelItemSecretsConditionInput
  ) {
    deleteItemSecrets(input: $input, condition: $condition) {
      id
      ipid
      code
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const createGenerateItems = /* GraphQL */ `
  mutation CreateGenerateItems(
    $input: CreateGenerateItemsInput!
    $condition: ModelGenerateItemsConditionInput
  ) {
    createGenerateItems(input: $input, condition: $condition) {
      id
      ipid
      name
      back
      front
      geoFlg
      longitude
      latitude
      startDate
      endDate
      groups
      createdAt
      updatedAt
    }
  }
`;
export const updateGenerateItems = /* GraphQL */ `
  mutation UpdateGenerateItems(
    $input: UpdateGenerateItemsInput!
    $condition: ModelGenerateItemsConditionInput
  ) {
    updateGenerateItems(input: $input, condition: $condition) {
      id
      ipid
      name
      back
      front
      geoFlg
      longitude
      latitude
      startDate
      endDate
      groups
      createdAt
      updatedAt
    }
  }
`;
export const deleteGenerateItems = /* GraphQL */ `
  mutation DeleteGenerateItems(
    $input: DeleteGenerateItemsInput!
    $condition: ModelGenerateItemsConditionInput
  ) {
    deleteGenerateItems(input: $input, condition: $condition) {
      id
      ipid
      name
      back
      front
      geoFlg
      longitude
      latitude
      startDate
      endDate
      groups
      createdAt
      updatedAt
    }
  }
`;
