import loadingImage from '../img/components/loading/loading.gif';
import './Loading.scss';

const Loading = () => (
  <div className="Loading">
    <img src={loadingImage} alt="ローディング中" />
  </div>
);

export default Loading;
