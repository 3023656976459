// LOGIC
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Button, CheckboxField } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

// CSS
import './Registration.scss';
// 画像
import lineButtonDisabled from '../img/components/registration/line-button-disabled.png';
import lineButton from '../img/components/registration/line-button.png';
import background from '../img/components/start/background.png';
import logo from '../img/components/start/oshidori_logo.png';


type RegistrationProps = {
  hideRegistration: () => void;
};

const Registration = (props: RegistrationProps) => {
  const { hideRegistration } = props;

  const [agree, setAgree] = useState(false);
  const { ipParam } = useParams();

  const onGoogleButtonClick = () => {
    if (agree) {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
        customState: ipParam,
      });
    }
  };

  const onLINEButtonClick = () => {
    if (agree) {
      // eslint-disable-next-line
      // @ts-ignore
      Auth.federatedSignIn({provider: 'LINE', customState: ipParam});
    }
  };

  return (
    <Modal
      isOpen
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          zIndex: 3,
          bottom: 0,
          inset: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${background})`,
        },
        content: {
          inset: '0',
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
        },
      }}
    >
      <div className="Registration">
        <div className="Registration__block">
          <div className="Registration__logo">
            <img className="Registration__logo-img" src={logo} alt="推しドリロゴ" />
          </div>
          <div className="Registration__header">
            <h2>会員登録 / ログイン</h2>
            <hr />
          </div>
          <div className="Registration__contents">
            <p>会員登録で、限定スタンプの購入が可能になります！</p>
          </div>
          <div className="Registration__notice">
            <p>下記にて許可をいただいた場合、メールアドレスを取得します。<br/>
                メールアドレスは下記の目的以外では使用いたしません。
            </p>
            <p>■ ユーザーIDとしてのアカウントの管理</p>
          </div>
          <div className="Registration__terms">
            <CheckboxField
              checked={agree}
              onClick={() => {
                setAgree(!agree);
              }}
              label="メールアドレスの取得に同意する"
              name="term"
              value="agree"
              size="large"
              className="Registration__terms-check"
            />
          </div>
          <div className="Registration__buttons">
            <div className="Registration__item">
              <Button disabled={!agree} onClick={onLINEButtonClick} className="Registration__button-line"
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="click"
                data-amplify-analytics-attrs={`ipid:${ipParam},page:registration,type:line`}
              >
                <div className="Registration__button-line-image">
                  {agree ? (
                    <img src={lineButton} alt="LINEログインボタン" />
                  ): (
                    <img src={lineButtonDisabled} alt="LINEログインボタン非活性" />
                  )}
                </div>
                <p>LINEでログイン</p>
              </Button>
            </div>

            <Button className="Registration__button-close" onClick={hideRegistration}>
              キャンセル
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Registration;
