import './AngleNotice.scss';
import Notice from './img/components/angle-notice/portrait-natural.png';

const AngleNotice = () => (
  <div className="AngleNotice">
    <div className="AngleNotice__content">
      <img src={Notice} alt="縦向きでご利用ください" />
    </div>
  </div>
);

export default AngleNotice;
