import { useRef, useEffect } from "react";

type VideoProps = {
  src: string;
  isMuted: boolean;
};

const Video = (props: VideoProps) => {
  const {src, isMuted } = props;
  const refVideo = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (refVideo.current) {
      if (isMuted) {
          //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
          refVideo.current.defaultMuted = true;
          refVideo.current.muted = true;
      }
      refVideo.current.src = src;
    }
  }, [src]);

  return (
    <video
      className='PreviewVideo__video'
      ref={refVideo}
      autoPlay
      playsInline //FIX iOS black screen
      controls
    />
  );
};

export default Video;