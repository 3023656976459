import Modal from 'react-modal';

import loading from '../img/components/controller/loading.png';

type PrepareProps = {
  isOpen: boolean;
};

const Prepare = (props: PrepareProps) => {
  const { isOpen } = props;
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          backgroundColor: 'black',
          opacity: 0.9,
          display: 'flex',
          alignItems: 'center',
        },
        content: {
          inset: '0',
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
        },
      }}
    >
      <div>
        <img width="100%" src={loading} alt="ローディング中" />
      </div>
    </Modal>
  );
};
export default Prepare;
