import './OriginalButton.scss';

type OriginalButtonProps = {
	clickEvent: ((obj?: any) => void);
	target: any;
	text: string;
}

const OriginalButton = (props: OriginalButtonProps) => {
  const { clickEvent, target, text } = props;
	return (
		<button 
			type="button"
			className='OriginalButton'
			onClick={() => target ? clickEvent(target) : clickEvent() } 
		>
			<span className="OriginalButton__text">{text}</span>
		</button>
	)
}

export default OriginalButton;