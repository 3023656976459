/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "30358f0fc14044daa4b844f44b6cfb01",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "30358f0fc14044daa4b844f44b6cfb01",
            "region": "ap-northeast-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://piivk3y2af.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        },
        {
            "name": "myitem",
            "endpoint": "https://xwq5s71qzc.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        },
        {
            "name": "stripe",
            "endpoint": "https://evmh9jbd2b.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://c7u355g7hrgujhjwa3tihwukwy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-qqptwdg3rjhq3bptjmr2cmtaz4",
    "aws_cognito_identity_pool_id": "ap-northeast-1:e0404725-dcb8-4492-a249-1e4b201b8eef",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Ki12brZsG",
    "aws_user_pools_web_client_id": "1rbh9skm7v2n85jacidoj9ollr",
    "oauth": {
        "domain": "p7fl8uyfyksd-master.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://oshidori.photo",
        "redirectSignOut": "https://oshidori.photo",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "devarframe-storage62852-master",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "geo": {
        "amazon_location_service": {
            "region": "ap-northeast-1",
            "maps": {
                "items": {
                    "map027f49db-master": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map027f49db-master"
            }
        }
    }
};


export default awsmobile;
