import './Terms.scss';

const Terms = () => (
  <section className="Terms">
    <div className="Terms__block">
      <h1 className="title-block__info">利用規約</h1>
      <div className="section-block js-scroll-target">
        <div className="section-block">
          <p>
          この利用規約（以下「本規約」といいます。）は、株式会社ヴィレッジヴァンガードコーポレーション（以下「当社」といいます。）が提供するWebAR写真撮影サービス「推しドリ」（以下「本サービス」といいます。）の提供条件および当社とユーザーとの間の権利関係が定められています。<br/>
          <br/>
本サービスのご利用に際しては本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。なお、利用者が本サービスの利用を開始した時点をもって本規約に同意をしたものとみなします。
          </p>
        </div>
      </div>
      <div className="section-block js-scroll-target">
        <h4> 第1条（適用） </h4>
        <div className="section-block">
          <p>
          1. 本規約は、本サービスの提供条件および本サービスの利用に関する当社とユーザーとの権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。<br/>
2. 当社が、当社サイトまたは申込書その他で掲載する本サービスの利用に関する条件は、本規約の一部を構成するものとします。<br/>
3. 本規約の記載内容と、前項の条件が異なる場合は、本規約の内容が優先して適用されるものとします。<br/>
          </p>
        </div>
        <h4> 第2条（定義） </h4>
        <div className="section-block">
          <p>
          本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。<br/>
（1）「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。<br/>
（2）「当社」とは、株式会社ヴィレッジヴァンガードコーポレーションを意味します。<br/>
（3）「当社サイト」とは、当社が管理および運営するウェブサイトであり、ドメインに「oshidori.photo」の文字列を含むウェブサイトを意味します。<br/>
（4）「ユーザー」とは、登録の有無にかかわらず、本サービスを利用する個人または法人を意味します。<br/>
（5）「会員」とは、第3条（利用登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。<br/>
（6）「本サービス」とは、当社が提供する「推しドリ」という名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。<br/>
（7）「当社コンテンツ」とは、当社もしくは第三者に権利帰属し、当該第三者が当社に利用許諾した知的財産で、当社がユーザーに提供するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。<br/>
（8）「有償コンテンツ」とは、当社コンテンツのうち、当社が有償で会員に提供するコンテンツを意味します。<br/>
          </p>
        </div>
        <h4>第3条（会員登録） </h4>
        <div className="section-block">
          <p>
          1. 有償コンテンツや会員限定機能の利用を希望する者（以下「会員希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。<br/>
2. 当社は、当社の基準に従って、第１項に基づいて登録申請を行った会員希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の会員としての登録は、当社が本項の通知を行ったことをもって完了したものとします。<br/>
3. 当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録および再登録を拒否することがあり、またその理由について一切開示義務を負いません。<br/>
（1）当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合<br/>
（2）未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合<br/>
（3）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合<br/>
（4）過去当社との契約に違反した者またはその関係者であると当社が判断した場合<br/>
（5）第13条に定める措置を受けたことがある場合<br/>
（6）その他、登録を適当でないと当社が判断した場合<br/>
          </p>
        </div>
      </div>

      <div className="section-block js-scroll-target">
        <h4> 第4条（IDおよびパスワードの管理） </h4>
        <div className="section-block">
          <p>
          1.会員は、本サービスの利用に必要となる、SNSのユーザIDおよびパスワード等の管理責任を負い、当社は、SNSログインによりログインをした場合には、会員本人による使用とみなします。 なお、会員によるユーザーIDおよびパスワード等の管理不十分、使用上の過誤、第三者の使用等による損害等に関する一切の責任は会員が負うものとし当社は一切の責任を負いません。なお、各SNSのID情報は暗号化され各SNSサイトと連携されますので、SNSのログインパスワードを当サイトで保持することはありません。<br/>
2.会員は、SNSアカウントを第三者に利用させることまたは貸与、譲渡もしくは担保またはその他の処分等をすることはできないものとします。<br/>
3.会員は、SNSアカウントの盗難または第三者による使用もしくはそのおそれ等がある場合、速やかに当社に連絡し、当社の指示に従うものとします。<br/>
4. 当社は、前項に規定する盗難または第三者による使用もしくはそのおそれ等がある会員の利用を停止することができます。 なお、当該停止により会員および第三者に損害等が生じた場合でも、当社は一切の責任を負わないものとします。<br/>
          </p>
        </div>
        <h4> 第5条（本サービスの利用） </h4>
        <div className="section-block">
          <p>
          1. ユーザーは、本規約の目的の範囲内でかつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。<br/>
2. ユーザーは、本サービスにおいて提供される当社コンテンツの品質、正確性、信頼性、有用性等については自ら判断するものとし、これらを自己の責任において利用することにあらかじめ同意するものとします。<br/>
3. ユーザーは、本サービスを利用する前に、本サービスについて情報を十分に確認してから本サービスの利用を開始するものとします。<br/>
4. 当社は、本サービスにおいて提供されるコンテンツの真実性、正確性、確実性、信頼性、有益性等については、一切保証しないものとします。<br/>
5. 当社は、ユーザーとユーザーの顧客等の第三者との間で相互に提供されるいかなる情報、ファイルおよび物品等について一切責任を負わないものとし、ユーザーは、これらの情報、ファイルまたは物品等の提供によってユーザーに生じたいかなる種類の損害または損失等からも当社を免責することにあらかじめ同意するものとします。<br/>
          </p>
        </div>
      </div>
      <div className="section-block js-scroll-target">
        <h4> 第6条（当社コンテンツ） </h4>
        <div className="section-block">
          <p>
          1. 当社は、ユーザーに対して、当社コンテンツの知的財産権につき、本サービスを利用するために必要な限度でその利用等を許諾するものとします。<br/>
2. ユーザーは、当社コンテンツを、本サービスが予定している利用態様を超えて利用（複製、送信、転載、改変を含みます。）してはいけないものとします。<br/>
3.ユーザーは、利用料、利用期間その他利用条件が別途当社ウェブサイト上で定められた当社コンテンツを利用する場合、かかる利用条件に従うものとします。当社ウェブサイト上で「購入」「販売」等の表示がされている場合であっても、当社がユーザーに対して提供する当社コンテンツに関する知的財産権その他権利はユーザーには移転せず、本サービスを利用するために必要な限度での利用を許諾するのみとします。<br/>
4. 有償コンテンツにつき、会員が当社ウェブサイト上に記載する料金を支払ったことを条件として、当社は有償コンテンツの知的財産権につき、本サービスを利用するために必要な限度でその利用等を許諾するものとします。<br/>
5. 有償コンテンツ利用料金の支払方法は、会員が選択した決済サービスを利用するものとします。<br/>
6. 会員は、支払済みの有償コンテンツ利用料金について、本規約に特別に定める場合を除き、当社に対し返金を求めることができないものとします。<br/>
          </p>
        </div>
        <h4> 第7条（電気通信回線） </h4>
        <div className="section-block">
          <p>
          1. ユーザーが使用する端末機器から当社サイトに接続する電気通信回線は、ユーザーの責任と費用負担において、確保、維持されるものとし、当社は一切の責任を負いません。<br/>
2. ユーザーは、本サービスを利用するにあたり必要となる一切の通信費用を負担するものとします。<br/>
          </p>
        </div>
        <h4> 第8条（委託）</h4>
        <div className="section-block">
          <p>
          当社は本サービスの提供に関する業務の全部もしくは一部をユーザーの承諾なしに、第三者に委託することができます。ただし、その場合、当社は責任をもって委託先を管理するものとします。
          </p>
        </div>
      </div>
      <div className="section-block js-scroll-target">
        <h4> 第9条（禁止事項） </h4>
        <div className="section-block">
          <p>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。違反行為を理由として損害を生じても当社は一切の責任を負わないものとします。<br/>
（1）本規約に違反する行為<br/>
（2）法令に違反する行為または犯罪行為に関連する行為<br/>
（3）当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為<br/>
（4）公序良俗に反する行為<br/>
（5）当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為<br/>
（6）本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者を含む第三者に送信すること<br/>
・過度に暴力的または残虐な表現を含む情報<br/>
・コンピュータ・ウィルスその他の有害なコンピュータプログラムを含む情報<br/>
・当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報<br/>
・過度にわいせつな表現を含む情報および差別を助長する表現を含む情報<br/>
・自殺、自傷行為を助長する表現を含む情報<br/>
・薬物の不適切な利用を助長する表現を含む情報<br/>
・反社会的な表現を含む情報<br/>
・チェーンメール等の第三者への情報の拡散を求める情報<br/>
・他人に不快感を与える表現を含む情報<br/>
（7）本サービスのネットワークまたはシステム等に過度な負荷をかける行為<br/>
（8）当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為<br/>
（9）本サービスの運営を妨害するおそれのある行為<br/>
（10）本サービスを商業目的で使用する行為<br/>
（11）当社のネットワークまたはシステム等への不正アクセス<br/>
（12）第三者への成りすます行為<br/>
（13）本サービスの他の利用者のＩＤまたはパスワードを利用する行為<br/>
（14）本サービスの他の利用者の情報の取集<br/>
（15）当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為<br/>
（16）反社会的勢力等への利益供与<br/>
（17）前各号の行為を直接または間接に惹起し、または容易にする行為<br/>
（18）前各号の行為を試みること<br/>
（19）その他、当社が不適切と判断する行為<br/>
          </p>
        </div>
        <h4> 第10条（本サービスの提供の停止等） </h4>
        <div className="section-block">
          <p>
          1. 当社は、定期的にまたは必要に応じて、保守作業のために、本サービスを一時的に休止することができるものとします。<br/>
2. 当社は、保守作業を行う場合には、事前にユーザーに対してその旨を通知するものとします。ただし、緊急の場合には、事前の通知をすることなく本サービスを休止し、事後速やかにユーザーに通知するものとします。<br/>
3. 第１項に定めるほか、当社は、第三者による妨害行為等により本サービスの継続がユーザーに重大な支障を与えるおそれがあると判断される場合、その他やむを得ない事由がある場合にも、本サービスを一時的に休止することができるものとします。<br/>
4. 当社は、本条に基づいてなされた本サービスの休止によってユーザーに生じた不利益、損害について責任を負いません。<br/>
          </p>
        </div>
        <h4> 第11条（権利帰属） </h4>
        <div className="section-block">
          <p>
          １.当社サイトを利用して作成されたコンテンツ(以下、「完成コンテンツ」といいます。)の知的財産権は、従前より当社または当社以外の第三者に帰属するものを除き、完成コンテンツを作成したユーザーに帰属するものとします。なお、完成コンテンツを作成する際に使用した当社コンテンツの知的財産権は、作成した完成コンテンツ自体の知的財産権にかかわらず、提供元である当社若しくは第三者に属するものとし、ユーザーは本規約を遵守するものとし、当社若しくは第三者の知的財産権を侵害してはならないものとします。<br/>
２. 当社は、ユーザーに対し、当社コンテンツの知的財産権につき、本サービスを利用するために必要な限度でその利用等を許諾するものとします。ユーザーは理由の如何にかかわらず、当社の事前の同意なく、その限度を超えてかかるコンテンツを利用することはできず、かかるコンテンツの複製、改変、編集、削除等をしてはなりません。<br/>
          </p>
        </div>
        <h4>第12条（第三者の権利侵害）</h4>
        <div className="section-block">
          <p>
          当社は、クレーム等の発生が当社の責めに帰すべき事由に基づく場合を除き、本サービスの利用に関してユーザーと第三者との間で生じたクレーム等に関し一切の責任を負わないものとします。
          </p>
        </div>
        <h4> 第13条（利用制限および登録抹消） </h4>
        <div className="section-block">
          <p>
          １. 当社は、以下の場合には、事前の通知または催告することなく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、または会員としての登録を抹消することができるものとします。<br/>
（1）本規約のいずれかの条項に違反した場合<br/>
（2）登録事項に虚偽の事実があることが判明した場合<br/>
（3）第3条第3項各号に該当する場合<br/>
（4）その他、当社が本サービスの利用を適当でないと判断した場合<br/>
２. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。<br/>
          </p>
        </div>
        <h4> 第14条（退会） </h4>
        <div className="section-block">
          <p>
          １. 会員は、当社所定の手続の完了により、本サービスから退会し、自己の会員としての登録を抹消することができます。<br/>
２. 退会後の利用者情報の取扱いについては、第１８条の規定に従うものとします。<br/>
          </p>
        </div>
        <h4>第15条（本サービスの変更・終了）</h4>
        <div className="section-block">
          <p>
          1. 当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。<br/>
2. 当社が本サービスを終了する場合、当社はユーザーに事前に通知するものとします。<br/>
3. 当社は、本サービスの内容の変更および終了により、ユーザーまたは第三者が被ったいかなる不利益または損害について、一切の責任を負わないものとします。<br/>
          </p>
        </div>
        <h4>第16条（保証の否認および免責）</h4>
        <div className="section-block">
          <p>
          1. 当社は、本サービスにより提供するコンテンツの内容につきいかなる保証も行うものではありません。コンテンツの内容に誤りが発見された場合その他当社が必要と判断した場合において、当社が情報のアップデートをする場合がありますが、かかるアップデートを行う義務を当社が負うものではありません。<br/>
2. ユーザーは、本サービスを利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、本サービスがユーザーの特定の目的に適合すること，期待する機能および商品的価値および正確性、ならびに有用性を有すること，ユーザーによる本サービスの利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。<br/>
3. 本サービスまたは当社ウェブサイトに関連してユーザーと他のユーザーまたはユーザーの顧客等第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理および解決するものとし、当社はかかる事項について一切責任を負いません。<br/>
4. 当社が本サービスを提供するために利用している第三者のシステム等の仕様や規約が変更されたことにより、完成コンテンツの作成および閲覧等、ユーザーが本サービスを利用することが不可能となった場合、これにより、ユーザーまたは第三者が被ったいかなる不利益または損害について、当社は一切の責任を負わないものとします。<br/>
5. 当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、ユーザーの情報の削除または消失､会員の登録の取消、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関連してユーザーが被った損害につき、本規約に特別に定める場合を除き、賠償する責任を一切負わないものとします。<br/>
6. 当社は、当社の責めに帰すべき事由によって本サービスに関してユーザーに損害が生じた場合、当社が負う損害賠償責任の範囲は、当社に故意または重過失がある場合を除いて、金１万円を上限とします。<br/>
7. 前条および本条各項の規定を含む本規約上の規定で当社の責任を免除・限定する規定が、消費者契約法、民法その他の法令等により無効または合意しなかったものとみなされた場合には、当社は、ユーザーに対して、ユーザーに実際に生じた直接的かつ現実の損害を賠償する責任を負うものとします。<br/>
8. 当社は、当社サイトにウイルスが存在しないことを保証するものではありません。ユーザーは自身の保護のための安全対策を講じるものとします。<br/>
9. 当社ウェブサイトから他のウェブサイトへのリンクまたは他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイトおよびそこから得られる情報に関していかなる理由に基づいても一切の責任を負わないものとします。<br/>
          </p>
        </div>
        <h4>第17条（損害賠償）</h4>
        <div className="section-block">
          <p>
          利用者が、本利用規約に規定する事項に違反したことにより、当社が損害を被った場合には、当該利用者は当社に対して当該損害の賠償責任を負うものとします。 なお、当社が、利用者と第三者との紛争、その他利用者の責めに帰すべき事由に起因した費用（弁護士費用、証人費用、証拠収集費用及びその他の訴訟遂行上の合理的費用を含む）を負担することとなる場合、 当社は、その費用を、現実に負担が生じる前であっても、損害の一部として利用者に請求することができるものとします。
          </p>
        </div>
        <h4>第18条（秘密保持）</h4>
        <div className="section-block">
          <p>
          ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
          </p>
        </div>
        <h4>第19条（個人情報の取扱い）</h4>
        <div className="section-block">
          <p>
          当社によるユーザーの個人情報の取扱いについては、別途当社プライバシーポリシーの定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取扱うことについて同意するものとします。
          </p>
        </div>
        <h4>第20条（利用規約の変更）</h4>
        <div className="section-block">
          <p>
          1. 当社は、利用者の一般の利益に適合する場合のほか、社会情勢、経済事情、税制の変動等の諸般の状況の変化、法令の変更、本サービスに関する実情の変化その他相当の事由があると認められる場合には、本サービスを提供する目的の範囲内で、本サービスの内容その他本規約または条件等の内容（以上をあわせて以下「本規約の内容等」といいます。）を変更できるものとします。<br/>
2.当社は、前項の定めに基づいて本規約の内容等の変更を行う場合は、変更後の利用規約の内容を、当社ウェブサイト上に表示しまたは当社の定める方法によりユーザーに通知することでユーザーに周知するものとし、適用されるものとします。<br/>
3.ユーザーは、変更後の本規約に同意しない場合には、本サービスの利用を中止するものとし、ユーザーが本規約の変更後も本サービスの利用を継続する場合、当該ユーザーは変更後の本規約に同意したものとみなされます。<br/>
          </p>
        </div>
        <h4>第21条（通知または連絡）</h4>
        <div className="section-block">
          <p>
          1. 本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、および本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。<br/>
2. 当社が登録事項に含まれるメールアドレスその他連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。<br/>
          </p>
        </div>
        <h4>第22条（サービス利用契約上の地位の譲渡等）</h4>
        <div className="section-block">
          <p>
          １. ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。<br/>
２. 当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利および義務並びに会員の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき本項においてあらかじめ同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。<br/>
          </p>
        </div>
        <h4>第23条（分離可能性）</h4>
        <div className="section-block">
          <p>
          本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </p>
        </div>
        <h4>第24条（不可抗力）</h4>
        <div className="section-block">
          <p>
          当社は、天災、伝染病、法令・規則の制定・改廃、その他の不可抗力によって本サービスの履行が妨げられた場合には、利用契約その他の一切の規定にかかわらず、かかる不可抗力によってユーザーに生じた損害について一切の責任を負担しません。
          </p>
        </div>
        <h4>第25条（反社会的勢力の排除）</h4>
        <div className="section-block">
          <p>
          1.ユーザーは、当社に対し、次の各号の事項を確約します。<br/>
（1）自らが、暴力団、暴力団員、暴力団員であった者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等その他これらに準ずる者（以下総称して「反社会的勢力」という。）ではないこと。<br/>
（2）自らの役員または経営に実質的に関与している者（業務を執行する社員、取締役、執行役またはこれらに準ずる者をいう）が反社会的勢力と社会的に非難されるべき関係を有しないこと<br/>
（3）反社会的勢力が経営を支配していると認められる関係を有しないこと。<br/>
（4）反社会的勢力が経営に実質的に関与していると認められる関係を有しないこと。<br/>
（5）自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってする等、不当に反社会的勢力を利用していると認められる関係を有しないこと。<br/>
（6）反社会的勢力に対して資金等を提供し、または便宜を供与する等の関与をしていると認められる関係を有しないこと。<br/>
（7）反社会的勢力に自己の名義を利用させ、本規約に同意するものでないこと。<br/>
2.当社は、ユーザーが前項の規定に違反した場合には、当社の判断により、何らの通知または催告なしに、ただちに会員の登録の削除、投稿データの削除を含めた対応ができるものとします。なお、ユーザーは、当該対応により生じた損害の賠償を当社に対して請求することができないものとします。<br/>
          </p>
        </div>
        <h4>第26条（準拠法・裁判管轄）</h4>
        <div className="section-block">
          <p>
          1. 本規約およびサービス利用契約の準拠法は、日本法とします。<br/>
          2. 本規約またはサービス利用契約に禁止、または関連する一切の紛争については、当社の本店所在地を管轄する裁判所を専属的合意管轄裁判所とします。<br/>
          </p>
        </div>
      </div>
      <div className="section-block">
        <p>
          2023年06月01日 制定
          <br />
          株式会社ヴィレッジヴァンガードコーポレーション
        </p>
      </div>
    </div>
  </section>
);

export default Terms;
