import './ConfirmReset.scss';
import { Alert } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

import OriginalButton from './OriginalButton';

type ConfirmResetProps = {
  hideConfirmReset: (confirm: boolean) => void;
};

const ConfirmReset = (props: ConfirmResetProps) => {
  const { hideConfirmReset } = props;

  return (
    <Modal
      isOpen
      onRequestClose={() => hideConfirmReset(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.85)',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        },
        content: {
          position: 'relative',
          backgroundColor: 'white',
          inset: 'unset',
          border: 'none',
          borderRadius: '5px',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          marginLeft: '10px',
          marginRight: '10px',
        },
      }}
    >
      <div className="ConfirmReset">
        <Alert className='ConfirmReset__alert' variation="info" isDismissible={false} hasIcon heading="写真がリセットされて撮影画面に戻ります">【写真保存方法】
        <ul>
          <li>キャンセルを押す</li>
          <li>写真を長押しして保存する</li>
        </ul>
        </Alert>
        <div className="ConfirmReset__buttons">
          <OriginalButton clickEvent={hideConfirmReset} target={false} text='キャンセル' />
          <OriginalButton clickEvent={hideConfirmReset} target text='リセットする' />
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmReset;
