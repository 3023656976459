import './ConfirmGenerate.scss';
import { Alert } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

import OriginalButton from './OriginalButton';


type ConfirmGenerateProps = {
  hideConfirmGenerate: (confirm: boolean) => void;
};

const ConfirmGenerate = (props: ConfirmGenerateProps) => {
  const { hideConfirmGenerate } = props;

  return (
    <Modal
      isOpen
      onRequestClose={() => hideConfirmGenerate(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.85)',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        },
        content: {
          position: 'relative',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '5px',
          left: 0,
          right: 0,
          margin: 'auto',
        },
      }}
    >
      <div className="ConfirmGenerate">
        <Alert className='ConfirmGenerate__alert' variation="info" isDismissible={false} hasIcon heading="撮影した写真が上書きされます">【合成前の写真保存方法】
        <ul>
          <li>キャンセルを押す</li>
          <li>写真を長押しして保存する</li>
        </ul>
        </Alert>
        <div className="ConfirmGenerate__buttons">
          <OriginalButton clickEvent={hideConfirmGenerate} target={false} text='キャンセル' />
          <OriginalButton clickEvent={hideConfirmGenerate} target text='合成する' />
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmGenerate;
