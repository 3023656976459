import { Button } from '@aws-amplify/ui-react';

import loadingImage from '../img/components/loading/loading.gif';
import './ItemLoading.scss';

const ItemLoading = () => {

  const refreshScreen = () => {
    window.location.reload();
  }

  return (
    <div className='ItemLoading'>
      <img src={loadingImage} alt="ローディング中" />
      <p> 画像を読み込み中です。しばらく待っても画像が表示されない場合は、通信環境・GPSの設定をお確かめの上、画面を更新してください。</p>
      <Button marginTop={20} width='80%' variation="primary" onClick={refreshScreen}>
        更新する
      </Button>
    </div>
    )
}

export default ItemLoading;
