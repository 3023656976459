import './TextClipInfo.scss';

import { Card, Button} from '@aws-amplify/ui-react';
import Modal from 'react-modal';

// 画像
import close from '../img/components/preview/close.png';
import bananaSample from '../img/components/text-clip-setting/banana-sample.png';
import girlSample from '../img/components/text-clip-setting/girl-sample.png';

type TextClipInfoProps = {
  hideTextClipInfo: () => void;
};

const TextClipInfo = (props: TextClipInfoProps) => {
  const { hideTextClipInfo } = props;


  return (
    <div>
    <Modal
      isOpen
      onRequestClose={hideTextClipInfo}
      style={{
        overlay: {
          backgroundColor: 'transparent',
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '20px',
          overflow: 'scroll',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          inset: '0',
          backgroundColor:  'white',
          border: 'none',
          position: 'absolute',
        },
      }}
    >
      <div className="TextClipInfo">
        <button type="button" className="TextClipInfo__close" onClick={hideTextClipInfo}>
          <img src={close} alt="テキスト切り抜き情報画面を閉じるボタン" />
        </button>
        <h2 className='title'>
          テキスト切り抜きとは
        </h2>
          
        <Card variation="elevated" textAlign='left' borderRadius="5px" marginBottom={20}>
          <p>テキスト切り抜きとは、指定した文字で背景をくり抜いたフレームを表示する機能です。</p>
          <p>あなたオリジナルの他人に真似できないカッコイイ写真を作ろう！</p>
          <img src={girlSample} alt='女子高生の切り抜きサンプル' />
          <img src={bananaSample} alt='バナナの切り抜きサンプル' />
        </Card>

      <Button variation="primary" onClick={() => hideTextClipInfo()}>閉じる</Button>
      </div>
    </Modal>
    </div>
  );
};
export default TextClipInfo;
