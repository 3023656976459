// builtin
import 'modern-normalize/modern-normalize.css';
import './Front.scss';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { InvokeCommand, LambdaClient} from "@aws-sdk/client-lambda";
import { Stage, PixiRef, Container } from '@pixi/react';
import '@pixi/gif';
import { API, Storage, Auth, Amplify, Analytics } from 'aws-amplify';
import * as PIXI from 'pixi.js';
import { useEffect, useRef, useState, useContext } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

import AngleNotice from './AngleNotice';
import { CreateLogsInput, Items, ItemType, MyItems, GenerateItems } from './API';
import awsConfig from './aws-exports';
import Controller from './components/Controller';
import CameraFailure from './components/failures/CameraFailure';
import GPSFailure from './components/failures/GPSFailure';
import NetworkFailure from './components/failures/NetworkFailure';
import VideoBlack from './components/failures/VideoBlack';
import Loading from './components/Loading';
import MobileOnly from './components/MobileOnly';
import { UserContext } from './components/providers/UserProvider';
import PurchaseResult from './components/PurchaseResult';
import Start from './components/Start';
import hubeny from './components/utils/geo';
import { createLogs } from './graphql/mutations';
import { getItems, iPInfosByIpid, itemsByIpid, myItemsByUsersID, generateItemsByIpid} from './graphql/queries';

awsConfig.oauth.redirectSignIn = window.location.origin + window.location.pathname;
awsConfig.oauth.redirectSignOut = window.location.origin + window.location.pathname;

Amplify.configure(awsConfig);

Analytics.autoTrack('pageView', {
  enable: true,
  eventName: 'pageView',
  attributes: {
    attr: 'attr'
  },
  type: 'SPA',
  provider: 'AWSPinpoint',
  getUrl: () => window.location.origin + window.location.pathname
});

Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
  selectorPrefix: 'data-amplify-analytics-',
  provider: 'AWSPinpoint',
  attributes: {
    type: '',
    id: '',
    name: '',
  }
});

type CanvasContainer = PixiRef<typeof Container>;

const Front = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  // Stage Element
  const stageRef = useRef<Stage>(null);
  // Video Element
  const videoRef = useRef<HTMLVideoElement>(null);
  // Canvas Element
  const canvasRef = useRef<CanvasContainer>(null);
  // ローディング中
  const [loaded, setLoaded] = useState(false);
  // 開始画面
  const [started, setStarted] = useState(false);
  // スマホからの利用に制限
  const [isMobile, setIsMobile] = useState(true);
  // カメラの切り替え
  const [facingMode, setFacingMode] = useState(false);
  // 画像の格納
  const [imageAssets, setImageAssets] = useState<Record<string, any>>({});
  // データの格納
  const [items, setItems] = useState<Items[]>([]);
  // マイアイテムのデータの格納
  const [myItems, setMyItems] = useState<Items[]>([]);
  // 合成用データの格納
  const [generateItems, setGenerateItems] = useState<GenerateItems[]>([]);
  // データの格納
  const [tmpItems, setTmpItems] = useState<Items[]>([]);
  // 現在地
  const [geolocation, setGeoLocation] = useState<Record<string, number> | null>(null);
  // 検索パラメータ
  const [searchParams] = useSearchParams();
  const { ipParam } = useParams();
  // Videoが再生されているかどうか確認するパラメータ
  const [playing, setPlaying] = useState(false);
  const [videoLoadStart, setVideoLoadStart] = useState(false);
  // メディアストリームの操作用に保存
  let mediaStream: MediaStream | undefined;
  // カメラ切り替え時に変更するSpriteの場所を保存
  const videoSpriteIndex = 0;
  // PIXI Stageのオプション
  const stageProps = {
    options: {
      autoDensity: true,
      resolution: window.devicePixelRatio,
      preserveDrawingBuffer: true,
      antialias: true,
    },
  };

  // ユーザーの取得
  const { user, setUser } = useContext(UserContext);
  // 購入結果
  const [purchaseResult, setPurchaseResult] = useState(false);
  // ダミー
  const didLoadRef = useRef(false);
  // 一度だけマイアイテムを読み込む
  const [myItemLoaded, setMyItemLoaded] = useState(false);
  // 横画面表示を無しに
  const [showAngleNotice, setShowAngleNotice] = useState(false);
  // 画像URLの有効期間(秒)
  const IMAGE_EXPIRES_TIME = 14400;
  // スタンプ・フレームの最大取得数
  const QUERY_LIMIT = 500;
  // カメラの失敗時に表示するモーダル
  const [cameraFailure, setCameraFailure] = useState(false);
  // 通信の失敗時に表示するモーダル
  const [networkFailure, setNetworkFailure] = useState(false);
  // GPS起動の失敗時に表示するモーダル
  const [gpsFailure, setGPSFailure] = useState(false);
  // 黒い画面が表示された時に出すモーダル
  const [videoBlack, setVideoBlack] = useState(false);

  // 横向きのチェック
  const checkOrientationChange = () => {
    const ratio = window.innerHeight / window.innerWidth;
    if (ratio > 1) {
      setShowAngleNotice(false);
    } else {
      setShowAngleNotice(true);
    }
  }

  /**
   * カメラの取得
   * @param containers pediaProps
   * @returns Promise<MediaStream | getStream | null>
   */
  const getStream = async (retries = 2): Promise<any> => {
    try {
      // カメラサイズ取得に失敗した場合は、サイズの幅を持たせて実行する
      // 通常idealとして実行されるが、Safariはexactで実行される
      // https://stackoverflow.com/questions/45796011/how-to-change-dimensions-with-getusermedia-on-ios
      const videoWidth = retries === 2 ? 1600 : { min: 1280, ideal: 1920, max: 2560 };
      const videoHeight = retries === 2 ? 900 : { min: 720, ideal: 1080, max: 1440 };
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: facingMode ? 'user' : { ideal: 'environment' },
          width: videoWidth,
          height: videoHeight,
        },
        audio: false,
      });
      return stream;
    } catch (e) {
      if (retries === 0) {
        // カメラの取得時に画面の更新をするモーダルを表示する
        setCameraFailure(true);
      } else {
        // カメラの取得をリトライする
        return getStream(retries - 1);
      }
      return null;
    }
  };

  /**
   * videoエレメントにカメラの映像をセットする
   */
  const setStream = async () => {
    // 一度カメラを停止する
    if (videoRef.current !== null) {
      videoRef.current.onloadstart = (e) => {
        setVideoLoadStart(true)
      }
      videoRef.current.onplaying = (e) => {
        setPlaying(true)
      }
      if (videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (!mediaStream) {
        // getStreamの戻り値がPromise<MediaStream | undefined>
        // videoRefのsrcObjectはMediaStream | null
        // で型の違いがあるため || nullでundefinedをnullに変換する
        mediaStream = (await getStream()) || undefined;
        videoRef.current.srcObject = (await mediaStream) || null;
      }
    }
  };

  const createStage = async () => {
    if (videoRef.current !== null && canvasRef.current !== null) {
      // すでにキャンバスにアイテムが作成されている場合は削除する
      for (let i = canvasRef.current.children.length; i > 1; i -= 1) {
        // 最後の要素から消していく
        canvasRef.current.removeChild(canvasRef.current.children[i - 1]);
      }
      canvasRef.current.sortableChildren = true;

      const stageCurrent: any = stageRef.current;
      const pixiApp: PIXI.Application = stageCurrent.app;

      const width = window.innerWidth;
      const height = window.innerHeight;

      pixiApp.view.width = width * devicePixelRatio;
      pixiApp.view.height = height * devicePixelRatio;

      pixiApp.screen.width = width;
      pixiApp.screen.height = height;

      // Video Elementの設定
      videoRef.current.autoplay = false;
      videoRef.current.muted = true;
      videoRef.current.playsInline = true;
      videoRef.current.width = width;
      videoRef.current.height = height;
      videoRef.current.playsInline = true;

      const media = videoRef.current.srcObject as MediaStream;
      const videoSettings = media.getTracks()[0].getSettings();
      const videoWidth = videoSettings.width || width;
      const videoHeight = videoSettings.height || height;

      // videoエレメントの映像をcanvasに投影する
      const videoSprite = PIXI.Sprite.from(videoRef.current);

      videoSprite.height = videoHeight;
      videoSprite.width = videoWidth;

      // videoStripeを中央に表示
      videoSprite.anchor.set(0.5, 0.5);
      videoSprite.x = pixiApp.view.width / 2 / devicePixelRatio;
      videoSprite.y = pixiApp.view.height / 2 / devicePixelRatio;

      // 左右反転
      let mirror = 1;
      if (facingMode) {
        mirror = -1;
      }
      videoSprite.scale.set(mirror / 2, 1 / 2);
      canvasRef.current.addChildAt(videoSprite, videoSpriteIndex);

      const videoElement = document.querySelector('video');
      videoElement?.remove();
    } 
  };

  const setItemsByGeolocation = () => {
    const newItems: Items[] = [];
    if (tmpItems.length > 0) {
      if (geolocation) {
        for (let i = 0; i < tmpItems.length; i += 1) {
          const item = tmpItems[i];
          if (item.geoFlg && item.longitude && item.latitude) {
            const diffM = hubeny(item.latitude, item.longitude, geolocation.latitude, geolocation.longitude);
            if (diffM >= 0 && diffM <= 500) {
              // GPS範囲に入っていたら追加
              newItems.push(item);
            }
          } else {
            // geoがないものはスルー
            newItems.push(item);
          }
        }
      } else {
        for (let i = 0; i < tmpItems.length; i += 1) {
          const item = tmpItems[i];
          if (!item.geoFlg) {
            // geoがないものはスルー
            newItems.push(item);
          }
        }
      }
      setItems(newItems);
    }
  };

  const successGetCurrentPosition = (position: GeolocationPosition) => {
    setGeoLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };

  const errorGetCurrentPosition = (error: GeolocationPositionError) => {
    setGeoLocation(null);
    setGPSFailure(true);
  };

  // 必要な画像を事前ロードする
  const loadItems = async (contents = '') => {
    let contentsParam = contents;
    if (!contentsParam) {
      contentsParam = process.env.REACT_APP_SUPERADMIN;
    }

		let ipResult: GraphQLResult<any>;
		try {
			// IPInfoが存在するかチェック
			ipResult = await API.graphql({
				query: iPInfosByIpid,
				variables: {
					ipid: contentsParam,
				},
				authMode: GRAPHQL_AUTH_MODE.API_KEY,
			});
		} catch (err: any) {
			console.log(err);
			// AWSClientの設定
			const clientConfig = {
				region: process.env.REACT_APP_REGION, 
				credentials: {
					accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
					secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
				}
			}
			const lambdaClient = new LambdaClient(clientConfig)
			const invokeCommand = new InvokeCommand({
				FunctionName: process.env.REACT_APP_REPORT_FUNCTION,
				Payload: JSON.stringify({ body : JSON.stringify({ message: '【推しドリERROR】graphQLエラー' })})
			})
			await lambdaClient.send(invokeCommand);
			throw err;
		}


    // データがなければデフォルトのコンテンツを表示する
    if (!ipResult || !ipResult.data || ipResult.data.iPInfosByIpid.items.length === 0) {
      contentsParam = process.env.REACT_APP_SUPERADMIN;
    }

    // スタンプ・フレーム情報を取得する
    const itemResult: GraphQLResult<any> = await API.graphql({
      query: itemsByIpid,
      variables: {
        ipid: contentsParam,
        limit: QUERY_LIMIT
      },
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    });

    if (itemResult && itemResult.data && itemResult.data.itemsByIpid) {
      // 取得データ
      const itemData: Items[] = itemResult.data.itemsByIpid.items as Items[];
      itemData.sort((a, b) => a.name.localeCompare(b.name));
      // 実際に利用するデータ
      const itemList: Items[] = [];
      const images: string[] = [];
      let needGeoLocation = false;
      await Promise.all(
        itemData.map(async (item) => {
          // 公開日付が決まっている場合は公開日付のチェックを行う
          let dateCheck = true;
          const now = new Date().getTime();
          if (item.startDate) {
            const start = new Date(item.startDate).getTime();
            if (start > now) {
              dateCheck = false;
            }
          }
          if (item.endDate) {
            const end = new Date(item.endDate).getTime();
            if (end < now) {
              dateCheck = false;
            }
          }
          // 日付のチェックにクリア
          if (dateCheck) {
            if (item.thumbnail) {
              Storage.get(item.thumbnail, {expires: IMAGE_EXPIRES_TIME}).then((name) => {
                item.thumbnail = name;
              });
            }
            if (
              (item.type === ItemType.STAMP && item.main) ||
              (item.type === ItemType.FRAME && item.main && (!item.top && !item.bottom))
            ) {
              item.main = await Storage.get(item.main, {expires: IMAGE_EXPIRES_TIME});
              images.push(item.main);
              itemList.push(item);
            } else if (item.top && item.bottom) {
              item.top = await Storage.get(item.top, {expires: IMAGE_EXPIRES_TIME});
              item.bottom = await Storage.get(item.bottom, {expires: IMAGE_EXPIRES_TIME});
              images.push(item.top);
              images.push(item.bottom);
              itemList.push(item);
            }
            if (item.geoFlg && item.latitude && item.longitude) {
              needGeoLocation = true;
            }
          }
        })
      );

      setImageAssets(await PIXI.Assets.loader.load(images));
      if (needGeoLocation) {
        setTmpItems(itemList);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(successGetCurrentPosition, errorGetCurrentPosition);
        } else {
          setItemsByGeolocation();
        }
      } else {
        setItems(itemList);
      }

      // スタンプ・フレーム情報を取得する
      const generateItemResult: GraphQLResult<any> = await API.graphql({
        query: generateItemsByIpid,
        variables: {
          ipid: contentsParam,
          limit: QUERY_LIMIT
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });

      if (generateItemResult && generateItemResult.data && generateItemResult.data.generateItemsByIpid) {
        // 取得データ
        const generateItemsData: GenerateItems[] = generateItemResult.data.generateItemsByIpid.items as GenerateItems[];
        // 利用するデータ
        const generateItemList: GenerateItems[] = [];
        await Promise.all(
          generateItemsData.map(async (item) => {
            let dateCheck = true;
            const now = new Date().getTime();
            if (item.startDate) {
              const start = new Date(item.startDate).getTime();
              if (start > now) {
                dateCheck = false;
              }
            }
            if (item.endDate) {
              const end = new Date(item.endDate).getTime();
              if (end < now) {
                dateCheck = false;
              }
            }
            // 日付のチェックにクリア
            if (dateCheck) {
              if (item.front) {
                item.front = await Storage.get(item.front, {expires: IMAGE_EXPIRES_TIME});
              }
              if (item.back) {
                item.back= await Storage.get(item.back, {expires: IMAGE_EXPIRES_TIME});
              }
              generateItemList.push(item);
            }
          })
        )
        setGenerateItems(generateItemList);
      }
    } else if (itemResult.errors) {
      setNetworkFailure(true);
    }
  };

  // ユーザーがセットされた時にスタートする
  useEffect(() => {
    // マイアイテムを取得する
    const getMyItems = async (sub: string) => {
      // DynamoDBから取得
      const getMyItemsResult:any = await API.graphql({
        query: myItemsByUsersID,
        variables: { usersID: sub },
      });
      // データがある場合
      if (getMyItemsResult.data.myItemsByUsersID && getMyItemsResult.data.myItemsByUsersID.items) {
        const myItemList = getMyItemsResult.data.myItemsByUsersID.items as MyItems[];
        const itemList: Items[] = [];
        const images: string[] = [];
        await Promise.all(
          myItemList.map(async (myItem) => {
          const getItemsResult:any = await API.graphql({
            query: getItems,
            variables: { id: myItem.itemsID },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
          if (getItemsResult.data.getItems) {
            const item = getItemsResult.data.getItems as Items;
            if (item.thumbnail) {
              item.thumbnail = await Storage.get(item.thumbnail, {expires: IMAGE_EXPIRES_TIME});
            }
            if (
              (item.type === ItemType.STAMP && item.main) ||
              (item.type === ItemType.FRAME && item.main && (!item.top && !item.bottom))
            ) {
              item.main = await Storage.get(item.main, {expires: IMAGE_EXPIRES_TIME});
              images.push(item.main);
              itemList.push(item);
            } else if (item.top && item.bottom) {
              item.top = await Storage.get(item.top, {expires: IMAGE_EXPIRES_TIME});
              item.bottom = await Storage.get(item.bottom, {expires: IMAGE_EXPIRES_TIME});
              images.push(item.top);
              images.push(item.bottom);
              itemList.push(item);
            }
          } 
          })
        )
        if (images.length > 0) {
          const newImageAssets = await PIXI.Assets.loader.load(images);
          setImageAssets(Object.assign(imageAssets, newImageAssets));
        }
        setMyItems(itemList);
      }
    }
    if (user) {
      setStarted(true);
    }
      // マイアイテムがあれば取得する
    if (user && imageAssets && Object.keys(imageAssets).length > 0 && !myItemLoaded) {
      setMyItemLoaded(true);
      const userData: any = user;
      if (userData.attributes && userData.attributes.sub) {
        getMyItems(userData.attributes.sub)
      } else {
        getMyItems(userData.signInUserSession.accessToken.payload.sub);
      }
    }
  }, [user, imageAssets]);

  useEffect(() => {
    // itemsとgeolocationがセットされた時、
    // geoの情報からitemsを再設定する
    if (tmpItems.length > 0) {
      setItemsByGeolocation();
    }
  }, [tmpItems, geolocation]);

  useEffect(() => {
    const setNewStream = async () => {
      if (videoRef.current && canvasRef.current && canvasRef.current.children.length) {
        await setStream();

        videoRef.current.onloadedmetadata = () => {
          if (videoRef.current) {
            videoRef.current.play();
          }
        };

        const stageCurrent: any = stageRef.current;
        const pixiApp: PIXI.Application = stageCurrent.app;

        const width = window.innerWidth;
        const height = window.innerHeight;

        const media = videoRef.current.srcObject as MediaStream;
        const videoSettings = media.getTracks()[0].getSettings();
        const videoWidth = videoSettings.width || width;
        const videoHeight = videoSettings.height || height;

        // videoエレメントの映像をcanvasに投影する
        const videoSprite = PIXI.Sprite.from(videoRef.current);

        videoSprite.height = videoHeight;
        videoSprite.width = videoWidth;

        // videoStripeを中央に表示
        videoSprite.anchor.set(0.5, 0.5);
        videoSprite.x = pixiApp.view.width / 2 / devicePixelRatio;
        videoSprite.y = pixiApp.view.height / 2 / devicePixelRatio;

        // 左右反転
        let mirror = 1;
        if (facingMode) {
          mirror = -1;
        }

        videoSprite.scale.set(mirror / 2, 1 / 2);
        canvasRef.current.removeChildAt(videoSpriteIndex);
        canvasRef.current.addChildAt(videoSprite, videoSpriteIndex);
      }
    };
    setNewStream();
  }, [facingMode]);

  /**
   * 黒い画面になった場合の動作
   */
  const checkPlaying = () => {
    if (!playing) {
      setVideoBlack(true)
    }
  }

  useEffect(() => {
    if (started && videoLoadStart) {
      // 2秒以内にplayingにならない場合は黒い画面の可能性あり
      const timeout = window.setTimeout(() => {
        checkPlaying();
      }, 2000)
      return () => {
        clearTimeout(timeout);
      }
    }
    return () => {};
  }, [started, videoLoadStart, playing])


  // ページロード後に実行
  const onPageLoad = async () => {
    PIXI.utils.clearTextureCache();
    // 少し待機させる
    window.setTimeout(async () => {
      await setStream();
      await createStage();
      setLoaded(true);
    }, 500)

    let resizeTimer: NodeJS.Timeout;
    window.onresize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(checkOrientationChange, 100);
    };

    const redirectStatus = searchParams.get('redirect_status');
    const paymentIntent = searchParams.get('payment_intent');
    if (redirectStatus && paymentIntent) {
      if (redirectStatus === 'succeeded') {
        setPurchaseResult(true);
      }
      try {
        const updateOrderResult = await API.post('stripe', '/webhook/update-order', {
          body: {
            id: paymentIntent,
          },
        });
        if (updateOrderResult.success) {
          // ユーザ情報（購入履歴）を更新する
          const currentUser = await Auth.currentAuthenticatedUser();
          setUser(currentUser);
          Analytics.record({
            name: 'purchaseComplete',
            attributes: { ipid: updateOrderResult.ipid, type: 'none', id: updateOrderResult.id, name: updateOrderResult.name },
            metrics: { _item_price: Number(updateOrderResult.price) },
            immediate: true,
          });
        } else {
          const input: CreateLogsInput = {
            id: `${paymentIntent}#${new Date(new Date().toLocaleString()).getTime()}`,
            ipid: ipParam,
            page: 'Front',
            func: 'onPageLoad',
            message: '購入情報の更新に失敗。',
            memo: `paymentIntent = ${paymentIntent} redirectStatus = ${redirectStatus} result = ${JSON.stringify(
              updateOrderResult
            )}`,
            createdAt: new Date(new Date().toLocaleString()).toJSON(),
          };
          // ログを作成する
          await API.graphql({
            query: createLogs,
            variables: { input },
          });
          alert('購入情報の更新に失敗しました。管理者へ更新情報が送信されました。');
        }
      } catch (e: unknown) {
        const input: CreateLogsInput = {
          id: `${paymentIntent}#${new Date(new Date().toLocaleString()).getTime()}`,
          ipid: ipParam,
          page: 'Front',
          func: 'onPageLoad',
          message: '購入情報の更新に失敗。',
          memo: `paymentIntent = ${paymentIntent} redirectStatus = ${redirectStatus} result = ${JSON.stringify(e)}`,
          createdAt: new Date(new Date().toLocaleString()).toJSON(),
        };
        // ログを作成する
        await API.graphql({
          query: createLogs,
          variables: { input },
        });
        alert('購入情報の更新に失敗しました。管理者へ更新情報が送信されました。');
      }
    }
  };

  // 読み込み、更新時に実行される
  useEffect(() => {
    // モバイルからのアクセスではない場合はモバイルからアクセスするように画面を表示
    // テスト実行中はPCからのアクセスを許可
    if (!PIXI.utils.isMobile.any && process.env.REACT_APP_ENV !== 'test') {
      setIsMobile(false);
      return;
    }
    if (didLoadRef.current === false) {
      didLoadRef.current = true;
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // return () => {window.removeEventListener('load', onPageLoad)}
    }
  }, []);

  // カメラの切り替え
  const toggleCamera = async () => {
    if (videoRef.current && canvasRef.current) {
      // mediaStreamのリセット
      await mediaStream?.getTracks().forEach((track) => {
        track.stop();
      });
      // videoElementのソースリセット
      videoRef.current.srcObject = null;

      // faceModeを切り替えてストリームの取得
      setFacingMode(!facingMode);
    }
  };

  const hidePurchaseResult = () => {
    setPurchaseResult(false);
  };

  const hideVideoBlack = () => {
    setVideoBlack(false);
  };

  return (
    <div className="App">
      {showAngleNotice && <AngleNotice />}
      {!isMobile && <MobileOnly />}
      {cameraFailure && <CameraFailure isOpen={cameraFailure} />}
      {networkFailure && <NetworkFailure isOpen={networkFailure} />}
      {gpsFailure && <GPSFailure isOpen={gpsFailure} />}
      {purchaseResult && <PurchaseResult isOpen={purchaseResult} hidePurchaseResult={hidePurchaseResult} />}
      {videoBlack && <VideoBlack hideVideBlack={hideVideoBlack} />}
      <div style={{ display: ` ${loaded ? 'block' : 'none'}` }}>
        <div style={{ display: ` ${started ? 'block' : 'none'}` }}>
          <video ref={videoRef} />
          <div className="canvas-block">
            <Stage {...stageProps} ref={stageRef}>
              <Container ref={canvasRef} />
            </Stage>
            <Controller
              canvas={canvasRef}
              video={videoRef}
              assets={imageAssets}
              items={items}
              myItems={myItems}
              generateItems={generateItems}
              toggleCamera={toggleCamera}
              loadItems={loadItems}
            />
          </div>
        </div>
        <div style={{ display: ` ${started ? 'none' : 'block'}` }}>
          <Start setStarted={setStarted} loadItems={loadItems} />
        </div>
      </div>
      <div style={{ display: ` ${loaded ? 'none' : 'block'}` }}>
        <Loading />
      </div>
    </div>
  );
};

export default Front;
