import * as PIXI from 'pixi.js';

/**
 * Spriteの作成
 * @param src 画像の取得先
 * @param width 横幅
 * @param height 高さ px | 'scale'
 * @param x X px | 'center'
 * @param y X px | 'center'
 * @param anchorX 0.0〜1.0
 * @param anchorY 0.0〜1.0
 * @returns PIXI.Sprite
 */
export const createSpriteFrom = (
  count: number,
  src: unknown,
  width: number,
  height: number | string,
  x: number | string,
  y: number | string,
  anchorX: number,
  anchorY: number
) => {
  let sprite: PIXI.Sprite;
  if (src instanceof PIXI.Texture) {
    sprite = PIXI.Sprite.from(src);
  } else {
    // AnimategGifの場合
    sprite = src as PIXI.AnimatedSprite;
  }
  // textureに連番で名前をつける
  sprite.name = count.toString();
  // 表示基準
  sprite.anchor.set(anchorX, anchorY);
  // 比率により高さを調節するため、変更前の横幅のサイズを取得しておく
  const beforeWidth = sprite.width;
  sprite.width = width;
  // 横幅のサイズに合わせて縦横比を変えずに拡大する
  if (height === 'scale') {
    sprite.height *= sprite.width / beforeWidth;
  } else {
    sprite.height = Number(height);
  }
  if (x === 'center') {
    sprite.x = window.innerWidth - sprite.width / 2;
  } else {
    sprite.x = Number(x);
  }
  if (y === 'center') {
    sprite.y = window.innerHeight - sprite.height / 2;
  } else {
    sprite.y = Number(y);
  }
  return sprite;
};

/**
 * 操作系のスプライトの作成
 * @param src 画像の取得先
 * @param width 横幅
 * @param height 高さ px | 'scale'
 * @returns PIXI.Sprite
 */
export const createOperationSpriteFrom = (src: string, width: number, height: number) => {
  const INDEX_DEFAULT = 3;
  const sprite = PIXI.Sprite.from(src);
  sprite.eventMode = 'dynamic';
  sprite.anchor.set(0.5);
  sprite.width = width;
  sprite.height = height;
  sprite.visible = false;
  sprite.zIndex = INDEX_DEFAULT;
  return sprite;
};
