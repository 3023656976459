import { View, Image, useTheme } from '@aws-amplify/ui-react';

import logo from '../../img/components/start/oshidori_logo.png';

const Header = () => {
  const { tokens } = useTheme();
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image alt="logo" src={logo} width={200} />
    </View>
  );
};
export default Header;
