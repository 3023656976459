import './Help.scss';
import { Flex, View, Expander, ExpanderItem, useTheme } from '@aws-amplify/ui-react';

import android from '../img/statics/help/android_icon.png';
import camera from '../img/statics/help/camera_icon.png';
import download from '../img/statics/help/download_icon.png';
import gps from '../img/statics/help/gps_icon.png';
import ios_camera_setting_safari1 from '../img/statics/help/ios-camera-setting-1.png';
import ios_camera_setting_safari2 from '../img/statics/help/ios-camera-setting-2.png';
import ios_camera_setting_safari3 from '../img/statics/help/ios-camera-setting-3.png';
import ios_camera_setting_safari4 from '../img/statics/help/ios-camera-setting-4.png';
import ios_gps_setting_safari1 from '../img/statics/help/ios-gps-setting-1.png';
import ios_gps_setting_safari3 from '../img/statics/help/ios-gps-setting-3.png';
import ios_gps_setting_safari4 from '../img/statics/help/ios-gps-setting-4.png';
import ios_gps_setting_base1 from '../img/statics/help/ios-gps-setting-base-1.png';
import ios_gps_setting_base2 from '../img/statics/help/ios-gps-setting-base-2.png';
import ios_gps_setting_base3 from '../img/statics/help/ios-gps-setting-base-3.png';
import ios_login_error from '../img/statics/help/ios-login-error.png';
import ios_camera_setting1 from '../img/statics/help/ios-setting-1.png';
import ios_camera_setting2 from '../img/statics/help/ios-setting-2.png';
import ios_camera_setting3 from '../img/statics/help/ios-setting-3.png';
import ios from '../img/statics/help/ios_icon.png';
import question from '../img/statics/help/question.png';


type CustomTitleProps = {
  category: string;
  name: string;
};

export const CustomTitle = (props: CustomTitleProps) => {
  const { category, name} = props;
  const { tokens } = useTheme();
  return (
    <Flex gap={tokens.space.small}>
      <View width={tokens.space.xxxl} color={tokens.colors.teal[80]}>
        <img className='Help__expander-icon' src={category} alt='ヘルプカテゴリ'/>
      </View>
      <View>{name}</View>
    </Flex>
  );
};

const Help = () => (
  <div className="Help">
    <div className="Help__header">
      <h1 className="Help__header-text">
        ヘルプページ
      </h1>
    </div>
    <div className="Help__body">
      <div className="Help__items">
      <Expander type="multiple" className='Help__expander'>
        <ExpanderItem title={<CustomTitle category={question} name="ログインに失敗します。"/>} value="login-1">
          URLをコピーしChromeまたはSafariの別のブラウザでご利用を試みてください。<br/>
          <p>iPhoneの場合、コードスキャナーで読み込んだ後、ログインがうまく機能しない現象が発生します。</p>
          <p>その場合、コードスキャナーで読み込んだ後、右下のSafariのマークを選択して、推しドリを起動することで解決します。</p>
          <img className='Help__img' src={ios_login_error} alt='iOSログインエラー' />
        </ExpanderItem>
        <ExpanderItem title={<CustomTitle category={camera} name="推しドリを開始すると黒い画面になりカメラが起動しません。"/>} value="item-1">
          画面の更新いただき再度確認をするか、URLをコピーしChromeまたはSafariの別のブラウザでご利用を試みてください。ご不便をおかけして申し訳ございません。

          <p>iPhoneの場合、コードスキャナーで読み込んだ後、画面が黒いままになる現象が発生することがあります。</p>
          <p>その場合、コードスキャナーで読み込んだ後、右下のSafariのマークを選択して、推しドリを起動することで解決します。</p>
          <img className='Help__img' src={ios_login_error} alt='iOSログインエラー' />
        </ExpanderItem>
        <ExpanderItem title={<CustomTitle category={camera} name="カメラの起動に失敗しエラー画面が表示されます。"/>} value="item-2">
          画面の更新いただき再度確認をするか、URLをコピーしChromeまたはSafariの別のブラウザでご利用を試みてください。それでもエラー画面が表示される場合は下記のスマホの設定をご確認ください。
          <div className='Help__expander-sub'>
            <Expander type="multiple" className='Help__expander'>
              <ExpanderItem title={<CustomTitle category={ios} name="iOS（iPhone）・Chromeの設定"/>} value="item-2-1">
                <p>1. 「設定」のアプリケーションを開く</p>
                <p>2. 「プライバシーとセキュリティ」を選択する</p>
                <img className='Help__img' src={ios_camera_setting1} alt='カメラの起動設定の確認1' />
                <p>3. 「カメラ」を選択する</p>
                <img className='Help__img' src={ios_camera_setting2} alt='カメラの起動設定の確認2' />
                <p>4. 「Chrome」にチェックをつける</p>
                <img className='Help__img' src={ios_camera_setting3} alt='カメラの起動設定の確認3' />
              </ExpanderItem>
              <ExpanderItem title={<CustomTitle category={ios} name="iOS（iPhone）・Safariの設定"/>} value="item-2-1">
                <p>1. ブラウザの「あぁ」を選択する</p>
                <img className='Help__img' src={ios_camera_setting_safari1} alt='カメラの起動設定の確認1' />
                <p>2. 「Webサイトの設定」を選択する</p>
                <img className='Help__img' src={ios_camera_setting_safari2} alt='カメラの起動設定の確認2' />
                <p>3. 「カメラ」を選択する</p>
                <img className='Help__img' src={ios_camera_setting_safari3} alt='カメラの起動設定の確認3' />
                <p>4. 「許可」にチェックをつける</p>
                <img className='Help__img' src={ios_camera_setting_safari4} alt='カメラの起動設定の確認4' />
              </ExpanderItem>
              <ExpanderItem title={<CustomTitle category={android} name="Androidの設定"/>} value="item-2-2">
              <a href="https://support.google.com/chrome/answer/2693767?hl=ja&co=GENIE.Platform%3DAndroid" rel='noreferrer noopener' target='_blank'>カメラとマイクを使用する</a>をご確認ください。
              </ExpanderItem>
            </Expander>
          </div>
        </ExpanderItem>
        <ExpanderItem title={<CustomTitle category={gps} name="GPSの起動に失敗しエラー画面が表示されます。"/>} value="item-3">
          下記のスマホの設定をご確認ください。
          <div className='Help__expander-sub'>
            <Expander type="multiple" className='Help__expander'>
              <ExpanderItem title={<CustomTitle category={ios} name="iOS（iPhone）全般の設定"/>} value="item-3-1">
                <p>1. 「設定」のアプリケーションを開く</p>
                <p>2. 「プライバシーとセキュリティ」を選択する</p>
                <img className='Help__img' src={ios_camera_setting1} alt='GPSの起動設定の確認1' />
                <p>3. 「位置情報サービス」を選択する</p>
                <img className='Help__img' src={ios_gps_setting_base1} alt='GPSの起動設定の確認2' />
                <p>4. 「Chrome」または「Safari」のアプリで位置情報が許可されているか確認する</p>
                <img className='Help__img' src={ios_gps_setting_base2} alt='GPSの起動設定の確認3' />
                <img className='Help__img' src={ios_gps_setting_base3} alt='GPSの起動設定の確認4' />
              </ExpanderItem>
              <ExpanderItem title={<CustomTitle category={ios} name="iOS（iPhone）・Safariの設定"/>} value="item-3-2">
                <p>1. ブラウザの「あぁ」を選択する</p>
                <img className='Help__img' src={ios_gps_setting_safari1} alt='GPSの起動設定の確認1' />
                <p>2. 「Webサイトの設定」を選択する</p>
                <img className='Help__img' src={ios_camera_setting_safari2} alt='GPSの起動設定の確認2' />
                <p>3. 「位置情報」を選択する</p>
                <img className='Help__img' src={ios_gps_setting_safari3} alt='GPSの起動設定の確認3' />
                <p>4. 「許可」にチェックをつける</p>
                <img className='Help__img' src={ios_gps_setting_safari4} alt='GPSの起動設定の確認4' />
              </ExpanderItem>
              <ExpanderItem title={<CustomTitle category={android} name="Androidの設定"/>} value="item-3-3">
              <a href="https://support.google.com/android/answer/6179507?sjid=3096257959440232067-AP" rel='noreferrer noopener' target='_blank'>Android スマートフォンの位置情報を使用するアプリを選択する</a>でブラウザアプリの設定をご確認ください。
              </ExpanderItem>
            </Expander>
          </div>
        </ExpanderItem>
        <ExpanderItem title={<CustomTitle category={download} name="ダウンロードした動画がどこにあるかわかりません。"/>} value="item-4">
          <a href="https://support.apple.com/ja-jp/HT213649" rel='noreferrer noopener' target='_blank' >iPhoneでダウンロードしたファイルの場所</a><br/>
          <a href="https://appllio.com/android-smartphone-find-downloaded-files" rel='noreferrer noopener' target='_blank' >Androidでダウンロードしたファイルの場所</a>
         </ExpanderItem>
      </Expander>
      </div>
    </div>
  </div>
);

export default Help;
