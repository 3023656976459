import './NotFound.scss';
import { Card, Text, Button } from '@aws-amplify/ui-react';

const NotFound = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  const back = () => {
    window.history.back();
  }
  return (
    <Card className="NotFound">
      <Text className='NotFound__block'>
        ページが見つかりませんでした。
      </Text>
      <Button className='NotFound__block' onClick={back}>戻る</Button>
    </Card>
  );
}
export default NotFound;

