// デザイン
import './Info.scss';
import { Button } from '@aws-amplify/ui-react';
import { useState, useContext } from 'react';
import Modal from 'react-modal';

// 画像
import close from '../img/components/preview/close.png';
import logo from '../img/components/start/oshidori_logo.png';

// LOGIC
import { UserContext } from './providers/UserProvider';
import Registration from './Registration';

type InfoProps = {
  hideInfo: () => void;
};

const Info = (props: InfoProps) => {
  const { hideInfo } = props;
  // ユーザーの取得
  const { user } = useContext(UserContext);
  const [showRegistration, setShowRegistration] = useState(false);

  const hideRegistration = () => {
    setShowRegistration(false);
  };

  const logout = async () => {
    await user?.signOut();
    window.location.reload();
  };

  return (
    <Modal
      isOpen
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          inset: '0px',
          display: 'flex',
          backgroundColor: '#fcefaa',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          inset: '0',
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
        },
      }}
    >
      <div className="Info">
        <div className="Info__block">
          <button type="button" className="Info__close" onClick={hideInfo}>
            <img src={close} alt="インフォメーションを閉じるボタン" />
          </button>
          <div className="Info__logo">
            <img className="Info__logo-img" src={logo} alt="推しドリロゴ" />
          </div>
          <div className="Info__header">
            <h2>インフォメーション</h2>
            <hr />
          </div>
          <div className="Info__contents">
            <p />
          </div>
          <div className="Info__terms" />
          <div className="Info__buttons">
            <a rel="noopener noreferrer" href="/help" target="_blank" className="Info__button">
              <Button>ヘルプページ</Button>
            </a>
            {!user && (
              <Button
                className="Info__button"
                onClick={() => {
                  setShowRegistration(true);
                }}
              >
                会員登録 / ログイン
              </Button>
            )}
            {showRegistration && <Registration hideRegistration={hideRegistration} />}
            <a rel="noopener noreferrer" href="/terms" target="_blank" className="Info__button">
              <Button>利用規約</Button>
            </a>
            <a rel="noopener noreferrer" href="/privacy-policy" target="_blank" className="Info__button">
              <Button>プライバシーポリシー</Button>
            </a>
            <a rel="noopener noreferrer" href="/commerce" target="_blank" className="Info__button">
              <Button>特定商取引法に基づく表記</Button>
            </a>
            <a rel="noopener noreferrer" href="https://corp.village-v.co.jp/" target="_blank" className="Info__button">
              <Button>運営会社</Button>
            </a>
            {user && (
              <Button className="Info__button" onClick={logout}>
                ログアウト
              </Button>
            )}
            <Button className="Info__button" onClick={hideInfo}>
              閉じる
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Info;
