// CSS
import './Controller.scss';
// Logic
import { Button } from '@aws-amplify/ui-react';
import * as faceapi from '@vladmandic/face-api';
import { Analytics } from 'aws-amplify';
import * as PIXI from 'pixi.js';
import { FC, RefObject, useState, useEffect, useContext, lazy, Suspense } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

// Components
// User
// API
import {Items, ItemType, GenerateItems} from '../API';
// Images
import checkImage from '../img/components/controller/check.png';
import clearImage from '../img/components/controller/clear.png';
import clipTextImg from '../img/components/controller/clip-text.png';
import closeImage from '../img/components/controller/close.png';
import deleteImage from '../img/components/controller/gomibako_orange.png';
import infoButton from '../img/components/controller/info.png';
import leftImage from '../img/components/controller/left_orange.png';
import minusImage from '../img/components/controller/minus.png';
import plusImage from '../img/components/controller/plus.png';
import rightImage from '../img/components/controller/right_orange.png';
import switchMediaImage1 from '../img/components/controller/switch-media1.png';
import switchMediaImage2 from '../img/components/controller/switch-media2.png';
import userButton from '../img/components/controller/user.png';

import CodeForm from './CodeForm';
import Info from './Info';
import ItemLoading from './ItemLoading';
import LoginUser from './LoginUser';
import Prepare from './Prepare';
import Preview from './Preview';
import PreviewVideo from './PreviewVideo';
import { UserContext } from './providers/UserProvider';
import Registration from './Registration';
import TextClipSetting from './TextClipSetting';
import TextInput from './TextInput';
import { createSpriteFrom, createOperationSpriteFrom } from './utils/sprite';

// Components 画面のレンダリング時に読み込みを行う
const Product = lazy(() => import('./Product'));

Modal.setAppElement('#root');

type ControllerProps = {
  canvas: RefObject<PIXI.Container<PIXI.DisplayObject>>;
  video: RefObject<HTMLVideoElement>;
  assets: Record<string, any>;
  items: Items[];
  myItems: Items[];
  generateItems: GenerateItems[];
  toggleCamera: () => void;
  loadItems: (contents: string) => void;
};

const Controller: FC<ControllerProps> = (props: ControllerProps) => {
  const { canvas, video, assets, items, myItems, generateItems, toggleCamera, loadItems } = props;
  const currentCanvas: PIXI.Container<PIXI.DisplayObject> | null = canvas.current;
  const currentVideo: HTMLVideoElement | null = video.current;
  // コントローラーの表示非表示を切り替える
  const [toggleController, setToggleController] = useState(true);
  // スタンプ・フレーム・期間限定などの画像タイプ
  const TYPE = {
    STAMP: 'STAMP',
    FRAME: 'FRAME',
    MYITEM: 'MYITEM',
    LIMITED: 'LIMITED',
  };
  const stampData: Items[] = items.filter((d: Items) => d.type === ItemType.STAMP && d.thumbnail !== '');
  const frameData: Items[] = items.filter((d: Items) => d.type === ItemType.FRAME && d.thumbnail !== '');
  const myItemData: Items[] = myItems;
  // スタンプ・フレーム・期間限定などの画像タイプを切り替える
  const [activeType, setActiveType] = useState(TYPE.STAMP);
  // 撮影した写真のプレビュー表示・ダウンロード表示を切り替える
  const [showPreview, setShowPreview] = useState(false);
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  // ログインユーザーの注文履歴等
  const [showLoginUser, setShowLoginUser] = useState(false);
  // スタンプの回転操作
  let rotateLeft: PIXI.Sprite | null = null;
  let rotateRight: PIXI.Sprite | null = null;
  let plusSize: PIXI.Sprite | null = null;
  let minusSize: PIXI.Sprite | null = null;
  let square: PIXI.Graphics | null = null;
  let checkFixed: PIXI.Sprite | null = null;
  // スタンプの初期サイズ
  const DEFAULT_IMAGE_SIZE = 160;
  // 回転ボタンの画像からの距離
  const ROTATE_IMAGE_DISTANCE = 65;
  // ±ボタンの画像からの距離
  const PLUSMINUS_IMAGE_DISTANCE = 30;
  // スタンプ回転の量（ラジアン 1周で6.28）
  const ROTATE_SIZE = 6.28 / 20;
  // スタンプサイズ変更の量
  const SIZE = 50;
  // フレームの管理
  type FrameDict = {
    full: PIXI.Sprite | null;
    top: PIXI.Sprite | null;
    bottom: PIXI.Sprite | null;
    generate: PIXI.Sprite | null;
    textclip: PIXI.Sprite | null;
   };
  const [frameTarget, setFrameTarget] = useState<FrameDict>({ full: null, top: null, bottom: null, generate: null, textclip: null });
  // スタンプの操作
  const [stampTarget, setStampTarget] = useState<PIXI.Sprite | null>(null);
  type Target = {
    left: PIXI.Sprite;
    right: PIXI.Sprite;
    square: PIXI.Graphics;
    checkFixed: PIXI.Sprite;
    plus: PIXI.Sprite;
    minus: PIXI.Sprite;
    [key: string]: PIXI.Sprite | PIXI.Graphics;
  };
  type TargetDict = { [key: string]: Target };
  const [stampList, setStampList] = useState<TargetDict>({});
  // Preview Image Element
  const [previewSrc, setPreviewSrc] = useState('');
  // PreviewVideo Image Element
  const [previewSrcVideo, setPreviewSrcVideo] = useState('');
  // 連番管理
  const [count, setCount] = useState(0);

  // 顔スタンプの初期サイズ
  const FACE_DEFAULT_IMAGE_SIZE = 200;
  // 顔認識のタイミング(ms)
  const THROTTLETIMER = 20;
  // スタンプの操作
  const [faceTarget, setFaceTarget] = useState<PIXI.Sprite | null>(null);
  const [faceTrackingStarted, setFaceTrackingStarted] = useState(false);
  // スタンプ準備中表示
  const [prepare, setPrepare] = useState(false);
  // 画面の中心
  const [centerPosition, setCenterPosition] = useState<Record<string, number>>({ x: 0, y: 0 });
  // ユーザーの取得
  const { user, dynamoUser } = useContext(UserContext);
  // 購入
  const [product, setProduct] = useState<Items | null>(null);
  // IP
  const { ipParam } = useParams();
  // 情報の表示
  const [showInfo, setShowInfo] = useState(false);
  // ユーザー登録・ログイン画面
  const [showRegistration, setShowRegistration] = useState(false);
  // シークレットコード入力画面
  const [showCodeForm, setShowCodeForm] = useState(false);
  // テキストクリップ設定画面
  const [showTextClipSetting, setShowTextClipSetting] = useState(false);
  const [defaultTextClipSetting, setDefaultTextClipSetting] = useState({text: '', color: '#FFF', align: 'left', verticalAlign: 'center', size: 80, fontname: 'corporate-logo-ver2', closer: false });
  // テキストの入力
  const [showTextInput, setShowTextInput] = useState(false);
  // 合成済み判断
  const [generated, setGenerated] = useState(false);
  // IPID
  const [ipid, setIpid] = useState(ipParam);
  // 動画、写真の切替
  const PHOTO_TYPE = { PICTURE : 'PICTURE', VIDEO: 'VIDEO',};
  const [photoType, setPhotoType] = useState(PHOTO_TYPE.PICTURE);
  // 画像の順番、インデックスの管理
  const INDEX_DEFAULT = 3;
  const INDEX_FRAME_DEFAULT = 2;
  const INDEX_TARGET = 1000;
  const INDEX_TARGET_CONTROLLERS = 1001;
  const INDEX_CHECK_FIXED = 2000;
  // 動画撮影中の判断
  const [takingMovie, setTakingMovie] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const recordedBlobs:BlobPart[] = [];
  // 顔検出の準備と開始
  const loadFaceApi = async () => {
    await Promise.all([
      // 推論が高速な顔検出。バウンディングボックスと精度を取得
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      // 顔のパーツのモデル読み込み
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      faceapi.nets.faceLandmark68TinyNet.loadFromUri('/models'),
    ]);
  };

  useEffect(() => {
    // canvasがセットされた時に1回実行する
    if (currentCanvas !== null) {
      currentCanvas.eventMode = 'dynamic';
      loadFaceApi();
      setCenterPosition({
        x: window.innerWidth / 2 || 0,
        y: window.innerHeight / 2 || 0,
      });
    }
  }, [currentCanvas]);

  useEffect(() => {
    if (!toggleController) {
      // データがなければ画像の読み込みを行う
      if (stampData.length === 0 && frameData.length === 0 && ipParam) {
        loadItems(ipParam);
        window.history.replaceState(null, `${ipParam}`, `/contents/${ipParam}`)
        setIpid(ipParam);
      }
    }
  }, [toggleController]);

  // 顔のランドマーク検出
  async function getLandMarks() {
    if (currentVideo && faceTrackingStarted) {
      try {
        const faceData = await faceapi
          .detectSingleFace(
            currentVideo,
            new faceapi.TinyFaceDetectorOptions({
              scoreThreshold: 0.4,
            })
          )
          .withFaceLandmarks();
        if (faceData !== undefined) {
          const resizedResults = faceapi.resizeResults(faceData, {
            width: window.innerWidth,
            height: window.innerHeight,
          });
          if (resizedResults && faceTarget !== null) {
            // 27: 眉間
            const position = resizedResults.landmarks.positions[27];
            // 左右反転処理
            faceTarget.x = position.x * -1 + window.innerWidth;
            faceTarget.y = position.y;
          }
        }
      } finally {
        if (prepare) {
          setPrepare(false);
        }
      }
    }
  }

  // 顔トラッキングの開始
  useEffect(() => {
    let interval: NodeJS.Timeout;
    try {
      if (faceTrackingStarted) {
        interval = setInterval(() => {
          getLandMarks();
        }, THROTTLETIMER);
      }
      return () => {
        if (interval) {
          clearTimeout(interval);
        }
      };
    } catch (e) {
      return () => {
        if (interval) {
          clearTimeout(interval);
        }
      };
    }
  }, [faceTrackingStarted, faceTarget]);

  /**
   * 商品の購入済みチェック
   * @param productID 商品ID
   * @returns boolean
   */
  const bought = (productID: string | null | undefined) => {
    let boughtFlg = false;
    if (productID && dynamoUser && dynamoUser.Orders && dynamoUser.Orders.items) {
      for (let i = 0; i < dynamoUser.Orders.items.length; i += 1) {
        const order = dynamoUser.Orders.items[i];
        if (order?.productID === productID) {
          boughtFlg = true;
          break;
        }
      }
    }
    return boughtFlg;
  };

  /**
   * フレームの追加
   * @param src
   */
  const addFrameToCanvas = (targetData: Items[], id: string) => {
    let top = null;
    let bottom = null;
    let full = null;
    // すでに登録された背景が入っていた場合は削除する
    if (!(frameTarget.full && frameTarget.full.name === id || frameTarget.top && frameTarget.top.name === id )){
      // 未購入の商品の場合は購入画面を表示する
      // if (frame && frame.price) {
      //   if (!bought(frame.productID)) {
      //     setProduct(frame);
      //     return;
      //   }
      // }
      const frame = targetData.find((item) => item.id === id);
      // 上下に分かれているタイプと、全体のタイプのフレームがある
      if (frame && frame.top && frame.bottom) {
        // 上下に分かれているタイプのフレーム追加
        top = createSpriteFrom(count, assets[`${frame?.top}`], window.innerWidth, 'scale', 'center', 0, 0.5, 0.0);
        top.name = id;
        setCount(count + 1);

        bottom = createSpriteFrom(
          count,
          assets[`${frame?.bottom}`],
          window.innerWidth,
          'scale',
          'center',
          window.innerHeight,
          0.5,
          1.0
        );
        bottom.name = id;
        setCount(count + 1);
        if (currentCanvas) {
          top.zIndex = INDEX_FRAME_DEFAULT;
          bottom.zIndex = INDEX_FRAME_DEFAULT;
          currentCanvas.addChild(top);
          currentCanvas.addChild(bottom);
        }
      } else {
        // 全体タイプのフレームの作成
        full = createSpriteFrom(
          count,
          assets[`${frame?.main}`],
          window.innerWidth,
          'scale',
          'center',
          'center',
          0.5,
          0.5
        );
        setCount(count + 1);
        if (currentCanvas) {
          full.zIndex = INDEX_FRAME_DEFAULT;
          full.name = id;
          currentCanvas.addChild(full);
        }
      }
    }
    if (frameTarget.top) {
      frameTarget.top.destroy();
    }
    if (frameTarget.bottom) {
      frameTarget.bottom.destroy();
    }
    if (frameTarget.full) {
      frameTarget.full.destroy();
    }
    setFrameTarget({ full, top, bottom, generate: frameTarget.generate, textclip: frameTarget.textclip  });
  };

  /**
   * すべてのスタンプの操作系を非表示にする
   */
  const hideAllStampOptions = () => {
    Object.keys(stampList).forEach((stampKey) => {
      const texture = currentCanvas?.getChildByName(stampKey);
      if (texture) {
        texture.alpha = 1.0;
        texture.zIndex = INDEX_DEFAULT;
      }

      // 操作系のデータを全て削除する
      Object.keys(stampList[stampKey]).forEach((k) => {
        stampList[stampKey][k].visible = false;
        stampList[stampKey][k].zIndex = INDEX_DEFAULT;
      });
    });
    setStampTarget(null);
  };

  /**
   * 自分以外のスタンプの操作系を非表示にする
   */
  const hideOtherStampOptions = (dt: PIXI.Sprite | null) => {
    Object.keys(stampList).forEach((stampKey) => {
      const texture = currentCanvas?.getChildByName(stampKey);
      if (texture !== dt) {
        if (texture) {
          texture.alpha = 1.0;
          texture.zIndex = INDEX_DEFAULT;
        }

        // 操作系のデータを全て削除する
        Object.keys(stampList[stampKey]).forEach((k) => {
          stampList[stampKey][k].visible = false;
          stampList[stampKey][k].zIndex = INDEX_DEFAULT;
        });
      }
    });
    if (dt === stampTarget) {
      setStampTarget(null);
    }
  };

  /**
   * チェック操作後にすべての操作系を非表示にする
   */
  const hideOperations = () => {
    if (rotateRight) {
      rotateRight.visible = false;
      rotateRight.zIndex = INDEX_DEFAULT;
    }
    if (rotateLeft) {
      rotateLeft.visible = false;
      rotateLeft.zIndex = INDEX_DEFAULT;
    }
    if (square) {
      square.visible = false;
      square.zIndex = INDEX_DEFAULT;
    }
    if (checkFixed) {
      checkFixed.visible = false;
      checkFixed.zIndex = INDEX_DEFAULT;
    }
    if (plusSize) {
      plusSize.visible = false;
      plusSize.zIndex = INDEX_DEFAULT;
    }
    if (minusSize) {
      minusSize.visible = false;
      minusSize.zIndex = INDEX_DEFAULT;
    }
  };

  /**
   * スタンプが移動されたときの操作系の更新
   * @param x スタンプ.x
   * @param y スタンプ.y
   * @param w スタンプの横幅
   * @param h スタンプの高さ
   */
  const updateOperations = (x: number, y: number, w: number, h: number) => {
    if (rotateLeft) {
      rotateLeft.visible = true;
      rotateLeft.x = x - ROTATE_IMAGE_DISTANCE - 10;
      rotateLeft.y = y - ROTATE_IMAGE_DISTANCE + 5;
      rotateLeft.zIndex = INDEX_TARGET_CONTROLLERS;
    }
    if (rotateRight) {
      rotateRight.visible = true;
      rotateRight.x = x + ROTATE_IMAGE_DISTANCE + 10;
      rotateRight.y = y + ROTATE_IMAGE_DISTANCE + 15;
      rotateRight.zIndex = INDEX_TARGET_CONTROLLERS;
    }
    if (square) {
      square.visible = true;
      square.x = x;
      square.y = y;
      square.zIndex = INDEX_TARGET_CONTROLLERS;
    }
    if (checkFixed) {
      checkFixed.visible = true;
      checkFixed.zIndex = INDEX_CHECK_FIXED;
    }

    if (plusSize) {
      plusSize.visible = true;
      plusSize.x = x + w / 2 + PLUSMINUS_IMAGE_DISTANCE;
      plusSize.y = y - PLUSMINUS_IMAGE_DISTANCE;
      plusSize.zIndex = INDEX_TARGET_CONTROLLERS;
    }
    if (minusSize) {
      minusSize.visible = true;
      minusSize.x = x + w / 2 + PLUSMINUS_IMAGE_DISTANCE;
      minusSize.y = y + PLUSMINUS_IMAGE_DISTANCE;
      minusSize.zIndex = INDEX_TARGET_CONTROLLERS;
    }
  };

  /**
   * スタンプが選択されているか返す
   * @returns boolean
   */
  const hasStampTarget = () => stampTarget !== null;

  /**
   * Canvasに画像を追加する
   * @param src 追加する画像の情報
   */
  const addStampToCanvas = (sprite: PIXI.Sprite) => {
    sprite.eventMode = 'dynamic';
    sprite.interactiveChildren = true;
    sprite.zIndex = INDEX_DEFAULT;

    // 四角で囲む
    square = new PIXI.Graphics();
    square.lineStyle(1, 0xffffff);
    square.drawRect(0, 0, sprite.width + 10, sprite.height + 10);
    square.pivot.x = (sprite.width + 10) / 2;
    square.pivot.y = (sprite.height + 10) / 2;

    // 左回転の操作をするボタンの作成
    rotateLeft = createOperationSpriteFrom(rightImage, 71 / 2, 87 / 2);
    rotateLeft.on('pointertap', (event: PIXI.FederatedPointerEvent) => {
      sprite.rotation += ROTATE_SIZE;
      if (square) square.rotation += ROTATE_SIZE;
    });

    // 右回転の操作をするボタンの作成
    rotateRight = createOperationSpriteFrom(leftImage, 71 / 2, 87 / 2);
    rotateRight.on('pointertap', (event: PIXI.FederatedPointerEvent) => {
      sprite.rotation -= ROTATE_SIZE;
      if (square) square.rotation -= ROTATE_SIZE;
    });

    // 拡大縮小を操作するボタンの作成
    plusSize = createOperationSpriteFrom(plusImage, 30, 30);
    plusSize.on('pointertap', (event: PIXI.FederatedPointerEvent) => {
      if (sprite.width + SIZE < window.innerWidth * 2) {
        sprite.height *= (SIZE + sprite.width) / sprite.width;
        sprite.width += SIZE;
      }
    });

    minusSize = createOperationSpriteFrom(minusImage, 30, 30);
    minusSize.on('pointertap', (event: PIXI.FederatedPointerEvent) => {
      if (sprite.width - SIZE > 0) {
        sprite.height *= (sprite.width - SIZE) / sprite.width;
        sprite.width -= SIZE;
      }
    });

    // スタンプのドラッグ対象
    let dragTarget: PIXI.Sprite | null = null;
    // スタンプが選択された時の処理
    sprite
      .on('pointerdown', async (event: PIXI.FederatedPointerEvent) => {
        hideAllStampOptions();
        dragTarget = sprite;
        sprite.zIndex = INDEX_TARGET;
        setStampTarget(sprite);
        sprite.alpha = 0.5;
        if (canvas && canvas.current && sprite === dragTarget) {
          const localPosition = canvas.current.toLocal(sprite.getGlobalPosition())
          if (localPosition !== undefined) {
						updateOperations(localPosition.x, localPosition.y, sprite.width, sprite.height);
          }
        }
      })
      // スタンプが移動された時の処理
		  .on('pointermove', (event: PIXI.FederatedPointerEvent) => {
        if (canvas && canvas.current && sprite === dragTarget) {
          const localPosition = canvas.current.toLocal(event.global)
          if (localPosition !== undefined) {
            sprite.x = localPosition.x;
						sprite.y = localPosition.y;
            window.setTimeout(async () => {
              sprite.zIndex = INDEX_TARGET;
              hideOtherStampOptions(dragTarget);
              updateOperations(localPosition.x, localPosition.y, sprite.width, sprite.height);
            }, 70)
          }
        }
      })

    // チェック操作の作成
    checkFixed = createOperationSpriteFrom(checkImage, 40, 40);
    checkFixed.x = 45;
    checkFixed.y = 100;
    checkFixed.on('pointertap', (event: PIXI.FederatedPointerEvent) => {
      sprite.alpha = 1;
      hideOperations();
      setStampTarget(null);
    });

    if (currentCanvas) {
      currentCanvas.on('pointertap', (event: PIXI.FederatedPointerEvent) => {
        if (dragTarget) {
          try {
            const targetPoint:PIXI.Point = currentCanvas.toLocal(dragTarget.getGlobalPosition());
            const targetWidthHalf = dragTarget.width / 2;
            const targetHeightHalf = dragTarget.height / 2;
            // スタンプの範囲外が選択されたらターゲットを外す
            if (
              ((targetPoint.x - targetWidthHalf - 71 / 2) > event.x || (targetPoint.x + targetWidthHalf + PLUSMINUS_IMAGE_DISTANCE + SIZE) < event.x) || 
              ((targetPoint.y - targetHeightHalf - 87 / 2) > event.y || (targetPoint.y + targetHeightHalf + 87 / 2) < event.y)
              ) {
                window.setTimeout(async () => {
                  sprite.zIndex = INDEX_DEFAULT;
                  sprite.alpha = 1;
                  hideAllStampOptions();
                  dragTarget = null;
                  setStampTarget(null);
                }, 100)
            }
          } catch ( e: unknown) {
            // DO NOTHING
          }
        }
      })
      // 作成した画像をキャンバスに追加する
      currentCanvas.addChild(sprite);
      currentCanvas.addChild(rotateLeft);
      currentCanvas.addChild(rotateRight);
      currentCanvas.addChild(square);
      currentCanvas.addChild(checkFixed);
      currentCanvas.addChild(plusSize);
      currentCanvas.addChild(minusSize);
      if (sprite.name !== null) {
        stampList[sprite.name] = {
          left: rotateLeft,
          right: rotateRight,
          square,
          checkFixed,
          plus: plusSize,
          minus: minusSize,
        };
        setStampList(stampList);
        hideAllStampOptions();
      }
    }
    setToggleController(true);
  };

  /**
   * Front.tsxのloadItemsのデータをスタンプとして追加する
   * @param targetData loadItemsで取得されたスタンプデータ
   * @param id 追加するスタンプのID
   */
  const addStampToCanvasFromTargetData = (targetData: Items[], id: string) => {
    const stamp = targetData.find((item) => item.id === id);
    // 未購入の商品の場合は購入画面を表示する
    // if (stamp && stamp.price) {
    //   if (!bought(stamp.productID)) {
    //     setProduct(stamp);
    //     return;
    //   }
    // }
    const sprite = createSpriteFrom(
      count,
      assets[`${stamp?.main}`],
      stamp?.size || DEFAULT_IMAGE_SIZE,
      'scale',
      centerPosition.x,
      centerPosition.y,
      0.5,
      0.5
    );
    setCount(count + 1);
    addStampToCanvas(sprite)
  }

  /**
   * Textureからスタンプを追加する
   * @param texture PIXI.Texture
   */
  const addStampToCanvasFromTexture = (texture: PIXI.Texture) => {
    const sprite = createSpriteFrom(
      count,
      texture,
      DEFAULT_IMAGE_SIZE,
      'scale',
      centerPosition.x,
      centerPosition.y,
      0.5,
      0.5
    );
    setCount(count + 1);
    addStampToCanvas(sprite)
  }

  /**
   * 顔認識されるスタンプ追加
   * @param src 画像のパス
   */
  const addFaceToCanvas = (targetData: Items[], id: string) => {
    const stamp = targetData.find((item) => item.id === id);
    // 未購入の商品の場合は購入画面を表示する
    // if (stamp && stamp.price) {
    //   if (!bought(stamp.productID)) {
    //     setProduct(stamp);
    //     return;
    //   }
    // }
    // 準備中の表示
    setPrepare(true);
    // すでに顔のスタンプが動いていたら削除する
    if (faceTarget) {
      faceTarget.destroy();
      setFaceTarget(null);
      setFaceTrackingStarted(false);
    }
    const texture = createSpriteFrom(
      count,
      assets[`${stamp?.main}`],
      stamp?.size || FACE_DEFAULT_IMAGE_SIZE,
      'scale',
      centerPosition.x,
      centerPosition.y,
      0.5,
      0.5
    );
    setCount(count + 1);
    texture.eventMode = 'dynamic';

    texture.on('pointerdown', async (event: PIXI.FederatedPointerEvent) => {
      hideAllStampOptions();
      if (texture.alpha === 0.5) {
        texture.alpha = 1.0;
        setStampTarget(null);
      } else {
        texture.alpha = 0.5;
        setStampTarget(texture);
        setFaceTarget(texture);
      }
    });
    if (currentCanvas) {
      currentCanvas.addChild(texture);
    }
    setFaceTarget(texture);
    // 顔トラッキングの開始
    setFaceTrackingStarted(true);
  };

  /**
   * 合成済みにする
   */
  const setGeneratedState = (state: boolean) => {
    setGenerated(state);
  };

  /**
   * 全てのスタンプ・フレームを削除
   */
  const deleteStamps = () => {
    if (currentCanvas) {
      for (let i = currentCanvas.children.length; i > 1; i -= 1) {
        // 最後の要素から消していく
        currentCanvas.removeChild(currentCanvas.children[i - 1]);
      }
      Object.keys(stampList).forEach((stampKey) => {
        delete stampList[stampKey];
      });
      setStampList(stampList);
      setStampTarget(null);
      setFaceTrackingStarted(false);
      setFaceTarget(null);
      setGeneratedState(false);
    }
  };

  /**
   * 写真を撮影する
   */
  const takePicture = () => {
    // 全ての操作系を非表示にする
    hideAllStampOptions();
    const realWindowSize = Number(document.documentElement.style.getPropertyValue('--vh').replace('px', ''));
    // 撮影する範囲
    const renderer = PIXI.autoDetectRenderer({
      width: window.innerWidth,
      height: realWindowSize * 100,
      autoDensity: true,
      resolution: window.devicePixelRatio,
    });
    if (currentCanvas !== null) {
      renderer.render(currentCanvas);
      if (renderer.view.toDataURL) {
        const imageSrc = renderer.view.toDataURL('image/jpeg', 1.0);
        setPreviewSrc(imageSrc);
        setShowPreview(true);
      }
    }
  };

  /**
   * 動画を撮影する
   */
  const takeMovie = () => {
    setTakingMovie(true);

    // 全ての操作系を非表示にする
    hideAllStampOptions();

    if (previewSrcVideo) {
      URL.revokeObjectURL(previewSrcVideo);
    }

    if (video.current !== null) {
      const canvasElement = document.querySelector('canvas') as HTMLCanvasElement;
      const stream = canvasElement.captureStream() as MediaStream;
      let options = {};
      if (MediaRecorder.isTypeSupported('video/mp4')) {
        // 4.5Mbps
        options = {mimeType: 'video/mp4', videoBitsPerSecond : 4_500_000};
      } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        options = {mimeType: 'video/webm; codecs=vp9', videoBitsPerSecond : 4_500_000};
      } else  if (MediaRecorder.isTypeSupported('video/webm')) {
          options = {mimeType: 'video/webm', videoBitsPerSecond : 4_500_000};
      } else {
        window.alert('動画の撮影ができません。')
      }
      const md = new MediaRecorder(stream, options);
      setMediaRecorder(md)
    }
  }

  // 動画の撮影終了
  const endTakeMovie = () => {
    if ( mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
    }
    setTakingMovie(false);
  }

  /**
   * mediaRecorderが変更されたときの処理
   */
  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = (event) => {
        if( event.data && event.data.size > 0 ) {
          recordedBlobs.push(event.data)
        }
      }
      mediaRecorder.onstop = (event) => {
        let type;
        let ext;
        if (MediaRecorder.isTypeSupported('video/mp4')) {
          type = 'video/mp4';
          ext = 'mp4';
        } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
          type = 'video/webm';
          ext = 'webm';
        } else {
          window.alert('動画の撮影ができません。')
        } 
        const blob = new Blob(recordedBlobs, { type });
        const url = URL.createObjectURL(blob);
        setPreviewSrcVideo(url);
        setShowPreviewVideo(true);
      }
      mediaRecorder.start();
    }
  }, [mediaRecorder])


  /**
   * 選択されたスタンプを削除
   */
  const deleteStamp = () => {
    if (stampTarget && currentCanvas) {
      // 顔トラッキングが削除された場合はトラッキングを停止する
      if (faceTarget && faceTarget.name === stampTarget.name) {
        setFaceTarget(null);
        setFaceTrackingStarted(false);
      }
      // スタンプの削除
      const id = stampTarget.name;
      stampTarget.destroy();
      // 操作系のデータを全て削除する
      if (id && stampList[id]) {
        Object.keys(stampList[id]).forEach((stampKey) => {
          const index = currentCanvas.getChildIndex(stampList[id][stampKey]);
          currentCanvas.getChildAt(index).destroy();
        });
        // リストから削除
        delete stampList[id];
        setStampList(stampList);
      }
      setStampTarget(null);
    }
  };

  /**
   * プレビュー画面を非表示にする
   */
  const hidePreview = () => {
    setShowPreview(false);
  };

  /**
   * 動画プレビュー画面を非表示にする
   */
  const hidePreviewVideo = () => {
    setShowPreviewVideo(false);
  };

  /**
   * 購入画面を非表示にする
   */
  const hideProduct = () => {
    setProduct(null);
  };

  /**
   * ログインユーザー用 注文履歴の表示
   */
  const hideLoginUser = () => {
    setShowLoginUser(false);
  };

  /**
   * 情報の非表示
   */
  const hideInfo = () => {
    setShowInfo(false);
  };

  /**
   * 新規登録画面を非表示にする
   */
  const hideRegistration = () => {
    setShowRegistration(false);
  };

  /**
   * 新規登録画面を表示する
   */
  const openRegistration = () => {
    setShowRegistration(true);
  }

  /**
   * シークレットコード入力画面を表示する
   */
  const openCodeForm = () => {
    setShowCodeForm(true);
  }

  /**
   * シークレットコード入力画面を非表示にする
   */
  const hideCodeForm = () => {
    setShowCodeForm(false);
  };

  const loadImage = (imageSrc: string) => {
    const promise = new Promise((resolve, reject) => {
      const img = new Image();
      // CORSのための設定
      img.crossOrigin = 'Anonymous';
      img.alt = 'テキスト画像';
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = imageSrc;
    });
    return promise;
  }

  /**
   * 折り返しと改行を伴うテキストをキャンバスに描画する.
   * 改行文字は\n
   * alignがright|center以外が指定された時はleftとして扱う
   * @param context
   * @param width 横幅
   * @param lineHight 行送り
   * @param align 行揃え right|center
   */
  function fixedFillText(context: CanvasRenderingContext2D, text: string, width: number, height: number, lineHight: number, align: string, verticalAlign: string, closer: boolean) {
    const column = [''];
    let line = 0
    for (let i = 0; i < text.length; i += 1) {
      const char = text.charAt(i);
      if (char === "\n" || context.measureText(column[line] + char).width > width) {
        line += 1;
        column[line] = '';
      }
      column[line] += char.replace("\n", "");
    }

    let firstPadding = 0;
    if (verticalAlign === 'center') {
      firstPadding = (height - (lineHight + lineHight * column.length)) / 2;
    } else if (verticalAlign === 'bottom') {
      firstPadding = (height - (lineHight + lineHight * column.length));
    }

    let padding: number;
    for (let i = 0; i < column.length; i += 1) {
      const lineWidth: number = context.measureText(column[i]).width;
      if (align === 'right') {
        padding = width - lineWidth;
      }
      else if (align === 'center') {
        padding = (width - lineWidth)/2;
      }
      else {
        padding = 0;
      }

      if (closer) {
        context.fillText(column[i], 0 + padding, firstPadding + lineHight + (lineHight - lineHight / 6) * i);
      } else {
        context.fillText(column[i], 0 + padding, firstPadding + lineHight + lineHight * i);
      }
    }
  }

  const textClip = async (text: string, color: string, align: string, verticalAlign: string, size: number, fontname: string, closer: boolean) => {
    if (canvas.current) {
      const realWindowSize = Number(document.documentElement.style.getPropertyValue('--vh').replace('px', ''));
      const tCanvas = document.createElement("canvas");
      tCanvas.width = window.innerWidth * 3
      tCanvas.height = realWindowSize * 101 * 3;

      const ctx = tCanvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, tCanvas.width, tCanvas.height);
        ctx.globalCompositeOperation = 'destination-out';
        ctx.font = `normal normal 400 ${size * 3}px "${fontname}"`;
        fixedFillText(ctx, text, tCanvas.width, tCanvas.height, size * 3, align, verticalAlign, closer);

        ctx.globalCompositeOperation='source-over'; //マスクを戻す
        ctx.clearRect(0, 0, 0, tCanvas.height);//透過確認用

        const tSrc = tCanvas.toDataURL();
        const tImageElement = await loadImage(tSrc);
        const tBase = new PIXI.BaseTexture(tImageElement as HTMLImageElement);
        const tTexture = new PIXI.Texture(tBase);

        const widthRate = tCanvas.width / tTexture.width;
        const heightRate = tCanvas.height / tTexture.height;

        const spriteWidth = widthRate <= heightRate ? tTexture.width * heightRate : tTexture.width * widthRate;
        const spriteHeight = widthRate <= heightRate ? tTexture.height * heightRate : tTexture.height * widthRate;

        // 比率が低い方を大きくする
        const sprite = createSpriteFrom(count,
          tTexture,
          spriteWidth / 3,
          spriteHeight / 3,
          (spriteWidth / 3 - tCanvas.width / 3) / 2 * -1,
          tCanvas.height / 3,
          0.0,
          1.0
        )
        sprite.zIndex = INDEX_FRAME_DEFAULT;
        setCount(count + 1);
        if (currentCanvas) {
          currentCanvas.addChild(sprite);
        }
        if (frameTarget.textclip) {
          frameTarget.textclip.destroy()
        }
        setFrameTarget({
          full: frameTarget.full,
          top: frameTarget.top,
          bottom: frameTarget.bottom,
          generate: frameTarget.generate,
          textclip: sprite,
        });
      }
    }
  }

  const setTextInput = async (text: string, color: string, fontname: string) => {
    if (canvas.current) {
      const tCanvas = document.createElement("canvas");

      const size = 100;

      tCanvas.width = size * text.length * 3;
      tCanvas.height = size * 3 * 2;

      const ctx = tCanvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = color;
        ctx.font = `normal normal 400 ${size * 3}px "${fontname}"`;
        fixedFillText(ctx, text, tCanvas.width, tCanvas.height, size * 3, 'center', 'bottom', false);

        const tSrc = tCanvas.toDataURL();
        const tImageElement = await loadImage(tSrc);
        const tBase = new PIXI.BaseTexture(tImageElement as HTMLImageElement);
        const tTexture = new PIXI.Texture(tBase);

        addStampToCanvasFromTexture(tTexture);
      }
    }
  }

  const hideTextClipSetting = () => {
    setShowTextClipSetting(false);
  }

  const hideTextInput = () => {
    setShowTextInput(false);
  }

  const textClipSetting = () => {
    setShowTextClipSetting(true);
  }

  const setTextClipSetting = (text: string, color: string, align: string, verticalAlign: string, size: number, fontname: string, closer: boolean) => {
    textClip(text, color, align, verticalAlign, size, fontname, closer)
    setDefaultTextClipSetting({text, color, align, verticalAlign, size, fontname, closer})
  }

  // キャンバスにアイテムを追加する
  
  /**
   * キャンバスにアイテムを追加する 
   * @param targetData Front.loadItemsで取得されたデータ
   * @param item 選択された対象のアイテム
   */
  const addItemsToCanvas = (targetData: Items[],item: Items) => {
    if (item.type === ItemType.FRAME) {
      addFrameToCanvas(targetData, item.id);
      Analytics.record({
        name: 'useItem',
        attributes: { type: 'frame', id: item.id, name: item.name }
      });
    } else if (item.type === ItemType.STAMP) {
      if (item.faceFlg) {
        addFaceToCanvas(targetData, item.id);
        Analytics.record({
          name: 'useItem',
          attributes: { type: 'face', id: item.id, name: item.name }
        });
      } else {
        addStampToCanvasFromTargetData(targetData, item.id);
        Analytics.record({
          name: 'useItem',
          attributes: { type: 'stamp', id: item.id, name: item.name }
        });
      }
    }
  }

  const clearTextClip = () => {
    if (frameTarget.textclip) {
      frameTarget.textclip.destroy();
      setFrameTarget({
        full: frameTarget.full,
        top: frameTarget.top,
        bottom: frameTarget.bottom,
        generate: frameTarget.generate,
        textclip: null,
      });
    }
  }
  /**
   * 画像をキャンバスにフレームとして貼り付ける
   * @param base64 base64の画像データ
   */
  const addGeneratedImageToCanvas = (base64: string) => {
    // HTMLImageElementからPIXI.Textureを作成する
    const image = new Image()
    image.src = base64;
    const base  = new PIXI.BaseTexture(image);
    const texture = new PIXI.Texture(base);
    // textureからspriteを作成しキャンバスに追加
    const sprite = createSpriteFrom(count, texture, window.innerWidth, 'scale', 'center', 0, 0.5, 0.0 )
    setCount(count + 1);
    if (currentCanvas) {
      sprite.zIndex = INDEX_FRAME_DEFAULT;
      currentCanvas.addChild(sprite);
    }
    // 全消しで削除ができるように
    if (frameTarget.generate) {
      frameTarget.generate.destroy();
    }
    setFrameTarget({
      full: frameTarget.full,
      top: frameTarget.top,
      bottom: frameTarget.bottom,
      generate: sprite,
      textclip: frameTarget.textclip,
    });
  }

  return (
    <div>
      {showCodeForm && <CodeForm hideCodeForm={hideCodeForm} />}
      {showTextClipSetting && <TextClipSetting hideTextClipSetting={hideTextClipSetting} setTextClipSetting={setTextClipSetting} defaultValue={defaultTextClipSetting} clearTextClip={clearTextClip} />}
      {showRegistration && <Registration hideRegistration={hideRegistration} />}
      {showLoginUser && <LoginUser hideLoginUser={hideLoginUser} />}
      {showTextInput && <TextInput hideTextInput={hideTextInput} setTextInput={setTextInput} />}
      {product && (
        <Suspense>
          <Product product={product} hideProduct={hideProduct} openRegistration={openRegistration} />
        </Suspense>
      )}
      {prepare && <Prepare isOpen={prepare} />}
      {showPreview && <Preview 
        isOpen={showPreview}
        hidePreview={hidePreview}
        setGeneratedState={setGeneratedState}
        generated={generated}
        previewSrc={previewSrc}
        generateItems={generateItems}
        addGeneratedImageToCanvas={addGeneratedImageToCanvas}
        deleteStamps={deleteStamps}
         />}
      {showPreviewVideo && <PreviewVideo isOpen={showPreviewVideo} hidePreviewVideo={hidePreviewVideo} previewSrc={previewSrcVideo} />}
      <div className="ControllerHeader">
        {toggleController && user && !takingMovie && (
          <button
            type="button"
            className="UserButton"
            onClick={() => {
              setShowLoginUser(true);
            }}
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="click"
            data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:registration`}
          >
            <img src={userButton} alt="会員ユーザーボタン" />
          </button>
        )}
        {toggleController && !takingMovie && (
          <div>
            <button type="button" onClick={textClipSetting} className="ClipTextButton">
                <img src={clipTextImg} alt="クリップテキスト" />
            </button>
            <button
              type="button"
              className="PhotoTypeButton"
              onClick={() => {
                setPhotoType(photoType === PHOTO_TYPE.PICTURE ? PHOTO_TYPE.VIDEO : PHOTO_TYPE.PICTURE);
              }}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="click"
              data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:showInfo`}
            >
              {photoType === PHOTO_TYPE.PICTURE ? (
                <img src={switchMediaImage1} alt="カメラ切り替えボタン" />
              ) : (
                <img src={switchMediaImage2} alt="カメラ切り替えボタン" />
              )}
            </button>
            <button
              type="button"
              className="InfoButton"
              onClick={() => {
                setShowInfo(true);
              }}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="click"
              data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:showInfo`}
            >
              <img src={infoButton} alt="情報ボタン" />
            </button>
            {showInfo && <Info hideInfo={hideInfo} />}
          </div>
        )}
      </div>
      {toggleController ? (
        <div className={hasStampTarget() ? 'Controller__under-control Controller__wrapper' : 'Controller__wrapper'}>
          {hasStampTarget() ? (
            <button type="button" onClick={deleteStamp} className="Controller__delete-stamp Controller__buttons">
              <img src={deleteImage} alt="スタンプ・フレーム削除ボタン" />
            </button>
          ) : (
            <div className="Controller">
              { !takingMovie && (
              <button
                type="button"
                onClick={() => setToggleController(false)}
                className="Controller__carousel Controller__buttons"
              >
                {' '}
              </button>
              )}
              {photoType === PHOTO_TYPE.PICTURE ? (
                <button type="button" 
                  onClick={takePicture} 
                  className="Controller__shutter Controller__buttons"
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="click"
                  data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:takePicture`}
                >
                {' '}
                </button>
              ) : (
                <button type="button" 
                  onClick={() => {if (takingMovie) endTakeMovie(); else takeMovie()}}
                  className={takingMovie ? 'Controller__recording Controller__buttons' : 'Controller__record Controller__buttons'}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="click"
                  data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:${takingMovie ? 'endMovide' : 'takeMovie'}`}
                >
                {' '}
                </button>
              )
              }
              { !takingMovie && (
                <div className='Controller__control-right'>
                  <button type="button" onClick={toggleCamera} className="Controller__switch-camera Controller__buttons">
                    {' '}
                  </button>
                  <button type="button" onClick={() => {setShowTextInput(true)}} className="Controller__text-input Controller__buttons">
                    {' '}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="ItemPanel">
          <div className="ItemPanelHeader">
            <button type="button" className="ItemPanelClear" onClick={deleteStamps}>
              <div className="ItemPanelClear__image">
                <img src={clearImage} alt="スタンプ・フレームクリアボタン" />
              </div>
              <div className="ItemPanelClear__text">全消し</div>
            </button>
            <div className="ItemPanelHeader__select-type">
              <button
                type="button"
                onClick={() => setActiveType('STAMP')}
                className={`ItemPanelHeader__type ${activeType === TYPE.STAMP ? 'active' : ''}`}
              >
                <span>スタンプ</span>
              </button>
              <button
                type="button"
                onClick={() => setActiveType('FRAME')}
                className={`ItemPanelHeader__type ${activeType === TYPE.FRAME ? 'active' : ''}`}
              >
                <span>フレーム</span>
              </button>
              <button
                type="button"
                onClick={() => setActiveType('MYITEM')}
                className={`ItemPanelHeader__type ${activeType === TYPE.MYITEM ? 'active' : ''}`}
              >
                <span>マイアイテム</span>
              </button>
            </div>
            <button type="button" className="ItemPanelHeader__close" onClick={() => setToggleController(true)}>
              <img src={closeImage} alt="操作ボタン非表示ボタン" />
            </button>
          </div>
          <div className="ItemPanelItems">
            {activeType === TYPE.STAMP ? (
              <div className="ItemList">
                {stampData.length === 0 && <ItemLoading />}
                {stampData.length > 0 && stampData.map((data) => (
                  <button
                    type="button"
                    key={data.id}
                    onClick={() => addItemsToCanvas(stampData, data)}
                    className="ItemList__item"
                  >
                    <img src={data.thumbnail || ''} alt={`サムネイル画像_${data.name}`} />
                    {/* {data.price > 0 && !bought(data.productID) && (
                      <img className="ItemList__key" src={key} alt="未購入" />
                    )} */}
                  </button>
                ))}
              </div>
            ) : (
              ''
            )}

            {activeType === TYPE.FRAME ? (
              <div className="ItemList">
                {frameData.length > 0 && frameData.map((data) => (
                  <button
                    type="button"
                    key={data.id}
                    onClick={() => addItemsToCanvas(frameData, data)}
                    className="ItemList__item"
                  >
                    <img src={data.thumbnail || ''} alt={`サムネイル画像_${data.name}`} />
                    {/* {data.price > 0 && !bought(data.productID) && (
                      <img className="ItemList__key" src={key} alt="未購入" />
                    )} */}
                  </button>
                ))}
              </div>
            ) : (
              ''
            )}

            {activeType === TYPE.MYITEM ? (
              <div>
                <div className="MyItem">
                  {dynamoUser === null ? (
                    <Button 
                      onClick={() => openRegistration()}
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="click"
                      data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:registration`}
                    >会員登録・ログイン</Button>
                  ) : (
                    <Button onClick={() => openCodeForm()}
                      data-amplify-analytics-on="click"
                      data-amplify-analytics-name="click"
                      data-amplify-analytics-attrs={`ipid:${ipid},page:controller,type:openCodeForm`}
                    >シークレットコード</Button>
                  )}
                </div>
                <div className="ItemList">
                  {myItemData.map((data) => (
                    <button
                      type="button"
                      key={data.id}
                      onClick={() => addItemsToCanvas(myItemData, data)}
                      className="ItemList__item"
                    >
                      <img src={data.thumbnail || ''} alt={`サムネイル画像_${data.name}`} />
                      {/* {(data.price || 0) > 0 && !bought(data.productID) && (
                        <img className="ItemList__key" src={key} alt="未購入" />
                      )} */}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Controller;
