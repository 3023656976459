import { Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

type GetItemProps = {
  isOpen: boolean;
  hideGetItemResult: () => void;
};

const GetItemResult = (props: GetItemProps) => {
  const { isOpen, hideGetItemResult } = props;
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '20px',
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          inset: '0',
          backgroundColor: 'white',
          border: 'none',
          position: 'relative',
          textAlign: 'center',
          paddingTop: '60px',
          minWidth: '80%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <div>アイテムをゲットしました！<br/>画面が更新されます。</div>
      <Button marginTop='60px' variation="primary" onClick={hideGetItemResult}>
        閉じる
      </Button>
    </Modal>
  );
};
export default GetItemResult;
