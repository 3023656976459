import './GenerateLoading.scss';

import { Alert, Loader } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

import loadingImage from '../img/components/loading/loading.gif';

type GenerateLoadingProps = {
	percentage: number;
};

const GenerateLoading = (props: GenerateLoadingProps) => {
  const { percentage } = props;

  return (
    <Modal
      isOpen
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.85)',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        },
        content: {
					inset: 'unset',
          position: 'relative',
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: '5px',
          left: 0,
          right: 0,
					padding: 0,
          margin: 'auto',
        },
      }}
    >
      <div className="GenerateLoading">
        <Alert className='GenerateLoading__alert' isDismissible={false} hasIcon heading="画像合成中">今しばらくお待ちください
          <Loader filledColor='rgb(139 125 255)' variation="linear" percentage={percentage} isDeterminate />
					<div className="GenerateLoading__img">
    				<img src={loadingImage} alt="ローディング中" />
					</div>
        </Alert>
      </div>
    </Modal>
  );
};
export default GenerateLoading;
