import './NetworkFailure.scss';
import { Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

type NetworkFailureProps = {
  isOpen: boolean;
};

const NetworkFailure = (props: NetworkFailureProps) => {
  const { isOpen} = props;

  const refreshScreen = () => {
    window.location.reload();
  }

  return (
    <Modal
      className='NetworkFailure'
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          backgroundColor: '#fcefaa',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2,
        },
        content: {
          margin: '20',
          backgroundColor: 'white',
          border: 'none',
          position: 'fixed',
          zIndex: 2,
          textAlign: 'left',
        },
      }}
    >
      <div className='NetworkFailure__message'>
        <div>
          ネットワークの通信に失敗しました。<br/>
          画面を更新して再表示するか、通信環境の良い場所で再度お試しください。<br/>
        </div>
        <div className="NetworkFailure__buttons">
          <Button marginTop={20} width='80%' variation="primary" onClick={refreshScreen}>
            更新する
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default NetworkFailure;
