/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrdersInput = {
  id?: string | null,
  usersID: string,
  customerID?: string | null,
  ipid?: string | null,
  name?: string | null,
  thumbnail?: string | null,
  productID?: string | null,
  amount: number,
  orderStatus?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  settionID?: string | null,
  groups?: Array< string | null > | null,
};

export type ModelOrdersConditionInput = {
  usersID?: ModelIDInput | null,
  customerID?: ModelStringInput | null,
  ipid?: ModelStringInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  productID?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  orderStatus?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  settionID?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelOrdersConditionInput | null > | null,
  or?: Array< ModelOrdersConditionInput | null > | null,
  not?: ModelOrdersConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Orders = {
  __typename: "Orders",
  id: string,
  usersID: string,
  customerID?: string | null,
  ipid?: string | null,
  name?: string | null,
  thumbnail?: string | null,
  productID?: string | null,
  amount: number,
  orderStatus?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  settionID?: string | null,
  groups?: Array< string | null > | null,
};

export type UpdateOrdersInput = {
  id: string,
  usersID?: string | null,
  customerID?: string | null,
  ipid?: string | null,
  name?: string | null,
  thumbnail?: string | null,
  productID?: string | null,
  amount?: number | null,
  orderStatus?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  settionID?: string | null,
  groups?: Array< string | null > | null,
};

export type DeleteOrdersInput = {
  id: string,
};

export type CreateIPInfoInput = {
  id?: string | null,
  name: string,
  ipid: string,
  company: string,
  contract?: string | null,
  roy?: number | null,
  latitude?: number | null,
  longitude?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelIPInfoConditionInput = {
  name?: ModelStringInput | null,
  ipid?: ModelStringInput | null,
  company?: ModelStringInput | null,
  contract?: ModelStringInput | null,
  roy?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIPInfoConditionInput | null > | null,
  or?: Array< ModelIPInfoConditionInput | null > | null,
  not?: ModelIPInfoConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type IPInfo = {
  __typename: "IPInfo",
  id: string,
  name: string,
  ipid: string,
  company: string,
  contract?: string | null,
  roy?: number | null,
  latitude?: number | null,
  longitude?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateIPInfoInput = {
  id: string,
  name?: string | null,
  ipid?: string | null,
  company?: string | null,
  contract?: string | null,
  roy?: number | null,
  latitude?: number | null,
  longitude?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteIPInfoInput = {
  id: string,
};

export type CreateUsersInput = {
  id?: string | null,
  name?: string | null,
  email?: string | null,
  isAdmin?: boolean | null,
  ipid?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  customerID?: string | null,
};

export type ModelUsersConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  isAdmin?: ModelBooleanInput | null,
  ipid?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  customerID?: ModelStringInput | null,
  and?: Array< ModelUsersConditionInput | null > | null,
  or?: Array< ModelUsersConditionInput | null > | null,
  not?: ModelUsersConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Users = {
  __typename: "Users",
  id: string,
  name?: string | null,
  email?: string | null,
  isAdmin?: boolean | null,
  ipid?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  customerID?: string | null,
  Orders?: ModelOrdersConnection | null,
};

export type ModelOrdersConnection = {
  __typename: "ModelOrdersConnection",
  items:  Array<Orders | null >,
  nextToken?: string | null,
};

export type UpdateUsersInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  isAdmin?: boolean | null,
  ipid?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  customerID?: string | null,
};

export type DeleteUsersInput = {
  id: string,
};

export type CreateItemsInput = {
  id?: string | null,
  ipid: string,
  type: ItemType,
  name: string,
  main?: string | null,
  thumbnail?: string | null,
  top?: string | null,
  bottom?: string | null,
  faceFlg: boolean,
  premierFlg: boolean,
  geoFlg: boolean,
  size?: number | null,
  longitude?: number | null,
  latitude?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  dataType: DataType,
  startDate?: string | null,
  endDate?: string | null,
  price: number,
  productID?: string | null,
  groups?: Array< string | null > | null,
  jan?: string | null,
  getFlg: boolean,
};

export enum ItemType {
  STAMP = "STAMP",
  FRAME = "FRAME",
}


export enum DataType {
  ITEM = "ITEM",
  IPINFO = "IPINFO",
  USERINFO = "USERINFO",
  ORDER = "ORDER",
  ALBUM = "ALBUM",
  ADMININFO = "ADMININFO",
}


export type ModelItemsConditionInput = {
  ipid?: ModelStringInput | null,
  type?: ModelItemTypeInput | null,
  name?: ModelStringInput | null,
  main?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  top?: ModelStringInput | null,
  bottom?: ModelStringInput | null,
  faceFlg?: ModelBooleanInput | null,
  premierFlg?: ModelBooleanInput | null,
  geoFlg?: ModelBooleanInput | null,
  size?: ModelIntInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  dataType?: ModelDataTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  price?: ModelIntInput | null,
  productID?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  jan?: ModelStringInput | null,
  getFlg?: ModelBooleanInput | null,
  and?: Array< ModelItemsConditionInput | null > | null,
  or?: Array< ModelItemsConditionInput | null > | null,
  not?: ModelItemsConditionInput | null,
};

export type ModelItemTypeInput = {
  eq?: ItemType | null,
  ne?: ItemType | null,
};

export type ModelDataTypeInput = {
  eq?: DataType | null,
  ne?: DataType | null,
};

export type Items = {
  __typename: "Items",
  id: string,
  ipid: string,
  type: ItemType,
  name: string,
  main?: string | null,
  thumbnail?: string | null,
  top?: string | null,
  bottom?: string | null,
  faceFlg: boolean,
  premierFlg: boolean,
  geoFlg: boolean,
  size?: number | null,
  longitude?: number | null,
  latitude?: number | null,
  createdAt: string,
  updatedAt: string,
  dataType: DataType,
  startDate?: string | null,
  endDate?: string | null,
  price: number,
  productID?: string | null,
  groups?: Array< string | null > | null,
  jan?: string | null,
  getFlg: boolean,
};

export type UpdateItemsInput = {
  id: string,
  ipid?: string | null,
  type?: ItemType | null,
  name?: string | null,
  main?: string | null,
  thumbnail?: string | null,
  top?: string | null,
  bottom?: string | null,
  faceFlg?: boolean | null,
  premierFlg?: boolean | null,
  geoFlg?: boolean | null,
  size?: number | null,
  longitude?: number | null,
  latitude?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  dataType?: DataType | null,
  startDate?: string | null,
  endDate?: string | null,
  price?: number | null,
  productID?: string | null,
  groups?: Array< string | null > | null,
  jan?: string | null,
  getFlg?: boolean | null,
};

export type DeleteItemsInput = {
  id: string,
};

export type CreateLogsInput = {
  id?: string | null,
  ipid?: string | null,
  page?: string | null,
  func?: string | null,
  message?: string | null,
  memo?: string | null,
  createdAt?: string | null,
};

export type ModelLogsConditionInput = {
  ipid?: ModelStringInput | null,
  page?: ModelStringInput | null,
  func?: ModelStringInput | null,
  message?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLogsConditionInput | null > | null,
  or?: Array< ModelLogsConditionInput | null > | null,
  not?: ModelLogsConditionInput | null,
};

export type Logs = {
  __typename: "Logs",
  id: string,
  ipid?: string | null,
  page?: string | null,
  func?: string | null,
  message?: string | null,
  memo?: string | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateLogsInput = {
  id: string,
  ipid?: string | null,
  page?: string | null,
  func?: string | null,
  message?: string | null,
  memo?: string | null,
  createdAt?: string | null,
};

export type DeleteLogsInput = {
  id: string,
};

export type CreateMyItemsInput = {
  id?: string | null,
  ipid: string,
  usersID: string,
  itemsID: string,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelMyItemsConditionInput = {
  ipid?: ModelStringInput | null,
  usersID?: ModelIDInput | null,
  itemsID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMyItemsConditionInput | null > | null,
  or?: Array< ModelMyItemsConditionInput | null > | null,
  not?: ModelMyItemsConditionInput | null,
};

export type MyItems = {
  __typename: "MyItems",
  id: string,
  ipid: string,
  usersID: string,
  itemsID: string,
  Item?: Items | null,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateMyItemsInput = {
  id: string,
  ipid?: string | null,
  usersID?: string | null,
  itemsID?: string | null,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteMyItemsInput = {
  id: string,
};

export type CreateItemSecretsInput = {
  id?: string | null,
  ipid: string,
  code: string,
  itemsID: string,
  groups?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelItemSecretsConditionInput = {
  ipid?: ModelStringInput | null,
  code?: ModelStringInput | null,
  itemsID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelItemSecretsConditionInput | null > | null,
  or?: Array< ModelItemSecretsConditionInput | null > | null,
  not?: ModelItemSecretsConditionInput | null,
};

export type ItemSecrets = {
  __typename: "ItemSecrets",
  id: string,
  ipid: string,
  code: string,
  itemsID: string,
  Item?: Items | null,
  groups?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateItemSecretsInput = {
  id: string,
  ipid?: string | null,
  code?: string | null,
  itemsID?: string | null,
  groups?: Array< string | null > | null,
  startDate?: string | null,
  endDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteItemSecretsInput = {
  id: string,
};

export type CreateGenerateItemsInput = {
  id?: string | null,
  ipid: string,
  name: string,
  back?: string | null,
  front?: string | null,
  geoFlg: boolean,
  longitude?: number | null,
  latitude?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGenerateItemsConditionInput = {
  ipid?: ModelStringInput | null,
  name?: ModelStringInput | null,
  back?: ModelStringInput | null,
  front?: ModelStringInput | null,
  geoFlg?: ModelBooleanInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGenerateItemsConditionInput | null > | null,
  or?: Array< ModelGenerateItemsConditionInput | null > | null,
  not?: ModelGenerateItemsConditionInput | null,
};

export type GenerateItems = {
  __typename: "GenerateItems",
  id: string,
  ipid: string,
  name: string,
  back?: string | null,
  front?: string | null,
  geoFlg: boolean,
  longitude?: number | null,
  latitude?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  groups?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateGenerateItemsInput = {
  id: string,
  ipid?: string | null,
  name?: string | null,
  back?: string | null,
  front?: string | null,
  geoFlg?: boolean | null,
  longitude?: number | null,
  latitude?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  groups?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGenerateItemsInput = {
  id: string,
};

export type ModelOrdersFilterInput = {
  id?: ModelIDInput | null,
  usersID?: ModelIDInput | null,
  customerID?: ModelStringInput | null,
  ipid?: ModelStringInput | null,
  name?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  productID?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  orderStatus?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  settionID?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  and?: Array< ModelOrdersFilterInput | null > | null,
  or?: Array< ModelOrdersFilterInput | null > | null,
  not?: ModelOrdersFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIPInfoFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  ipid?: ModelStringInput | null,
  company?: ModelStringInput | null,
  contract?: ModelStringInput | null,
  roy?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIPInfoFilterInput | null > | null,
  or?: Array< ModelIPInfoFilterInput | null > | null,
  not?: ModelIPInfoFilterInput | null,
};

export type ModelIPInfoConnection = {
  __typename: "ModelIPInfoConnection",
  items:  Array<IPInfo | null >,
  nextToken?: string | null,
};

export type ModelUsersFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  isAdmin?: ModelBooleanInput | null,
  ipid?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  customerID?: ModelStringInput | null,
  and?: Array< ModelUsersFilterInput | null > | null,
  or?: Array< ModelUsersFilterInput | null > | null,
  not?: ModelUsersFilterInput | null,
};

export type ModelUsersConnection = {
  __typename: "ModelUsersConnection",
  items:  Array<Users | null >,
  nextToken?: string | null,
};

export type ModelItemsFilterInput = {
  id?: ModelIDInput | null,
  ipid?: ModelStringInput | null,
  type?: ModelItemTypeInput | null,
  name?: ModelStringInput | null,
  main?: ModelStringInput | null,
  thumbnail?: ModelStringInput | null,
  top?: ModelStringInput | null,
  bottom?: ModelStringInput | null,
  faceFlg?: ModelBooleanInput | null,
  premierFlg?: ModelBooleanInput | null,
  geoFlg?: ModelBooleanInput | null,
  size?: ModelIntInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  dataType?: ModelDataTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  price?: ModelIntInput | null,
  productID?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  jan?: ModelStringInput | null,
  getFlg?: ModelBooleanInput | null,
  and?: Array< ModelItemsFilterInput | null > | null,
  or?: Array< ModelItemsFilterInput | null > | null,
  not?: ModelItemsFilterInput | null,
};

export type ModelItemsConnection = {
  __typename: "ModelItemsConnection",
  items:  Array<Items | null >,
  nextToken?: string | null,
};

export type ModelLogsFilterInput = {
  id?: ModelIDInput | null,
  ipid?: ModelStringInput | null,
  page?: ModelStringInput | null,
  func?: ModelStringInput | null,
  message?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLogsFilterInput | null > | null,
  or?: Array< ModelLogsFilterInput | null > | null,
  not?: ModelLogsFilterInput | null,
};

export type ModelLogsConnection = {
  __typename: "ModelLogsConnection",
  items:  Array<Logs | null >,
  nextToken?: string | null,
};

export type ModelMyItemsFilterInput = {
  id?: ModelIDInput | null,
  ipid?: ModelStringInput | null,
  usersID?: ModelIDInput | null,
  itemsID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMyItemsFilterInput | null > | null,
  or?: Array< ModelMyItemsFilterInput | null > | null,
  not?: ModelMyItemsFilterInput | null,
};

export type ModelMyItemsConnection = {
  __typename: "ModelMyItemsConnection",
  items:  Array<MyItems | null >,
  nextToken?: string | null,
};

export type ModelItemSecretsFilterInput = {
  id?: ModelIDInput | null,
  ipid?: ModelStringInput | null,
  code?: ModelStringInput | null,
  itemsID?: ModelIDInput | null,
  groups?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelItemSecretsFilterInput | null > | null,
  or?: Array< ModelItemSecretsFilterInput | null > | null,
  not?: ModelItemSecretsFilterInput | null,
};

export type ModelItemSecretsConnection = {
  __typename: "ModelItemSecretsConnection",
  items:  Array<ItemSecrets | null >,
  nextToken?: string | null,
};

export type ModelGenerateItemsFilterInput = {
  id?: ModelIDInput | null,
  ipid?: ModelStringInput | null,
  name?: ModelStringInput | null,
  back?: ModelStringInput | null,
  front?: ModelStringInput | null,
  geoFlg?: ModelBooleanInput | null,
  longitude?: ModelFloatInput | null,
  latitude?: ModelFloatInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  groups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGenerateItemsFilterInput | null > | null,
  or?: Array< ModelGenerateItemsFilterInput | null > | null,
  not?: ModelGenerateItemsFilterInput | null,
};

export type ModelGenerateItemsConnection = {
  __typename: "ModelGenerateItemsConnection",
  items:  Array<GenerateItems | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionOrdersFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  customerID?: ModelSubscriptionStringInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  productID?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  orderStatus?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  settionID?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrdersFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrdersFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIPInfoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  company?: ModelSubscriptionStringInput | null,
  contract?: ModelSubscriptionStringInput | null,
  roy?: ModelSubscriptionFloatInput | null,
  latitude?: ModelSubscriptionFloatInput | null,
  longitude?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIPInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionIPInfoFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUsersFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  isAdmin?: ModelSubscriptionBooleanInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  customerID?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUsersFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsersFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  main?: ModelSubscriptionStringInput | null,
  thumbnail?: ModelSubscriptionStringInput | null,
  top?: ModelSubscriptionStringInput | null,
  bottom?: ModelSubscriptionStringInput | null,
  faceFlg?: ModelSubscriptionBooleanInput | null,
  premierFlg?: ModelSubscriptionBooleanInput | null,
  geoFlg?: ModelSubscriptionBooleanInput | null,
  size?: ModelSubscriptionIntInput | null,
  longitude?: ModelSubscriptionFloatInput | null,
  latitude?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  dataType?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionIntInput | null,
  productID?: ModelSubscriptionStringInput | null,
  jan?: ModelSubscriptionStringInput | null,
  getFlg?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemsFilterInput | null > | null,
};

export type ModelSubscriptionLogsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  page?: ModelSubscriptionStringInput | null,
  func?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  memo?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLogsFilterInput | null > | null,
  or?: Array< ModelSubscriptionLogsFilterInput | null > | null,
};

export type ModelSubscriptionMyItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  itemsID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMyItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionMyItemsFilterInput | null > | null,
};

export type ModelSubscriptionItemSecretsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  itemsID?: ModelSubscriptionIDInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionItemSecretsFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemSecretsFilterInput | null > | null,
};

export type ModelSubscriptionGenerateItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ipid?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  back?: ModelSubscriptionStringInput | null,
  front?: ModelSubscriptionStringInput | null,
  geoFlg?: ModelSubscriptionBooleanInput | null,
  longitude?: ModelSubscriptionFloatInput | null,
  latitude?: ModelSubscriptionFloatInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGenerateItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionGenerateItemsFilterInput | null > | null,
};

export type CreateOrdersMutationVariables = {
  input: CreateOrdersInput,
  condition?: ModelOrdersConditionInput | null,
};

export type CreateOrdersMutation = {
  createOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type UpdateOrdersMutationVariables = {
  input: UpdateOrdersInput,
  condition?: ModelOrdersConditionInput | null,
};

export type UpdateOrdersMutation = {
  updateOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type DeleteOrdersMutationVariables = {
  input: DeleteOrdersInput,
  condition?: ModelOrdersConditionInput | null,
};

export type DeleteOrdersMutation = {
  deleteOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type CreateIPInfoMutationVariables = {
  input: CreateIPInfoInput,
  condition?: ModelIPInfoConditionInput | null,
};

export type CreateIPInfoMutation = {
  createIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateIPInfoMutationVariables = {
  input: UpdateIPInfoInput,
  condition?: ModelIPInfoConditionInput | null,
};

export type UpdateIPInfoMutation = {
  updateIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteIPInfoMutationVariables = {
  input: DeleteIPInfoInput,
  condition?: ModelIPInfoConditionInput | null,
};

export type DeleteIPInfoMutation = {
  deleteIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateUsersMutationVariables = {
  input: CreateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type CreateUsersMutation = {
  createUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUsersMutationVariables = {
  input: UpdateUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type UpdateUsersMutation = {
  updateUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUsersMutationVariables = {
  input: DeleteUsersInput,
  condition?: ModelUsersConditionInput | null,
};

export type DeleteUsersMutation = {
  deleteUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateItemsMutationVariables = {
  input: CreateItemsInput,
  condition?: ModelItemsConditionInput | null,
};

export type CreateItemsMutation = {
  createItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type UpdateItemsMutationVariables = {
  input: UpdateItemsInput,
  condition?: ModelItemsConditionInput | null,
};

export type UpdateItemsMutation = {
  updateItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type DeleteItemsMutationVariables = {
  input: DeleteItemsInput,
  condition?: ModelItemsConditionInput | null,
};

export type DeleteItemsMutation = {
  deleteItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type CreateLogsMutationVariables = {
  input: CreateLogsInput,
  condition?: ModelLogsConditionInput | null,
};

export type CreateLogsMutation = {
  createLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateLogsMutationVariables = {
  input: UpdateLogsInput,
  condition?: ModelLogsConditionInput | null,
};

export type UpdateLogsMutation = {
  updateLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteLogsMutationVariables = {
  input: DeleteLogsInput,
  condition?: ModelLogsConditionInput | null,
};

export type DeleteLogsMutation = {
  deleteLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateMyItemsMutationVariables = {
  input: CreateMyItemsInput,
  condition?: ModelMyItemsConditionInput | null,
};

export type CreateMyItemsMutation = {
  createMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateMyItemsMutationVariables = {
  input: UpdateMyItemsInput,
  condition?: ModelMyItemsConditionInput | null,
};

export type UpdateMyItemsMutation = {
  updateMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteMyItemsMutationVariables = {
  input: DeleteMyItemsInput,
  condition?: ModelMyItemsConditionInput | null,
};

export type DeleteMyItemsMutation = {
  deleteMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateItemSecretsMutationVariables = {
  input: CreateItemSecretsInput,
  condition?: ModelItemSecretsConditionInput | null,
};

export type CreateItemSecretsMutation = {
  createItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateItemSecretsMutationVariables = {
  input: UpdateItemSecretsInput,
  condition?: ModelItemSecretsConditionInput | null,
};

export type UpdateItemSecretsMutation = {
  updateItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteItemSecretsMutationVariables = {
  input: DeleteItemSecretsInput,
  condition?: ModelItemSecretsConditionInput | null,
};

export type DeleteItemSecretsMutation = {
  deleteItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateGenerateItemsMutationVariables = {
  input: CreateGenerateItemsInput,
  condition?: ModelGenerateItemsConditionInput | null,
};

export type CreateGenerateItemsMutation = {
  createGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGenerateItemsMutationVariables = {
  input: UpdateGenerateItemsInput,
  condition?: ModelGenerateItemsConditionInput | null,
};

export type UpdateGenerateItemsMutation = {
  updateGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGenerateItemsMutationVariables = {
  input: DeleteGenerateItemsInput,
  condition?: ModelGenerateItemsConditionInput | null,
};

export type DeleteGenerateItemsMutation = {
  deleteGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOrdersQueryVariables = {
  id: string,
};

export type GetOrdersQuery = {
  getOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrdersConnection",
    items:  Array< {
      __typename: "Orders",
      id: string,
      usersID: string,
      customerID?: string | null,
      ipid?: string | null,
      name?: string | null,
      thumbnail?: string | null,
      productID?: string | null,
      amount: number,
      orderStatus?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      settionID?: string | null,
      groups?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByUsersIDQueryVariables = {
  usersID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByUsersIDQuery = {
  ordersByUsersID?:  {
    __typename: "ModelOrdersConnection",
    items:  Array< {
      __typename: "Orders",
      id: string,
      usersID: string,
      customerID?: string | null,
      ipid?: string | null,
      name?: string | null,
      thumbnail?: string | null,
      productID?: string | null,
      amount: number,
      orderStatus?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      settionID?: string | null,
      groups?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByIpidAndOrderStatusQueryVariables = {
  ipid: string,
  orderStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByIpidAndOrderStatusQuery = {
  ordersByIpidAndOrderStatus?:  {
    __typename: "ModelOrdersConnection",
    items:  Array< {
      __typename: "Orders",
      id: string,
      usersID: string,
      customerID?: string | null,
      ipid?: string | null,
      name?: string | null,
      thumbnail?: string | null,
      productID?: string | null,
      amount: number,
      orderStatus?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      settionID?: string | null,
      groups?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIPInfoQueryVariables = {
  id: string,
};

export type GetIPInfoQuery = {
  getIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListIPInfosQueryVariables = {
  filter?: ModelIPInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIPInfosQuery = {
  listIPInfos?:  {
    __typename: "ModelIPInfoConnection",
    items:  Array< {
      __typename: "IPInfo",
      id: string,
      name: string,
      ipid: string,
      company: string,
      contract?: string | null,
      roy?: number | null,
      latitude?: number | null,
      longitude?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IPInfosByIpidQueryVariables = {
  ipid: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIPInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IPInfosByIpidQuery = {
  iPInfosByIpid?:  {
    __typename: "ModelIPInfoConnection",
    items:  Array< {
      __typename: "IPInfo",
      id: string,
      name: string,
      ipid: string,
      company: string,
      contract?: string | null,
      roy?: number | null,
      latitude?: number | null,
      longitude?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsersQueryVariables = {
  id: string,
};

export type GetUsersQuery = {
  getUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUsersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUsersConnection",
    items:  Array< {
      __typename: "Users",
      id: string,
      name?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      ipid?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      customerID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUsersConnection",
    items:  Array< {
      __typename: "Users",
      id: string,
      name?: string | null,
      email?: string | null,
      isAdmin?: boolean | null,
      ipid?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      customerID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemsQueryVariables = {
  id: string,
};

export type GetItemsQuery = {
  getItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type ListItemsQueryVariables = {
  filter?: ModelItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemsConnection",
    items:  Array< {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByIpidQueryVariables = {
  ipid: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByIpidQuery = {
  itemsByIpid?:  {
    __typename: "ModelItemsConnection",
    items:  Array< {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLogsQueryVariables = {
  id: string,
};

export type GetLogsQuery = {
  getLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListLogsQueryVariables = {
  filter?: ModelLogsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLogsQuery = {
  listLogs?:  {
    __typename: "ModelLogsConnection",
    items:  Array< {
      __typename: "Logs",
      id: string,
      ipid?: string | null,
      page?: string | null,
      func?: string | null,
      message?: string | null,
      memo?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMyItemsQueryVariables = {
  id: string,
};

export type GetMyItemsQuery = {
  getMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListMyItemsQueryVariables = {
  filter?: ModelMyItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMyItemsQuery = {
  listMyItems?:  {
    __typename: "ModelMyItemsConnection",
    items:  Array< {
      __typename: "MyItems",
      id: string,
      ipid: string,
      usersID: string,
      itemsID: string,
      groups?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MyItemsByUsersIDQueryVariables = {
  usersID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMyItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MyItemsByUsersIDQuery = {
  myItemsByUsersID?:  {
    __typename: "ModelMyItemsConnection",
    items:  Array< {
      __typename: "MyItems",
      id: string,
      ipid: string,
      usersID: string,
      itemsID: string,
      groups?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemSecretsQueryVariables = {
  id: string,
};

export type GetItemSecretsQuery = {
  getItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListItemSecretsQueryVariables = {
  filter?: ModelItemSecretsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemSecretsQuery = {
  listItemSecrets?:  {
    __typename: "ModelItemSecretsConnection",
    items:  Array< {
      __typename: "ItemSecrets",
      id: string,
      ipid: string,
      code: string,
      itemsID: string,
      groups?: Array< string | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemSecretsByIpidQueryVariables = {
  ipid: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemSecretsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemSecretsByIpidQuery = {
  itemSecretsByIpid?:  {
    __typename: "ModelItemSecretsConnection",
    items:  Array< {
      __typename: "ItemSecrets",
      id: string,
      ipid: string,
      code: string,
      itemsID: string,
      groups?: Array< string | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemSecretsByCodeQueryVariables = {
  code: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemSecretsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemSecretsByCodeQuery = {
  itemSecretsByCode?:  {
    __typename: "ModelItemSecretsConnection",
    items:  Array< {
      __typename: "ItemSecrets",
      id: string,
      ipid: string,
      code: string,
      itemsID: string,
      groups?: Array< string | null > | null,
      startDate?: string | null,
      endDate?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGenerateItemsQueryVariables = {
  id: string,
};

export type GetGenerateItemsQuery = {
  getGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGenerateItemsQueryVariables = {
  filter?: ModelGenerateItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGenerateItemsQuery = {
  listGenerateItems?:  {
    __typename: "ModelGenerateItemsConnection",
    items:  Array< {
      __typename: "GenerateItems",
      id: string,
      ipid: string,
      name: string,
      back?: string | null,
      front?: string | null,
      geoFlg: boolean,
      longitude?: number | null,
      latitude?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GenerateItemsByIpidQueryVariables = {
  ipid: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGenerateItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GenerateItemsByIpidQuery = {
  generateItemsByIpid?:  {
    __typename: "ModelGenerateItemsConnection",
    items:  Array< {
      __typename: "GenerateItems",
      id: string,
      ipid: string,
      name: string,
      back?: string | null,
      front?: string | null,
      geoFlg: boolean,
      longitude?: number | null,
      latitude?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      groups?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrdersSubscriptionVariables = {
  filter?: ModelSubscriptionOrdersFilterInput | null,
  usersID?: string | null,
};

export type OnCreateOrdersSubscription = {
  onCreateOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type OnUpdateOrdersSubscriptionVariables = {
  filter?: ModelSubscriptionOrdersFilterInput | null,
  usersID?: string | null,
};

export type OnUpdateOrdersSubscription = {
  onUpdateOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type OnDeleteOrdersSubscriptionVariables = {
  filter?: ModelSubscriptionOrdersFilterInput | null,
  usersID?: string | null,
};

export type OnDeleteOrdersSubscription = {
  onDeleteOrders?:  {
    __typename: "Orders",
    id: string,
    usersID: string,
    customerID?: string | null,
    ipid?: string | null,
    name?: string | null,
    thumbnail?: string | null,
    productID?: string | null,
    amount: number,
    orderStatus?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    settionID?: string | null,
    groups?: Array< string | null > | null,
  } | null,
};

export type OnCreateIPInfoSubscriptionVariables = {
  filter?: ModelSubscriptionIPInfoFilterInput | null,
};

export type OnCreateIPInfoSubscription = {
  onCreateIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateIPInfoSubscriptionVariables = {
  filter?: ModelSubscriptionIPInfoFilterInput | null,
};

export type OnUpdateIPInfoSubscription = {
  onUpdateIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteIPInfoSubscriptionVariables = {
  filter?: ModelSubscriptionIPInfoFilterInput | null,
};

export type OnDeleteIPInfoSubscription = {
  onDeleteIPInfo?:  {
    __typename: "IPInfo",
    id: string,
    name: string,
    ipid: string,
    company: string,
    contract?: string | null,
    roy?: number | null,
    latitude?: number | null,
    longitude?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
  id?: string | null,
};

export type OnCreateUsersSubscription = {
  onCreateUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
  id?: string | null,
};

export type OnUpdateUsersSubscription = {
  onUpdateUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUsersSubscriptionVariables = {
  filter?: ModelSubscriptionUsersFilterInput | null,
  id?: string | null,
};

export type OnDeleteUsersSubscription = {
  onDeleteUsers?:  {
    __typename: "Users",
    id: string,
    name?: string | null,
    email?: string | null,
    isAdmin?: boolean | null,
    ipid?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    customerID?: string | null,
    Orders?:  {
      __typename: "ModelOrdersConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateItemsSubscriptionVariables = {
  filter?: ModelSubscriptionItemsFilterInput | null,
};

export type OnCreateItemsSubscription = {
  onCreateItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type OnUpdateItemsSubscriptionVariables = {
  filter?: ModelSubscriptionItemsFilterInput | null,
};

export type OnUpdateItemsSubscription = {
  onUpdateItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type OnDeleteItemsSubscriptionVariables = {
  filter?: ModelSubscriptionItemsFilterInput | null,
};

export type OnDeleteItemsSubscription = {
  onDeleteItems?:  {
    __typename: "Items",
    id: string,
    ipid: string,
    type: ItemType,
    name: string,
    main?: string | null,
    thumbnail?: string | null,
    top?: string | null,
    bottom?: string | null,
    faceFlg: boolean,
    premierFlg: boolean,
    geoFlg: boolean,
    size?: number | null,
    longitude?: number | null,
    latitude?: number | null,
    createdAt: string,
    updatedAt: string,
    dataType: DataType,
    startDate?: string | null,
    endDate?: string | null,
    price: number,
    productID?: string | null,
    groups?: Array< string | null > | null,
    jan?: string | null,
    getFlg: boolean,
  } | null,
};

export type OnCreateLogsSubscriptionVariables = {
  filter?: ModelSubscriptionLogsFilterInput | null,
};

export type OnCreateLogsSubscription = {
  onCreateLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateLogsSubscriptionVariables = {
  filter?: ModelSubscriptionLogsFilterInput | null,
};

export type OnUpdateLogsSubscription = {
  onUpdateLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteLogsSubscriptionVariables = {
  filter?: ModelSubscriptionLogsFilterInput | null,
};

export type OnDeleteLogsSubscription = {
  onDeleteLogs?:  {
    __typename: "Logs",
    id: string,
    ipid?: string | null,
    page?: string | null,
    func?: string | null,
    message?: string | null,
    memo?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateMyItemsSubscriptionVariables = {
  filter?: ModelSubscriptionMyItemsFilterInput | null,
  usersID?: string | null,
};

export type OnCreateMyItemsSubscription = {
  onCreateMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateMyItemsSubscriptionVariables = {
  filter?: ModelSubscriptionMyItemsFilterInput | null,
  usersID?: string | null,
};

export type OnUpdateMyItemsSubscription = {
  onUpdateMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteMyItemsSubscriptionVariables = {
  filter?: ModelSubscriptionMyItemsFilterInput | null,
  usersID?: string | null,
};

export type OnDeleteMyItemsSubscription = {
  onDeleteMyItems?:  {
    __typename: "MyItems",
    id: string,
    ipid: string,
    usersID: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateItemSecretsSubscriptionVariables = {
  filter?: ModelSubscriptionItemSecretsFilterInput | null,
};

export type OnCreateItemSecretsSubscription = {
  onCreateItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateItemSecretsSubscriptionVariables = {
  filter?: ModelSubscriptionItemSecretsFilterInput | null,
};

export type OnUpdateItemSecretsSubscription = {
  onUpdateItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteItemSecretsSubscriptionVariables = {
  filter?: ModelSubscriptionItemSecretsFilterInput | null,
};

export type OnDeleteItemSecretsSubscription = {
  onDeleteItemSecrets?:  {
    __typename: "ItemSecrets",
    id: string,
    ipid: string,
    code: string,
    itemsID: string,
    Item?:  {
      __typename: "Items",
      id: string,
      ipid: string,
      type: ItemType,
      name: string,
      main?: string | null,
      thumbnail?: string | null,
      top?: string | null,
      bottom?: string | null,
      faceFlg: boolean,
      premierFlg: boolean,
      geoFlg: boolean,
      size?: number | null,
      longitude?: number | null,
      latitude?: number | null,
      createdAt: string,
      updatedAt: string,
      dataType: DataType,
      startDate?: string | null,
      endDate?: string | null,
      price: number,
      productID?: string | null,
      groups?: Array< string | null > | null,
      jan?: string | null,
      getFlg: boolean,
    } | null,
    groups?: Array< string | null > | null,
    startDate?: string | null,
    endDate?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateGenerateItemsSubscriptionVariables = {
  filter?: ModelSubscriptionGenerateItemsFilterInput | null,
};

export type OnCreateGenerateItemsSubscription = {
  onCreateGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGenerateItemsSubscriptionVariables = {
  filter?: ModelSubscriptionGenerateItemsFilterInput | null,
};

export type OnUpdateGenerateItemsSubscription = {
  onUpdateGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGenerateItemsSubscriptionVariables = {
  filter?: ModelSubscriptionGenerateItemsFilterInput | null,
};

export type OnDeleteGenerateItemsSubscription = {
  onDeleteGenerateItems?:  {
    __typename: "GenerateItems",
    id: string,
    ipid: string,
    name: string,
    back?: string | null,
    front?: string | null,
    geoFlg: boolean,
    longitude?: number | null,
    latitude?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    groups?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
