// CSS
import './LoginUser.scss';
import { Button } from '@aws-amplify/ui-react';
import { useContext } from 'react';
import Modal from 'react-modal';

// LOGIC
import orderHistoryImage from '../img/components/controller/orderhistory.png';

import { UserContext } from './providers/UserProvider';
import Thumbnail from './Thumbnail';

type LoginUserProps = {
  hideLoginUser: () => void;
};

const LoginUser = (props: LoginUserProps) => {
  const { hideLoginUser } = props;

  // ユーザーの取得
  const { user, dynamoUser } = useContext(UserContext);

  const logout = async () => {
    await user?.signOut();
    window.location.reload();
  };

  return (
    <Modal
      isOpen
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '0px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
        },
        content: {
          inset: '0',
          backgroundColor: 'white',
          border: 'none',
          position: 'relative',
          width: '100%',
        },
      }}
    >
      <div className="LoginUser">
        <div className="LoginUser__block">
          <div className="LoginUser__header">
            <h2>
              ログイン済み
              {/* <img src={orderHistoryImage} alt="購入履歴" /> */}
            </h2>
          </div>
          {/* <div className="LoginUser__order-list OrderTable">
            {dynamoUser &&
              dynamoUser.Orders &&
              dynamoUser.Orders.items.map((order, index) => (
                <div className="OrderTable__order" key={order && order.id}>
                  <hr className="OrderTable__hr" />
                  <div className="OrderTable__header">{order?.name}</div>
                  <div className="OrderTable__details">
                    <div className="OrderTable__thumbnail">
                      <Thumbnail src={order?.thumbnail || ''} name={order?.name || ''} />
                    </div>
                    <div className="OrderTable__detail">
                      <div className="OrderTable__datetime">
                        注文日時: {new Date(order?.updatedAt || '').toLocaleString()}
                      </div>
                      <div className="OrderTable__order-id">注文番号: {order?.id}</div>
                      <div className="OrderTable__amount">お支払い: ¥{order?.amount}（税込）</div>
                    </div>
                  </div>
                </div>
              ))}
          </div> */}
          <div className="LoginUser__buttons">
            <Button className="LoginUser__closeButton" onClick={hideLoginUser}>
              閉じる
            </Button>
            <Button className="LoginUser__logoutButton" onClick={logout}>
              ログアウト
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default LoginUser;
