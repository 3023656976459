import './Commerce.scss';
import { Flex, Table, TableCell, TableRow } from '@aws-amplify/ui-react';

const Commerce = () => (
  <section className="Commerce">
    <div className="Commerce__block">
      <h1 className="title-block__info">特定商取引法に基づく表記</h1>
      <div className="section-block js-scroll-target">
        <Flex direction="column">
          <Table variation="striped" backgroundColor="white">
            <TableRow>
              <TableCell>販売業者</TableCell>
              <TableCell>株式会社ヴィレッジヴァンガードコーポレーション</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>所在地</TableCell>
              <TableCell>愛知県名古屋市名東区上社1丁目901番</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>代表者</TableCell>
              <TableCell>白川篤典</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>販売責任者</TableCell>
              <TableCell>白川篤典</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>古物商許可証番号</TableCell>
              <TableCell>愛知県公安委員会許可 第541161903600号</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>連絡先</TableCell>
              <TableCell>0120-911-790</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>商品の価格</TableCell>
              <TableCell>各商品ページに表示します。</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>商品代金以外の付帯費用</TableCell>
              <TableCell>
                ※インターネット接続料金その他の電気通信回線の通信に関する費用はお客様にて別途ご用意頂く必要があります。
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>お支払い方法</TableCell>
              <TableCell>クレジット決済</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>お支払い期限</TableCell>
              <TableCell>
                ご利用のクレジットカードの締め日や契約内容により異なります。利用されているカード会社までお問い合わせください。
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>商品の引渡し又はサービスの提供時期期間</TableCell>
              <TableCell>サービス内課金によるお支払い方法の手続きが完了次第、すぐにご利用いただけます。</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>返品・交換について</TableCell>
              <TableCell>デジタルコンテンツの性質上、購入後の返品・交換はできません。</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>免責事項について</TableCell>
              <TableCell>
                サーバートラブル、ネットワークトラブルその他不可抗力により生じた商品の提供不能、中断については、弊社に重大な過失がある場合を除き、その責任を負わないものとします。
              </TableCell>
            </TableRow>
          </Table>
        </Flex>
      </div>
    </div>
  </section>
);

export default Commerce;
