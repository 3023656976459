// デザイン
import './VideoBlack.scss';
import { Button, TextField } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

// 画像
import browser from '../../img/components/failures/black-browser.png';
import searchBox from '../../img/components/failures/black-search-box.png';
import close from '../../img/components/preview/close.png';
import logo from '../../img/components/start/oshidori_logo.png';


type VideoBlackProps = {
  hideVideBlack: () => void;
};


const VideoBlack = (props: VideoBlackProps) => {
  const { hideVideBlack } = props;


  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.origin + window.location.pathname).then(() => {
      window.alert('コピーしました。')
    })
  }

  return (
    <Modal
      isOpen
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          inset: '0px',
          display: 'flex',
          backgroundColor: '#fcefaa',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          inset: '0',
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          position: 'relative',
        },
      }}
    >
      <div className="VideoBlack">
        <div className="VideoBlack__block">
          <button type="button" className="VideoBlack__close" onClick={hideVideBlack}>
            <img src={close} alt="黒画面を閉じるボタン" />
          </button>
          <div className="VideoBlack__logo">
            <img className="VideoBlack__logo-img" src={logo} alt="推しドリロゴ" />
          </div>
          <div className="VideoBlack__header">
            <h2>黒い画面が表示されましたか</h2>
            <hr />
          </div>
          <div className="VideoBlack__contents">
            <p>【黒い画面が表示された時の対処法】</p>
            <p>1. 下記のURLをコピーしてください</p>
            
            <TextField
              label="" 
              defaultValue={window.location.origin + window.location.pathname}
              outerEndComponent={<Button onClick={copyUrl}><svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM382 896h-.2L232 746.2v-.2h150v150z" /></svg></Button>} />
            <p>2. SafariまたはChromeのアプリを開いてください</p>
            <img src={browser} alt="ブラウザの選択" />
            <p>3. コピーしたURLを、検索ボックスに貼り付けて再度アクセスしてください</p>
            <img src={searchBox} alt="検索ボックスに貼り付け" />
          </div>
          <div className="VideoBlack__terms" />
          <div className="VideoBlack__buttons">
            <Button className="VideoBlack__button" onClick={hideVideBlack}>
              閉じる
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default VideoBlack;
