import { GraphQLResult } from '@aws-amplify/api-graphql';
import { CognitoUser } from '@aws-amplify/auth';
import { API, graphqlOperation } from 'aws-amplify';
import { createContext, useState,  ReactElement, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
// Graph API

import { Users } from '../../API';
import { getUsers } from '../../graphql/queries';

export const UserContext = createContext(
  {} as {
    user: CognitoUser | null;
    setUser: Dispatch<SetStateAction<CognitoUser | null>>;
    dynamoUser: Users | null;
    getDynamoUser: () => void;
  }
);

export const UserProvider = (props: Record<string, ReactElement>) => {
  const { children } = props;
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [dynamoUser, setDynamoUser] = useState<Users | null>(null);

  const getDynamoUser = () => {
    if (user) {
      user.getUserAttributes(async (error, result) => {
        if (result) {
          let id = '';
          for (let i = 0; i < result.length; i += 1) {
            if (result[i].getName() === 'sub') {
              id = result[i].getValue();
              break;
            }
          }
          const graphResult: GraphQLResult<any> = await API.graphql(
            graphqlOperation(getUsers, {
              id,
            })
          );
          if (graphResult.data && graphResult.data.getUsers) {
            const dUser: Users = graphResult.data.getUsers as Users;
            // 管理者の場合はログアウト
            if (dUser.isAdmin) {
              user.signOut();
              window.location.reload();
            } else {
              setDynamoUser(dUser);
            }
          }
        }
      });
    }
  };

  // Cognitoユーザーがセットされた時に、DynamoDBユーザーの情報を取得する
  useEffect(() => {
    getDynamoUser();
  }, [user]);

  const userValue = useMemo(() => ({
    user,
    setUser,
    dynamoUser,
    getDynamoUser
  }), [user, dynamoUser])

  return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>;
};
