/** *************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 ************************************************************************* */

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { isSuperAdmin } from "./Utils";
import orderIcon from '../img/admin/icon-order.png'
import secretIcon from '../img/admin/icon-secret.png';
import generateIcon from '../img/admin/icon-generate.png';

export default function SideBar(props) {
  React.useEffect(()=> {
    const checkAdmin = async () => {
      const isAdmin = await isSuperAdmin();
      setIsAmin(isAdmin);
    }
    checkAdmin()
  }, [])
  const {ips, stamps, orders, logout, users, secrets, generateItems, overrides, ...rest } = props;
  const [isAdmin, setIsAmin] = React.useState(false);

  return (
    <Flex
      gap="10px"
      direction="row"
      width="385px"
      height="762px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="32px 0px 32px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SideBar")}
      {...rest}
    >
      <Flex
        gap="32px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 321")}
      >
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 32px 0px 32px"
          {...getOverrideProps(overrides, "Menu")}
        >
          {isAdmin && (
          <Flex
            className="SideBar__item"
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ManageMenu29767090")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="管理者メニュー"
              {...getOverrideProps(
                overrides,
                "\u7BA1\u7406\u8005\u30E1\u30CB\u30E5\u30FC"
              )}
            ></Text>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={ips}
              {...getOverrideProps(overrides, "IPLink")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Icon29767093")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "mdi:ip")}
                >
                  <Icon
                    width="18px"
                    height="18px"
                    viewBox={{ minX: 0, minY: 0, width: 18, height: 18 }}
                    paths={[
                      {
                        d: "M13 8L11 8L11 6L13 6L13 8ZM0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 4.44089e-16 2 0L16 0C16.5304 4.44089e-16 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2L18 16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18L2 18C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 4.44089e-16 16.5304 0 16L0 2ZM7 4L5 4L5 14L7 14L7 4ZM9 14L11 14L11 10L13 10C13.5304 10 14.0391 9.78929 14.4142 9.41421C14.7893 9.03914 15 8.53043 15 8L15 6C15 5.46957 14.7893 4.96086 14.4142 4.58579C14.0391 4.21071 13.5304 4 13 4L9 4L9 14Z",
                        fill: "rgba(0,0,0,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="12.5%"
                    bottom="12.5%"
                    left="12.5%"
                    right="12.5%"
                    {...getOverrideProps(overrides, "Vector36482692")}
                  ></Icon>
                </View>
              </View>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="IP管理"
                {...getOverrideProps(overrides, "IP\u7BA1\u7406")}
              ></Text>
            </Flex>
            <Flex
              className="SideBar__item"
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={users}
              {...getOverrideProps(overrides, "AdminLink")}
            >
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Icon29767097")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  position="absolute"
                  top="0px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "wpf:administrator")}
                >
                  <Icon
                    width="20.25px"
                    height="23.75px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 20.25244140625,
                      height: 23.75,
                    }}
                    paths={[
                      {
                        d: "M13.4151 14.5329C13.2683 14.4849 12.3406 14.0668 12.9203 12.3046L12.912 12.3046C14.4231 10.7483 15.5778 8.244 15.5778 5.77846C15.5778 1.98738 13.0569 0 10.1271 0C7.19538 0 4.68831 1.98646 4.68831 5.77846C4.68831 8.25415 5.83662 10.7686 7.35692 12.3212C7.94954 13.8757 6.88985 14.4526 6.66831 14.5329C3.6 15.6434 0 17.6658 0 19.6625L0 20.4111C0 23.1314 5.27446 23.7498 10.1557 23.7498C15.0443 23.7498 20.2523 23.1314 20.2523 20.4111L20.2523 19.6625C20.2523 17.6058 16.6348 15.5991 13.4151 14.5329ZM8.32339 22.572C8.32339 20.2191 9.82154 17.0428 9.82154 17.0428L8.78492 16.2295C8.78492 15.4523 10.1262 14.6391 10.1262 14.6391C10.1262 14.6391 11.4637 15.4652 11.4637 16.2295L10.4308 17.0428C10.4308 17.0428 11.9289 20.2071 11.9289 22.5978C11.9289 22.9726 8.32339 22.8858 8.32339 22.572Z",
                        fill: "rgba(0,0,0,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0.6%"
                    bottom="0.44%"
                    left="7.81%"
                    right="7.81%"
                    {...getOverrideProps(overrides, "Vector36482690")}
                  ></Icon>
                </View>
              </View>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="ユーザー管理"
                {...getOverrideProps(
                  overrides,
                  "\u30E6\u30FC\u30B6\u30FC\u7BA1\u7406"
                )}
              ></Text>
            </Flex>
          </Flex>
          )}
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ManageMenu29767100")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="管理メニュー"
              {...getOverrideProps(
                overrides,
                "\u7BA1\u7406\u30E1\u30CB\u30E5\u30FC"
              )}
            ></Text>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "MnageLinks")}
            >
              <Flex
                className="SideBar__item"
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={stamps}
                {...getOverrideProps(overrides, "StampFrameLink")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon29767104")}
                >
                  <View
                    width="24px"
                    height="24px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "ph:stamp-fill")}
                  >
                    <Icon
                      width="24px"
                      height="24px"
                      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
                      paths={[
                        {
                          d: "M24 23.1111C24 23.3469 23.8946 23.573 23.7071 23.7397C23.5196 23.9064 23.2652 24 23 24L1 24C0.734784 24 0.48043 23.9064 0.292893 23.7397C0.105357 23.573 0 23.3469 0 23.1111C0 22.8754 0.105357 22.6493 0.292893 22.4826C0.48043 22.3159 0.734784 22.2222 1 22.2222L23 22.2222C23.2652 22.2222 23.5196 22.3159 23.7071 22.4826C23.8946 22.6493 24 22.8754 24 23.1111ZM22 12.4444L14.9463 12.4444L16.91 4.30111C17.0351 3.78277 17.0281 3.24613 16.8897 2.73047C16.7513 2.21481 16.485 1.73319 16.1101 1.32085C15.7353 0.908518 15.2615 0.575907 14.7233 0.347367C14.1852 0.118827 13.5964 0.000142312 13 0L11 0C10.4036 0.000142312 9.81479 0.118827 9.27666 0.347367C8.73853 0.575907 8.26471 0.908518 7.88987 1.32085C7.51503 1.73319 7.24866 2.21481 7.11026 2.73047C6.97187 3.24613 6.96494 3.78277 7.09 4.30111L9.05375 12.4444L2 12.4444C1.46957 12.4444 0.960859 12.6317 0.585786 12.9651C0.210714 13.2985 1.77636e-15 13.7507 0 14.2222L0 18.6667C8.88178e-16 19.1382 0.210714 19.5903 0.585786 19.9237C0.960859 20.2571 1.46957 20.4444 2 20.4444L22 20.4444C22.5304 20.4444 23.0391 20.2571 23.4142 19.9237C23.7893 19.5903 24 19.1382 24 18.6667L24 14.2222C24 13.7507 23.7893 13.2985 23.4142 12.9651C23.0391 12.6317 22.5304 12.4444 22 12.4444Z",
                          fill: "rgba(0,0,0,1)",
                          fillRule: "nonzero",
                        },
                      ]}
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="0%"
                      bottom="0%"
                      left="0%"
                      right="0%"
                      {...getOverrideProps(overrides, "Vector36482695")}
                    ></Icon>
                  </View>
                </View>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="スタンプ・フレーム管理"
                  {...getOverrideProps(
                    overrides,
                    "\u30B9\u30BF\u30F3\u30D7\u30FB\u30D5\u30EC\u30FC\u30E0\u7BA1\u7406"
                  )}
                ></Text>
              </Flex>
              <Flex
                className="SideBar__item"
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={orders}
                {...getOverrideProps(overrides, "OrderLink")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon29767108")}
                >
                  <img width={24} src={orderIcon}></img>
                </View>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="購入履歴"
                  {...getOverrideProps(
                    overrides,
                    "\u8CFC\u5165\u5C65\u6B74\u7BA1\u7406"
                  )}
                ></Text>
              </Flex> 
              <Flex
                className="SideBar__item"
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={secrets}
                {...getOverrideProps(overrides, "SecretLink")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon29767108")}
                >
                  <img width={24} src={secretIcon}></img>
                </View>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="シークレットコード管理"
                  {...getOverrideProps(
                    overrides,
                    "\u8CFC\u5165\u5C65\u6B74\u7BA1\u7406"
                  )}
                ></Text>
              </Flex> 
              <Flex
                className="SideBar__item"
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                onClick={generateItems}
                {...getOverrideProps(overrides, "GenerateItemLink")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon29767108")}
                >
                  <img width={24} src={generateIcon}></img>
                </View>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="合成用素材管理"
                  {...getOverrideProps(
                    overrides,
                    "\u8CFC\u5165\u5C65\u6B74\u7BA1\u7406"
                  )}
                ></Text>
              </Flex> 
            </Flex>
          </Flex>

          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            onClick={logout}
            {...getOverrideProps(overrides, "Frame 415")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 413")}
            >
              <Flex
                className="SideBar__item"
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 323")}
              >
                <View
                  width="24px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Icon36482704")}
                >
                  <View
                    width="24px"
                    height="24px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    overflow="hidden"
                    position="absolute"
                    top="0px"
                    left="0px"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "material-symbols:logout")}
                  >
                    <Icon
                      width="18px"
                      height="18px"
                      viewBox={{ minX: 0, minY: 0, width: 18, height: 18 }}
                      paths={[
                        {
                          d: "M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16L0 2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0L9 0L9 2L2 2L2 16L9 16L9 18L2 18ZM13 14L11.625 12.55L14.175 10L6 10L6 8L14.175 8L11.625 5.45L13 4L18 9L13 14Z",
                          fill: "rgba(0,0,0,1)",
                          fillRule: "nonzero",
                        },
                      ]}
                      display="block"
                      gap="unset"
                      alignItems="unset"
                      justifyContent="unset"
                      position="absolute"
                      top="12.5%"
                      bottom="12.5%"
                      left="12.5%"
                      right="12.5%"
                      {...getOverrideProps(overrides, "Vector36482716")}
                    ></Icon>
                  </View>
                </View>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.01px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="ログアウト"
                  {...getOverrideProps(
                    overrides,
                    "\u30ED\u30B0\u30A2\u30A6\u30C8"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
