import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';


import '@aws-amplify/ui-react/styles.css';
import AdminLayout from './AdminLayout';


const Dashboard = lazy(() => import('./Dashboard'));
const NotFound = lazy(() => import('./NotFound'));

const StampList = lazy(() => import('./StampList'));
const StampCreate = lazy(() => import('./StampCreate'));
const StampEdit = lazy(() => import('./StampEdit'));

const UserList = lazy(() => import('./UserList'));
const UserCreate = lazy(() => import('./UserCreate'));
const UserEdit = lazy(() => import('./UserEdit'));

const IPList = lazy(() => import('./IPList'));
const IPCreate = lazy(() => import('./IPCreate'));
const IPEdit = lazy(() => import('./IPEdit'));

const OrderList = lazy(() => import('./OrderList'));
const OrderEdit = lazy(() => import('./OrderEdit'));

const SecretList = lazy(() => import('./SecretList'));
const SecretCreate = lazy(() => import('./SecretCreate'));
const SecretEdit = lazy(() => import('./SecretEdit'));

const GenerateItemList = lazy(() => import('./GenerateItemList'));
const GenerateItemCreate = lazy(() => import('./GenerateItemCreate'));
const GenerateItemEdit = lazy(() => import('./GenerateItemEdit'));

const AdminRouting = () => (
  <Suspense fallback={<div />}>
    <Routes>
      <Route element={<AdminLayout />}>
        <Route path="" Component={Dashboard} />

        <Route path="users" Component={UserList} />
        <Route path="users/create" Component={UserCreate} />
        <Route path="users/:id" Component={UserEdit} />

        <Route path="stamps" Component={StampList} />
        <Route path="stamps/create" Component={StampCreate} />
        <Route path="stamps/:id" Component={StampEdit} />

        <Route path="ips" Component={IPList} />
        <Route path="ips/create" Component={IPCreate} />
        <Route path="ips/:id" Component={IPEdit} />

        <Route path="orders" Component={OrderList} />
        <Route path="orders/:id" Component={OrderEdit} />

        <Route path="secrets" Component={SecretList} />
        <Route path="secrets/create" Component={SecretCreate} />
        <Route path="secrets/:id" Component={SecretEdit} />

        <Route path="generate-items" Component={GenerateItemList} />
        <Route path="generate-items/create" Component={GenerateItemCreate} />
        <Route path="generate-items/:id" Component={GenerateItemEdit} />
      </Route>
      <Route path="*" Component={NotFound} />
    </Routes>
  </Suspense>
);

export default AdminRouting;
