import './AdminLayout.scss';
import { CognitoUser } from '@aws-amplify/auth';
import { Flex, ThemeProvider, translations, Alert, Card, Authenticator } from '@aws-amplify/ui-react';
import { I18n, Auth, API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';

import { Users } from '../API';
import { getUsers } from '../graphql/queries';
import logo from '../img/components/start/oshidori_logo.png';
import { studioTheme } from '../ui-components';

import { Footer, Header, SigninFooter } from './Login';
import NavBar from './NavBar';
import SideBar from './SideBar';
import getVocaburalies from './Vocabularies';

// 画像
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('ja',getVocaburalies());
I18n.setLanguage('ja');

type ContextType = {
  setMessage: React.Dispatch<
    React.SetStateAction<{
      errors: string[];
      success: string;
    }>
  >;
};

const AdminLayout = () => {
  const [message, setMessage] = useState({ errors: [], success: '' });
  const navigate = useNavigate();

  // ログイン後、ログインユーザーに管理者権限がついているか確認をする
  // ログイン状態でアクセスした場合、サインインメソッドを通らないため再チェック
  const checkUserGroup = async (cognitoUser: any) => {
    let adminflg = false;
    if (cognitoUser.signInUserSession?.idToken?.payload['cognito:groups']) {
      const groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
      if (groups) {
        for (let i = 0; i < groups.length; i += 1) {
          if (groups[i] === 'admin') {
            adminflg = true;
          }
        }
      }
      if (!adminflg) {
        try {
          const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
          currentUser.signOut();
        } catch (e) {
          navigate('/');
        } finally {
          navigate('/');
        }
      }
    } else {
      // DynamoDBから権限を取得する
      try {
        const graphResult: any = await API.graphql(
          graphqlOperation(getUsers, {
            id: cognitoUser.attributes.sub,
          })
        );
        // DynamoDBに情報がある場合、isAdminがついているかチェックする
        if (graphResult.data && graphResult.data.getUsers) {
          const user: Users = graphResult.data.getUsers as Users;
          if (user.isAdmin) {
            adminflg = true;
          }
        }
      } catch (e) {
        navigate('/');
      }
      if (!adminflg) {
        try {
          const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
          currentUser.signOut();
        } catch (e) {
          navigate('/');
        } finally {
          navigate('/');
        }
      }
    }
  };

  const services = {
    // サインインのメソッドをオーバーライド
    async handleSignIn(formData: Record<string, string>) {
      const { username, password } = formData;
      return Auth.signIn({
        username,
        password,
      }).then(async (cognitoUser: CognitoUser) => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // 新しいパスワードの設定が必要な場合はサインインを成功させる
          return cognitoUser;
        }
        const id = cognitoUser.getUsername();
        // DynamoDBから権限を取得する
        const graphResult: any = await API.graphql(
          graphqlOperation(getUsers, {
            id,
          })
        );

        // DynamoDBに情報がある場合、isAdminがついているかチェックする
        if (graphResult.data && graphResult.data.getUsers) {
          const user: Users = graphResult.data.getUsers as Users;
          if (user.isAdmin) {
            return cognitoUser;
          }
          // サインアウトし、ログインを失敗させる
          cognitoUser.signOut();
          return Auth.signIn({ username: 'username', password: 'password' });
        }
        // サインアウトし、ログインを失敗させる
        cognitoUser.signOut();
        return Auth.signIn({ username: 'username', password: 'password' });
      });
    },
  };

  // ログイン画面のカスタマイズ
  const components = {
    Header,
    Footer,
    SignIn: {
      Footer: SigninFooter,
    },
  };

  return (
    <Authenticator components={components} hideSignUp services={services} socialProviders={[]}>
      {({ signOut, user }) => (
        // eslint-disable-next-line no-sequences
        checkUserGroup(user),
        (
          <ThemeProvider theme={studioTheme}>
            <Flex direction="column">
              <NavBar
                overrides={{ 'oshidori_logo 1': { src: logo } }}
                admins={() => navigate(`/${process.env.REACT_APP_ADMINPAGE}`)}
                stamps={() => navigate('stamps')}
                orders={() => navigate('orders')}
              />
              <Flex direction="row">
                <SideBar
                  ips={() => navigate('ips')}
                  users={() => navigate('users')}
                  stamps={() => navigate('stamps')}
                  orders={() => navigate('orders')}
                  secrets={() => navigate('secrets')}
                  generateItems={() => navigate('generate-items')}
                  logout={signOut}
                />
                <Flex direction="column" marginBottom={50}>
                  <Card className='alert-list'>
                    {message.success && (
                      <Alert isDismissible variation="success" marginBottom={20} marginTop={20}>
                        {message.success}
                      </Alert>
                    )}
                    {message.errors &&
                      message.errors.length > 0 &&
                      message.errors.map((e) => (
                        <Alert isDismissible key={e} variation="error" marginBottom={20} marginTop={20}>
                          {e}
                        </Alert>
                      ))}
                  </Card>
                  <Outlet context={{ setMessage }} />
                </Flex>
              </Flex>
            </Flex>
          </ThemeProvider>
        )
      )}
    </Authenticator>
  );
};

export default AdminLayout;

// メッセージをセットする
export function useSetMessage() {
  return useOutletContext<ContextType>();
}
