import './CameraFailure.scss';
import { Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

type CameraFailureProps = {
  isOpen: boolean;
};

const CameraFailure = (props: CameraFailureProps) => {
  const { isOpen} = props;

  const refreshScreen = () => {
    window.location.reload();
  }

  return (
    <Modal
      className='CameraFailure'
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          backgroundColor: '#fcefaa',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2,
        },
        content: {
          margin: '20',
          backgroundColor: 'white',
          border: 'none',
          position: 'fixed',
          zIndex: 2,
          textAlign: 'left',
        },
      }}
    >
      <div className='CameraFailure__message'>
        <div>
          カメラの起動が拒否されました。<br/>
          画面を更新してカメラの使用を『許可』してください。<br/><br/>
          ※ 許可の画面が出ない場合や黒い画面が表示される場合は<a  rel="noopener noreferrer" href="/help" target="_blank">ヘルプページ</a>をご確認ください。
        </div>
        <div className="CameraFailure__buttons">
          <Button marginTop={20} width='80%' variation="primary" onClick={refreshScreen}>
            更新する
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default CameraFailure;
