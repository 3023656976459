/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrders = /* GraphQL */ `
  query GetOrders($id: ID!) {
    getOrders(id: $id) {
      id
      usersID
      customerID
      ipid
      name
      thumbnail
      productID
      amount
      orderStatus
      createdAt
      updatedAt
      settionID
      groups
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersID
        customerID
        ipid
        name
        thumbnail
        productID
        amount
        orderStatus
        createdAt
        updatedAt
        settionID
        groups
      }
      nextToken
    }
  }
`;
export const ordersByUsersID = /* GraphQL */ `
  query OrdersByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersID
        customerID
        ipid
        name
        thumbnail
        productID
        amount
        orderStatus
        createdAt
        updatedAt
        settionID
        groups
      }
      nextToken
    }
  }
`;
export const ordersByIpidAndOrderStatus = /* GraphQL */ `
  query OrdersByIpidAndOrderStatus(
    $ipid: String!
    $orderStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByIpidAndOrderStatus(
      ipid: $ipid
      orderStatus: $orderStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersID
        customerID
        ipid
        name
        thumbnail
        productID
        amount
        orderStatus
        createdAt
        updatedAt
        settionID
        groups
      }
      nextToken
    }
  }
`;
export const getIPInfo = /* GraphQL */ `
  query GetIPInfo($id: ID!) {
    getIPInfo(id: $id) {
      id
      name
      ipid
      company
      contract
      roy
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const listIPInfos = /* GraphQL */ `
  query ListIPInfos(
    $filter: ModelIPInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIPInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ipid
        company
        contract
        roy
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const iPInfosByIpid = /* GraphQL */ `
  query IPInfosByIpid(
    $ipid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelIPInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iPInfosByIpid(
      ipid: $ipid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ipid
        company
        contract
        roy
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      name
      email
      isAdmin
      ipid
      createdAt
      updatedAt
      customerID
      Orders (filter: {orderStatus: {eq: "succeeded"}}) {
        items {
          id
          productID
          name
          thumbnail
          updatedAt
          amount
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        isAdmin
        ipid
        createdAt
        updatedAt
        customerID
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        isAdmin
        ipid
        createdAt
        updatedAt
        customerID
      }
      nextToken
    }
  }
`;
export const getItems = /* GraphQL */ `
  query GetItems($id: ID!) {
    getItems(id: $id) {
      id
      ipid
      type
      name
      main
      thumbnail
      top
      bottom
      faceFlg
      premierFlg
      geoFlg
      size
      longitude
      latitude
      createdAt
      updatedAt
      dataType
      startDate
      endDate
      price
      productID
      groups
      jan
      getFlg
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      nextToken
    }
  }
`;
export const itemsByIpid = /* GraphQL */ `
  query ItemsByIpid(
    $ipid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByIpid(
      ipid: $ipid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      nextToken
    }
  }
`;
export const getLogs = /* GraphQL */ `
  query GetLogs($id: ID!) {
    getLogs(id: $id) {
      id
      ipid
      page
      func
      message
      memo
      createdAt
      updatedAt
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipid
        page
        func
        message
        memo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMyItems = /* GraphQL */ `
  query GetMyItems($id: ID!) {
    getMyItems(id: $id) {
      id
      ipid
      usersID
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const listMyItems = /* GraphQL */ `
  query ListMyItems(
    $filter: ModelMyItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipid
        usersID
        itemsID
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const myItemsByUsersID = /* GraphQL */ `
  query MyItemsByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMyItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    myItemsByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipid
        usersID
        itemsID
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItemSecrets = /* GraphQL */ `
  query GetItemSecrets($id: ID!) {
    getItemSecrets(id: $id) {
      id
      ipid
      code
      itemsID
      Item {
        id
        ipid
        type
        name
        main
        thumbnail
        top
        bottom
        faceFlg
        premierFlg
        geoFlg
        size
        longitude
        latitude
        createdAt
        updatedAt
        dataType
        startDate
        endDate
        price
        productID
        groups
        jan
        getFlg
      }
      groups
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const listItemSecrets = /* GraphQL */ `
  query ListItemSecrets(
    $filter: ModelItemSecretsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemSecrets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipid
        code
        itemsID
        groups
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemSecretsByIpid = /* GraphQL */ `
  query ItemSecretsByIpid(
    $ipid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelItemSecretsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemSecretsByIpid(
      ipid: $ipid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipid
        code
        itemsID
        Item {
          id
          name
        }
        groups
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemSecretsByCode = /* GraphQL */ `
  query ItemSecretsByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelItemSecretsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemSecretsByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipid
        code
        itemsID
        groups
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGenerateItems = /* GraphQL */ `
  query GetGenerateItems($id: ID!) {
    getGenerateItems(id: $id) {
      id
      ipid
      name
      back
      front
      geoFlg
      longitude
      latitude
      startDate
      endDate
      groups
      createdAt
      updatedAt
    }
  }
`;
export const listGenerateItems = /* GraphQL */ `
  query ListGenerateItems(
    $filter: ModelGenerateItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenerateItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ipid
        name
        back
        front
        geoFlg
        longitude
        latitude
        startDate
        endDate
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const generateItemsByIpid = /* GraphQL */ `
  query GenerateItemsByIpid(
    $ipid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGenerateItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    generateItemsByIpid(
      ipid: $ipid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ipid
        name
        back
        front
        geoFlg
        longitude
        latitude
        startDate
        endDate
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
