// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DataType = {
  "ITEM": "ITEM",
  "IPINFO": "IPINFO",
  "USERINFO": "USERINFO",
  "ORDER": "ORDER",
  "ALBUM": "ALBUM",
  "ADMININFO": "ADMININFO"
};

const ItemType = {
  "STAMP": "STAMP",
  "FRAME": "FRAME"
};

const { Orders, IPInfo, Users, Items, Logs, ItemSecrets, MyItems } = initSchema(schema);

export {
  Orders,
  IPInfo,
  Users,
  Items,
  Logs,
  ItemSecrets,
  MyItems,
  DataType,
  ItemType
};