import './GPSFailure.scss';
import { Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

type GPSFailureProps = {
  isOpen: boolean;
};

const GPSFailure = (props: GPSFailureProps) => {
  const {isOpen} = props;

  const refreshScreen = () => {
    window.location.reload();
  }

  return (
    <Modal
      className='GPSFailure'
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          backgroundColor: '#fcefaa',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2,
        },
        content: {
          margin: '20',
          backgroundColor: 'white',
          border: 'none',
          position: 'fixed',
          zIndex: 2,
          textAlign: 'left',
        },
      }}
    >
      <div className='GPSFailure__message'>
        <div>
          GPSの取得に失敗しました。<br/>
          画面を更新して再表示するか、<a  rel="noopener noreferrer" href="/help" target="_blank">ヘルプページ</a>にあるGPSの設定をお確かめの上、再度アクセスしてください。<br/>
        </div>
        <div className="GPSFailure__buttons">
          <Button marginTop={20} width='80%' variation="primary" onClick={refreshScreen}>
            更新する
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default GPSFailure;
