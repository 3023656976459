import './MobileOnly.scss';
import Notice from '../img/components/mobile-only/sp-only-toritext.png';

const MobileOnly = () => (
  <div className="MobileOnly">
    <div className="MobileOnly__content">
      <img src={Notice} alt="スマホからアクセスをしてください。PCからはご利用できません。" />
    </div>
  </div>
);

export default MobileOnly;
