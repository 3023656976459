import './Preview.scss';
import { Buffer } from 'buffer';

import { Alert } from '@aws-amplify/ui-react';
import { InvokeCommand, LambdaClient} from "@aws-sdk/client-lambda";
import { PutObjectCommand, S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import * as PIXI from 'pixi.js';
import { useRef, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

import { GenerateItems } from '../API';
import close from '../img/components/preview/close.png';
import line from '../img/components/preview/line_logo.png';
import share from '../img/components/preview/share_logo.png';
import twitter from '../img/components/preview/twitter_logo.png';

import ConfirmGenerate from './ConfirmGenerate';
import ConfirmReset from './ConfirmReset';
import GenerateLoading from './GenerateLoading';
import OriginalButton from './OriginalButton';
import { createSpriteFrom } from './utils/sprite';


type PreviewProps = {
  isOpen: boolean;
  hidePreview: () => void;
  setGeneratedState: (state: boolean) => void;
  generated: boolean;
  previewSrc: string;
  generateItems: GenerateItems[];
  addGeneratedImageToCanvas: (src: string) => void;
  deleteStamps: () => void;
};

const Preview = (props: PreviewProps) => {
  const { isOpen, hidePreview, setGeneratedState, generated, generateItems, previewSrc, addGeneratedImageToCanvas, deleteStamps } = props;
  const [src, setSrc] = useState(previewSrc)
  const [rembg, setRembg] = useState('')
  const [showConfirmGenerate, setShowConfirmGenerate] = useState(false)
  const [showConfirmReset, setShowConfirmReset] = useState(false)
  const [selectedItem, setSelectedItem] = useState<GenerateItems|null>(null)
  const [confirmed, setConfirmed] = useState(false)
  // Image Element
  const refPreviewImage = useRef<HTMLImageElement>(null);
  // 検索パラメータ
  const { ipParam } = useParams();
  const [percentage, setPercentage] = useState(0);
  // 準備中表示
  const [prepare, setPrepare] = useState(false);
  // 連番管理
  const [count, setCount] = useState(0);

  const [modify, setModify] = useState(false);

  const [app, setApp] = useState<PIXI.Application<PIXI.ICanvas>|null>(null);
  const [rembgSprite, setRembgSprite] = useState<PIXI.Sprite | null>(null);

  const clientShare = () => {
    if (navigator.share) {
      navigator.share({
        title: '推しドリ',
        text: '推しドリで写真を',
        url: process.env.PUBLIC_URL,
      });
    }
  };

  /**
   * 画像のURLを使いHTMLImageElementを作成する
   * @param imageSrc 画像のURL
   * @returns 
   */
  const loadImage = (imageSrc: string) => {
    const promise = new Promise((resolve, reject) => {
      const img = new Image();
      // CORSのための設定
      img.crossOrigin = 'Anonymous';
      img.alt = '合成用画像';
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = imageSrc;
    });
    return promise;
  }

  /**
   * GenerateItem.srcからHTMLImageElementを作成する
   * @param s3Client s3Client
   * @param usedSrc 署名付きURL（使用済みでブラウザにキャッシュされたもの）
   * @returns 
   */
  const getImageElementFromGenerateItem = async (s3Client: S3Client, usedSrc: string) => {
    // s3の署名付きURLをnew Image()のsrcに入れた時CORSエラーが発生する。（Chromeブラウザの仕様）
    // 具体的には、S3の画像URLを使用している場合、かつ、Chromeでブラウザのcacheが有効になっている場合、2度目のアクセス時にAccess-Control-Allow-OriginHeaderの付いていないキャッシュしたレスポンスをChromeが返すためCORSエラーが発生
    // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    // https://qiita.com/mikan3rd/items/51da67a1402e27895a23
    // CORSエラー回避のためブラウザs3から署名付きURLを新規で取得しcacheの値が使われないようにする
    const getCommand = new GetObjectCommand({
      Bucket: process.env.REACT_APP_IMG_BUCKET,
      Key: `public/${(new URL(usedSrc)).pathname.replace("/public/", "")}`,
      
    });
    const s3Url = await getSignedUrl(s3Client, getCommand, {expiresIn: 3600})
    // 新しく取得した署名付きURLからHTMLImageElementを作成
    return await loadImage(s3Url) as HTMLImageElement;
  }

  const moveX = (distance: number) => {
    if (rembgSprite) {
      rembgSprite.x += distance;
    }
  }

  const moveY = (distance: number) => {
    if (rembgSprite) {
      rembgSprite.y += distance;
    }
  }

  const sizeChange = (size: number) => {
    if (rembgSprite) {
      rembgSprite.height *= (size + rembgSprite.width) / rembgSprite.width;
      rembgSprite.width += size;
    }
  }

  const createSprite = (img: HTMLImageElement, pixiApp: PIXI.Application, zIndex: number, isBack: boolean): PIXI.Sprite => {
    const base  = new PIXI.BaseTexture(img);
    const texture = new PIXI.Texture(base);

    const widthRate = pixiApp.view.width / texture.width;
    const heightRate = pixiApp.view.height / texture.height;

    const spriteWidth = widthRate <= heightRate ? texture.width * heightRate : texture.width * widthRate;
    const spriteHeight = widthRate <= heightRate ? texture.height * heightRate : texture.height * widthRate;

    // 比率が低い方を大きくする
    const sprite = createSpriteFrom(count,
      texture,
      isBack ? spriteWidth : pixiApp.view.width,
      isBack ? spriteHeight : 'scale',
      isBack ? (spriteWidth - pixiApp.view.width) / 2 * -1 : 0,
      isBack ? pixiApp.view.height : 0,
      isBack ? 0.0 : 0.0,
      isBack ? 1.0 : 0.0
    )
    setCount(count + 1);
    sprite.zIndex = zIndex;
    pixiApp.stage.addChild(sprite);
    return sprite;
  }

  /**
   * 合成の作成
   * @param item 合成対象の画像
   */
  const generate = async (item: GenerateItems) => {
    // 写真撮影した画像から情報を取得
    const fileData = previewSrc.replace(/^data:\w+\/\w+;base64,/, '')
    const fileExtension = previewSrc.toString().slice(previewSrc.indexOf('/') + 1, previewSrc.indexOf(';'))
    const contentType = previewSrc.toString().slice(previewSrc.indexOf(':') + 1, previewSrc.indexOf(';'))

    if (refPreviewImage.current) {
      refPreviewImage.current.hidden = false;
    }

    // 拡張子、コンテントタイプが取得できた場合に合成を行う
    if (fileExtension && contentType) {
      setPrepare(true)
      setPercentage(0)
      try {
        // 登録するファイル名を決定する
        const d = new Date();
        const now = `${d.getFullYear()}${(d.getMonth()+1).toString().padStart(2, '0')}${d.getDate().toString().padStart(2, '0')}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`.replace(/\n|\r/g, '');
        const randValues = window.crypto.getRandomValues(new Uint32Array(1))
        const randValue = randValues[0];
        // 登録するファイル名
        const fileName = `${now}-${randValue}.${fileExtension}`;
        let url = rembg || '';

        // AWSClientの設定
        const clientConfig = {
          region: process.env.REACT_APP_REGION, 
          credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
          }
        }

        const s3Client = new S3Client(clientConfig);

        let tmpApp = app;

        if (tmpApp === null) {
          // 読み込み中の表示

          setPercentage(5);
          // s3にアップロードするコマンド
          const s3PutCommand = new PutObjectCommand({
            Bucket: process.env.REACT_APP_TMP_BUCKET,
            Key: fileName,
            Body: Buffer.from(fileData, 'base64'),
            ContentType: contentType
          });

          // s3アップロードの実行
          await s3Client.send(s3PutCommand);
          const payload = JSON.stringify({ fileName })

          setPercentage(8);

          const clearID = setInterval(() => {
            setPercentage((p: number) => {
              if (p < 90) {
                return p + 1;
              }
              return 90;
            });
          }, 80);

          // 背景削除の実行
          const lambdaClient = new LambdaClient(clientConfig)
          const invokeCommand = new InvokeCommand({
            FunctionName: process.env.REACT_APP_REMBG_FUNCTION,
            Payload: Buffer.from(payload)
          })
          await lambdaClient.send(invokeCommand)

          clearInterval(clearID);
          setPercentage(92);

          // 合成後のs3アイテムを取得
          const getCommand = new GetObjectCommand({
            Bucket: process.env.REACT_APP_TMP_BUCKET,
            Key: `processed/${fileName}`,
          });

          // 署名付きURLの取得
          url = await getSignedUrl(s3Client, getCommand, {expiresIn: 3600})
          setRembg(url);

          if (refPreviewImage.current) {
            const pixiApp = new PIXI.Application({
              width: (refPreviewImage.current?.width || 1) * 3,
              height: (refPreviewImage.current?.height || 1) * 3,
              backgroundColor: 0x1099bb,  // 背景色 16進 0xRRGGBB
            })
            pixiApp.stage.sortableChildren = true;
            const el = document.getElementById('generate-preview-image')
            if (el) {
              el.appendChild(pixiApp.view as HTMLCanvasElement);
            }
            tmpApp = pixiApp;
            setApp(pixiApp);
          }
        }

        setPercentage(90);

        // PIXI
        if (tmpApp) {

          // 全ての要素を削除する
          while(tmpApp.stage.children[0]) { 
            tmpApp.stage.removeChild(tmpApp.stage.children[0]);
          }
          setPercentage(91);

          if (item.back) {
            const backImage = await getImageElementFromGenerateItem(s3Client, item.back);
            createSprite(backImage, tmpApp, 1, true)
          }
          setPercentage(93);

          if (rembgSprite === null) {
            // 背景透過した人物画像をキャンバスに描画する
            const rembgImage = await loadImage(url) as HTMLImageElement;
            setRembgSprite(createSprite(rembgImage, tmpApp, 2, false));
          } else {
            tmpApp.stage.addChild(rembgSprite);
            setCount(count + 1);
          }
          setPercentage(95);

          if (item.front) {
            const frontImage = await getImageElementFromGenerateItem(s3Client, item.front);
            createSprite(frontImage, tmpApp, 3, true);
          }
          setPercentage(98);
          setModify(true);
          const el = document.getElementById('generate-preview-image')
          if (el) {
            el.style.display = 'block';
          }
          if (refPreviewImage.current) {
            refPreviewImage.current.hidden = true;
          }
          setApp(tmpApp);
          setPercentage(100);
        }
      } catch (err) {
        console.error(err);
        window.alert('画像の合成に失敗しました。');
      } finally {
        setPrepare(false)
      }
    } else {
      window.alert('画像の合成に失敗しました。');
    }
  }

  const confirmGenerate = (item: GenerateItems) => {
    if (confirmed) {
      generate(item)
    } else {
      setShowConfirmGenerate(true)
      setSelectedItem(item)
    }
  }

  /**
   * 作成した合成画像をCanvasの背景にフレームとして設定する
   */
  const setGeneratedImage = () => {
    setGeneratedState(true)
    addGeneratedImageToCanvas(src)
    hidePreview();
  }

  const hideConfirmGenerate = (confirm: boolean) => {
    if (confirm && selectedItem) {
      generate(selectedItem) 
      setConfirmed(true)
    }
    setShowConfirmGenerate(false);
  }

  const hideConfirmReset = (confirm: boolean) => {
    if (confirm) {
      deleteStamps();
      hidePreview();
    }
    setShowConfirmReset(false);
  }

  const modifyComplete = () => {
    // Canvaのデータをプレビューに貼る
    const renderer = PIXI.autoDetectRenderer({
      width: app?.view.width,
      height: app?.view.height,
      autoDensity: true,
      resolution: window.devicePixelRatio,
    });
    if (app!== null) {
      renderer.render(app.stage);
      if (renderer.view.toDataURL) {
        const imageSrc = renderer.view.toDataURL('image/jpeg', 1.0);
        if (refPreviewImage.current) {
          refPreviewImage.current.hidden = false;
          const el = document.getElementById('generate-preview-image')
          if (el) {
            el.style.display = 'none';
          }
        }
        setSrc(imageSrc);
      }
    }
    setModify(false);
  }

  return (
    <div>
    <Modal
      isOpen={isOpen}
      onRequestClose={hidePreview}
      className="Preview__overlay"
      style={{
        overlay: {
          inset: '10px',
          backgroundColor: 'transparent',
          overflowX: 'hidden',
          overflowY: 'scroll',
          borderRadius: '5px',
        },
        content: {
          inset: '0',
          backgroundColor: '#fcefaa',
          border: 'none',
        },
      }}
    >
      <div className="Preview">
        <Alert variation="info" className='Preview__alert' isDismissible={false} hasIcon heading="">
          {!modify && <span>写真を長押しで保存！</span>}
          {modify && <span>カスタマイズしよう！</span>}
          
        </Alert>
        <div className="Preview__image">
          <button type="button" className="Preview__back" onClick={hidePreview}>
            <img src={close} alt="プレビューを閉じるボタン" />
          </button>
          <div className="Preview__image-block">
            <div id='preview-image'>
              <img ref={refPreviewImage} className="Preview__image-data" src={src} alt="プレビュー" />
              <div id='generate-preview-image' />
            </div>
            {modify && (
              <div>
                <div className='arrows'>
                  <div className='arrows__block'>
                    <div className='arrows__title'>
                      位置を修正する
                    </div>
                    <div className='arrows__top'>
                      <button type="button" className='arrows__button arrows_button-top' onClick={() => { moveY(-20) }}> </button>
                    </div>
                    <div className='arrows__bottom'>
                      <button type="button" className='arrows__button arrows_button-left' onClick={() => {moveX(-20)}} > </button>
                      <button type="button" className='arrows__button arrows_button-down' onClick={() => {moveY(+20)}} > </button>
                      <button type="button" className='arrows__button arrows_button-right' onClick={() => {moveX(+20)}}> </button>
                    </div>
                  </div>
                  <div className='arrows__block'>
                    <div className='arrows__title'>
                      サイズを修正する
                    </div>
                    <div className='arrows__bottom'>
                      <button type="button" className='arrows__button arrows_button-minus' onClick={() => {sizeChange(-20)}} > </button>
                      <button type="button" className='arrows__button arrows_button-plus' onClick={() => {sizeChange(+20)}} > </button>
                    </div>
                  </div>
                </div>
                <OriginalButton target={null} clickEvent={modifyComplete} text="確定する" />
              </div>
            )}
            <OriginalButton target={null} clickEvent={setGeneratedImage} text="さらにスタンプを貼る！" />
            {rembg && !modify && (
              <OriginalButton target={null} clickEvent={setGeneratedImage} text="さらにスタンプを貼る！" />
            )}
            {generated && (
                <OriginalButton clickEvent={setShowConfirmReset} target text="全てリセットする" />
            )}
          </div>
          <div className="GenerateButtons">
            {!generated && !modify && generateItems.map((item, index) => (
              <div key={item.id} className='GenerateButtons__block'>
                <OriginalButton clickEvent={confirmGenerate} target={item} text={`↓ 合成${index + 1} ↓`} />
                <img src={item.front ? item.front : item.back || ''} style={{backgroundImage: `url(${item.back})`}} alt='合成sample' className='GenerateButtons__sample' />
              </div>
            ))}
          </div>
        </div>
        {!modify && (
        <div className="PreviewButtons">
          <p className="Preview__share-notice">\ シェアする /</p>
          <div className="PreviewButtons__shares">
            <div className="PreviewButtons__share">
              <div className="PreviewButtons__share-twitter">
                <a
                  href={`http://twitter.com/share?url=${process.env.REACT_APP_URL}/contents/${ipParam}&text=みてみて！素敵な写真が撮れたよ！&hashtags=推しドリ`}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="click"
                  data-amplify-analytics-attrs={`ipid:${ipParam},page:preview,type:share-twitter`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={twitter} alt="twitterシェアボタン" />
                </a>
              </div>
            </div>
            <div className="PreviewButtons__share">
              <div className="PreviewButtons__share-share">
                <button type="button" onClick={clientShare}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="click"
                  data-amplify-analytics-attrs={`ipid:${ipParam},page:preview,type:share`}
                >
                  <img src={share} alt="シェアボタン" />
                </button>
              </div>
            </div>
            <div className="PreviewButtons__share">
              <div className="PreviewButtons__share-line">
                <a
                  href={`https://social-plugins.line.me/lineit/share?url=${`${process.env.REACT_APP_URL}/contents/${ipParam}`}`}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="click"
                  data-amplify-analytics-attrs={`ipid:${ipParam},page:preview,type:share-line`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={line} alt="LINEシェアボタン" />
                </a>
              </div>
            </div>
          </div>
          <div className="PreviewButtons__back" />
        </div>
        )}
      </div>
    </Modal>
    {prepare && <GenerateLoading percentage={percentage} /> }
    {showConfirmGenerate && <ConfirmGenerate hideConfirmGenerate={hideConfirmGenerate} />}
    {showConfirmReset && <ConfirmReset hideConfirmReset={hideConfirmReset} />}
    </div>
  );
};
export default Preview;
