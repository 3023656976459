import { Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

import purchaceComplitedImage from '../img/components/controller/purchase-completed.png';

type PurchaseProps = {
  isOpen: boolean;
  hidePurchaseResult: () => void;
};

const PurchaseResult = (props: PurchaseProps) => {
  const { isOpen, hidePurchaseResult } = props;
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '20px',
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          inset: '0',
          backgroundColor: 'white',
          border: 'none',
          position: 'relative',
          textAlign: 'center',
        },
      }}
    >
      <div>購入しました！</div>
      <img width="100%" src={purchaceComplitedImage} alt="購入が完了しました！" />
      <Button variation="primary" onClick={hidePurchaseResult}>
        閉じる
      </Button>
    </Modal>
  );
};
export default PurchaseResult;
