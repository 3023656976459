import './TextInput.scss';

import { TextField, Card, Button, Alert, RadioGroupField, Radio } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { ChromePicker, ColorResult, Color, RGBColor } from 'react-color';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

// 画像
import close from '../img/components/preview/close.png';

type TextInputProps = {
  hideTextInput: () => void;
  setTextInput: (text: string, color: string, fontname: string) => void;
};

const TextInput = (props: TextInputProps) => {
  const { hideTextInput, setTextInput } = props;
  // 検索パラメータ
  const [color, setColor] = useState('rgba(0,0,0,1)');
  const [text, setText] = useState('');
  const [fontname, setFontname] = useState('');
  const [rgbaColor, setRgbaColor] = useState<Color>();
  const { ipParam } = useParams();
  const [ipid, setIpid] = useState(ipParam);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async () => {
    setErrorMessage('')
    if (color && text && fontname) {
      setTextInput(text.substring(0, 20), color, fontname)
      hideTextInput();
    } else {
      setErrorMessage('テキストを入力して色を選択してください')
    }
  }

  const changeColor = (c: ColorResult, event: any) => {
    setColor(`rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`)
    setRgbaColor(c.rgb)
  }

  /**
   * @author JuthaDDA
   * @see [RegExp の複数行記述，RegExp 内での変数参照がしたいので，
   *     正規表現を結合する関数を作った - Qiita
   *     ](https://qiita.com/juthaDDA/items/f1093b968faa3d810c1c)
   * @param regExps - Babel (< 7.11.0) を使う場合は,
   *     名前付きキャプチー・グループを含むと正しく変換されない.
   *     `@babel/plugin-transform-named-capturing-groups-regex (< 7.10.4)` も未対応.
   */
  const concatRegExps = ( regExps:RegExp[], flags?:string ):RegExp => 
    RegExp( regExps.reduce( ( acc, cur ) => acc + cur.source, '' ),
        flags,
  );

  /**
  * @author juthaDDA
  * @param rgba - 'rgb[a](R, G, B[, A])' 形式.
  * @see ['rgb\[a\](R, G, B\[, A\])' を正規表現で処理して，
  *     各値をメンバーとしてもつオブジェクトを返す関数 - Qiita](
  *     https://qiita.com/juthaDDA/items/d81f45295095eb4563f4)
  */
  const rgbaStrToValues = ( rgba:string ):RGBColor|null => {
    /**
     * ` /[+-]?\d*\.?\d+/` は実数（整数部の 0 省略可）の正規表現.
     *
     * @see [数値とマッチする正規表現 - Qiita](
     *     https://qiita.com/BlueSilverCat/items/f35f9b03169d0f70818b)
     */
    const regExp = concatRegExps( [
        /^rgba?\( *([+-]?\d*\.?\d+) *, *([+-]?\d*\.?\d+) *, */, // rgb[a](R, G,
        /([+-]?\d*\.?\d+)(?: *, *([+-]?\d*\.?\d+) *)?\)$/, // B[, A])
    ] );

    const result = regExp.exec( rgba );
    if ( ! result ) { return null; }

    const { 1: r, 2: g, 3: b, 4: a} = result;
    if ( ! ( r && g && b ) ) { return null; }

    const { min, max } = Math;
    return {
        r: max( min( Number( r ), 255 ), 0 ),
        g: max( min( Number( g ), 255 ), 0 ),
        b: max( min( Number( b ), 255 ), 0 ),
        a: a ? max( min( Number( a ), 1 ), 0 ) : 1,
    };
  };

  useEffect(() => {
    if (color) {
      const colorResult = rgbaStrToValues(color)
      if (colorResult) {
        setRgbaColor(colorResult)
      }
    }
  }, [])

  return (
    <div>
    <Modal
      isOpen
      onRequestClose={hideTextInput}
      style={{
        overlay: {
          backgroundColor: 'transparent',
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '20px',
          overflow: 'scroll',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          inset: '0',
          backgroundColor:  'white',
          border: 'none',
          position: 'absolute',
        },
      }}
    >
      <div className="TextInput">
        <button type="button" className="TextInput__close" onClick={hideTextInput}>
          <img src={close} alt="テキスト入力画面を閉じるボタン" />
        </button>
        <h2 className='title'>
          テキストの入力
        </h2>
          
        <Card variation="elevated" borderRadius="5px">
          <TextField
            maxLength={20}
            value={text}
            isRequired
            color={color}
            backgroundColor="white"
            onChange={(e) => setText(e.target.value)}
            textAlign="left"
            name="text"
            label="テキスト"
            placeholder=""
            marginBottom={20}
          />
          <div className='amplify-label' style={{textAlign: 'left', display: 'flex', marginBottom: '10px'}}>
            <span>色の選択</span>
          </div>

          <ChromePicker
            color={rgbaColor}
            onChange={ (c, e) =>changeColor(c, e) }
          />
          <RadioGroupField 
            isRequired
            label="フォント"
            name="fontname"
            value={fontname}
            marginTop={20}
            marginBottom={20}
            onChange={(e) => setFontname(e.target.value)}>
            <Radio value="dnp-shuei-gothic-kin-std"> </Radio>
            <Radio value="ta-kaku-shadow"> </Radio>
            <Radio value="fot-udkakugoc80-pro"> </Radio>
            <Radio value="fot-chiaro-std"> </Radio>
            <Radio value="akabara-cinderella"> </Radio>
            <Radio value="kokuryu"> </Radio>
            <Radio value="ab-megadot9"> </Radio>
            <Radio value="ta-mincho-gf-01"> </Radio>
            <Radio value="zen-maru-gothic"> </Radio>
            <Radio value="vdl-linegrpop-shadow"> </Radio>
            <Radio value="kaisei-decol"> </Radio>
            <Radio value="azo-sans-uber"> </Radio>
          </RadioGroupField>
          {errorMessage && <Alert variation="error">{errorMessage}</Alert>}
          <div className='TextInput__buttons'>
            <Button variation="primary" onClick={() => onSubmit()}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="click"
              data-amplify-analytics-attrs={`ipid:${ipid},page:TextInput,type:submit`}
            >確定する</Button>
          </div>
        </Card>
      </div>
    </Modal>
    </div>
  );
};
export default TextInput;
