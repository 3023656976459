import './TextClipSetting.scss';

import { TextAreaField, Card, Button, Alert, RadioGroupField, Radio, StepperField, CheckboxField} from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { ChromePicker, ColorResult, Color, RGBColor } from 'react-color';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

// 画像
import close from '../img/components/preview/close.png';
import infoImg from '../img/components/text-clip-setting/info.png';

import TextClipInfo from './TextClipInfo';

type TextClipSettingProps = {
  hideTextClipSetting: () => void;
  clearTextClip: () => void;
  setTextClipSetting: (text: string, color: string, align: string, verticalAlign: string, size: number, fontname: string, closer: boolean) => void;
  defaultValue: ({text: string, color: string, align: string, verticalAlign: string, size: number, fontname: string, closer: boolean});
};

const TextClipSetting = (props: TextClipSettingProps) => {
  const { hideTextClipSetting, setTextClipSetting, defaultValue, clearTextClip} = props;
  // 検索パラメータ
  const [color, setColor] = useState(defaultValue.color);
  const [text, setText] = useState(defaultValue.text);
  const [align, setAlign] = useState(defaultValue.align);
  const [size, setSize] = useState(defaultValue.size);
  const [fontname, setFontname] = useState(defaultValue.fontname);
  const [closer, setCloser] = useState(defaultValue.closer);
  const [rgbaColor, setRgbaColor] = useState<Color>();
  const [verticalAlign, setVerticalAlign] = useState(defaultValue.verticalAlign);
  const { ipParam } = useParams();
  const [ipid, setIpid] = useState(ipParam);
  const [errorMessage, setErrorMessage] = useState('');

  const [showTextClipInfo, setShowTextClipInfo] = useState(false);

  const onSubmit = async () => {
    setErrorMessage('')
    if (color && text) {
      setTextClipSetting(text.substring(0, 70), color, align, verticalAlign, size, fontname, closer)
      hideTextClipSetting();
    } else {
      setErrorMessage('テキストを入力して色を選択してください')
    }
  }

  const onClear = () => {
    clearTextClip();
    hideTextClipSetting();
  }

  const changeColor = (c: ColorResult, event: any) => {
    setColor(`rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`)
    setRgbaColor(c.rgb)
  }

  const hideTextClipInfo = () => {
    setShowTextClipInfo(false);
  }

  /**
   * @author JuthaDDA
   * @see [RegExp の複数行記述，RegExp 内での変数参照がしたいので，
   *     正規表現を結合する関数を作った - Qiita
   *     ](https://qiita.com/juthaDDA/items/f1093b968faa3d810c1c)
   * @param regExps - Babel (< 7.11.0) を使う場合は,
   *     名前付きキャプチー・グループを含むと正しく変換されない.
   *     `@babel/plugin-transform-named-capturing-groups-regex (< 7.10.4)` も未対応.
   */
  const concatRegExps = ( regExps:RegExp[], flags?:string ):RegExp => 
    RegExp( regExps.reduce( ( acc, cur ) => acc + cur.source, '' ),
        flags,
  );

  /**
  * @author juthaDDA
  * @param rgba - 'rgb[a](R, G, B[, A])' 形式.
  * @see ['rgb\[a\](R, G, B\[, A\])' を正規表現で処理して，
  *     各値をメンバーとしてもつオブジェクトを返す関数 - Qiita](
  *     https://qiita.com/juthaDDA/items/d81f45295095eb4563f4)
  */
  const rgbaStrToValues = ( rgba:string ):RGBColor|null => {
    /**
     * ` /[+-]?\d*\.?\d+/` は実数（整数部の 0 省略可）の正規表現.
     *
     * @see [数値とマッチする正規表現 - Qiita](
     *     https://qiita.com/BlueSilverCat/items/f35f9b03169d0f70818b)
     */
    const regExp = concatRegExps( [
        /^rgba?\( *([+-]?\d*\.?\d+) *, *([+-]?\d*\.?\d+) *, */, // rgb[a](R, G,
        /([+-]?\d*\.?\d+)(?: *, *([+-]?\d*\.?\d+) *)?\)$/, // B[, A])
    ] );

    const result = regExp.exec( rgba );
    if ( ! result ) { return null; }

    const { 1: r, 2: g, 3: b, 4: a} = result;
    if ( ! ( r && g && b ) ) { return null; }

    const { min, max } = Math;
    return {
        r: max( min( Number( r ), 255 ), 0 ),
        g: max( min( Number( g ), 255 ), 0 ),
        b: max( min( Number( b ), 255 ), 0 ),
        a: a ? max( min( Number( a ), 1 ), 0 ) : 1,
    };
  };

  useEffect(() => {
    if (color) {
      const colorResult = rgbaStrToValues(color)
      if (colorResult) {
        setRgbaColor(colorResult)
      }
    }
  }, [])

  return (
    <div>
    {showTextClipInfo && <TextClipInfo hideTextClipInfo={hideTextClipInfo} />}
    <Modal
      isOpen
      onRequestClose={hideTextClipSetting}
      style={{
        overlay: {
          backgroundColor: 'transparent',
          position: 'absolute',
          top: 0,
          bottom: 0,
          inset: '20px',
          overflow: 'scroll',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          inset: '0',
          backgroundColor:  color || 'white',
          border: 'none',
          position: 'absolute',
        },
      }}
    >
      <div className="TextClipSetting">
        <button type="button" className="TextClipSetting__close" onClick={hideTextClipSetting}>
          <img src={close} alt="テキスト切り抜き画面を閉じるボタン" />
        </button>
        <h2 className='title'>
          テキスト切り抜き
        </h2>
        <div className='TextClipSetting__info'>
          <Button type='button' variation='link' onClick={() => {setShowTextClipInfo(true)}}><img src={infoImg} alt='テキスト切り抜きとは？' />テキスト切り抜きとは？</Button>
        </div>
          
        <Card variation="elevated" borderRadius="5px">
          <TextAreaField
            value={text}
            isRequired
            backgroundColor="white"
            onChange={(e) => setText(e.target.value)}
            textAlign="left"
            name="text"
            label="切り抜きテキスト"
            placeholder=""
            rows={4}
            marginBottom={20}
          />
          <div className='amplify-label' style={{textAlign: 'left', display: 'flex', marginBottom: '10px'}}>
            <span>色の選択</span>
          </div>

          <ChromePicker
            color={rgbaColor}
            onChange={ (c, e) =>changeColor(c, e) }
          />
          <RadioGroupField 
            isRequired
            label="文字の配置（縦）"
            name="vertical-align"
            value={verticalAlign}
            marginTop={20}
            marginBottom={20}
            onChange={(e) => setVerticalAlign(e.target.value)}>
            <Radio value="top"> </Radio>
            <Radio value="center"> </Radio>
            <Radio value="bottom"> </Radio>
          </RadioGroupField>
          <RadioGroupField 
            isRequired
            label="文字の配置（横）"
            name="text-align"
            value={align}
            marginBottom={20}
            onChange={(e) => setAlign(e.target.value)}>
            <Radio value="left"> </Radio>
            <Radio value="center"> </Radio>
            <Radio value="right"> </Radio>
          </RadioGroupField>

          <RadioGroupField 
            isRequired
            label="フォント"
            name="fontname"
            value={fontname}
            marginTop={20}
            marginBottom={20}
            onChange={(e) => setFontname(e.target.value)}>
            <Radio value="dnp-shuei-gothic-kin-std"> </Radio>
            <Radio value="ta-kaku-shadow"> </Radio>
            <Radio value="fot-udkakugoc80-pro"> </Radio>
            <Radio value="fot-chiaro-std"> </Radio>
            <Radio value="akabara-cinderella"> </Radio>
            <Radio value="kokuryu"> </Radio>
            <Radio value="ab-megadot9"> </Radio>
            <Radio value="ta-mincho-gf-01"> </Radio>
            <Radio value="zen-maru-gothic"> </Radio>
            <Radio value="vdl-linegrpop-shadow"> </Radio>
            <Radio value="kaisei-decol"> </Radio>
            <Radio value="azo-sans-uber"> </Radio>
          </RadioGroupField>
          <StepperField
            value={size}
            max={150}
            min={50}
            step={5}
            isRequired
            onStepChange={(e) => setSize(e)}
            textAlign="left"
            name="size"
            label="文字サイズ（px）"
            placeholder=""
            marginBottom={20}
          />
          <CheckboxField
            name='closer'
            value='true'
            checked={closer}
            onChange={(e) => setCloser(e.target.checked)}
            label="文字の高さを詰める"
            marginBottom={20}
           />
          {errorMessage && <Alert variation="error">{errorMessage}</Alert>}
          <div className='TextClipSetting__buttons'>
            <Button onClick={() => onClear()}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="click"
              data-amplify-analytics-attrs={`ipid:${ipid},page:TextClipSetting,type:clear`}
            >クリア</Button>
            <Button variation="primary" onClick={() => onSubmit()}
              data-amplify-analytics-on="click"
              data-amplify-analytics-name="click"
              data-amplify-analytics-attrs={`ipid:${ipid},page:TextClipSetting,type:submit`}
            >確定する</Button>
          </div>
        </Card>
      </div>
    </Modal>
    </div>
  );
};
export default TextClipSetting;
