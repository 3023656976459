import './PrivacyPolicy.scss';

const PrivacyPolicy = () => (
  <section className="PrivacyPolicy">
    <div className="PrivacyPolicy__block">
      <h1 className="title-block__info">プライバシーポリシー</h1>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            株式会社ヴィレッジヴァンガードコーポレーション（以下「当社」といいます。）は、
            個人情報保護法をはじめ個人情報保護に関する関係諸法令、および主務大臣のガイドラインに定められた義務を守るとともに、以下の方針によってお客様の個人情報の保護に努めます。
          </p>
        </div>
      </div>
      <h3 className="inner__column__heading--third">1. 個人情報の収集</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            当社は、お客様の個人情報を取得するときは適正な手段で取得します。
            また、あらかじめお客様に同意いただいた以外の目的で利用することは致しません。（法令により例外として扱われる場合を除きます。）
            なお、お申し出やお申し込みなど、当社にいただきましたお電話は内容確認のために録音をさせていただく場合があります。予めご了承ください。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">2. 個人情報の管理</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            当社は、お客様の個人情報を適正に管理し、外部への漏洩防止に努めます。また、適正かつ合理的な安全対策を行い、不正アクセス等による個人情報の紛失、破壊、改ざん等を防止いたします。
          </p>
          <p>
            なお、当社は、個人情報の取扱いを外部企業等に委託する場合があります。この場合、お客様の個人情報の適正な管理を行うために委託契約等により、外部委託先の適正管理と監督を行います。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">3. 個人情報の利用</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>当社は、収集したお客様の個人情報を以下の目的で利用します。</p>
          <p>（１）通信販売・キャンペーンのお知らせをするため</p>
          <p>（２）商品の購入の確認やお届けをするため</p>
          <p>（３）商品・サービスに関するアンケートのお願いをお知らせするため</p>
          <p>（４）キャンペーンやイベント、アンケートへの応募、参加による当選品や謝礼をお届けするため</p>
          <p>（５）メールマガジンを配信するため</p>
          <p>（６）製品、サービスの開発・改善ための調査分析を行うため</p>
          <p>（７）お客様に特別なサービスや新しい製品などの情報を的確にお知らせするため</p>
          <p>（８）お客様のお申し出に対する回答をご連絡するため</p>
          <p>
            <br />
            当社が収集した個人情報の利用は、お客様の権利を損なわないように、十分に配慮して行います。また、お客様の同意なく上記目的の範囲を超えて利用することはいたしません。
            （お客様の事前の同意がある場合や法令により許される場合を除きます。）
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">4. 個人情報の共同利用</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            当社は、個人情報の利用目的での利用、お客様のお申し出、お取引に対応するために、
            必要な範囲内でお客様の氏名、住所、連絡先、性別等を当社グループ会社、運送会社等その他委託先とお客様の個人情報を共同利用する場合があります。この場合、当社が責任をもって個人情報の管理を行います。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">5. 個人情報の提供、開示および訂正・利用停止等</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>当社は、以下の場合を除き、個人情報を第三者に提供しません。</p>
          <p>（１）情報開示や第三者への提供についてお客様の同意がある場合</p>
          <p>（２）裁判所や警察等の公的機関から、法律に基づく正式な照会を受けた場合</p>
          <p>
            （３）当社のホームページ上でのお客様の行為が、当社の権利、財産やサービス等に損害を及ぼす可能性があり、それらの保護のために、必要と認められる場合
          </p>
          <p>（４）生命、身体または財産の保護のため緊急に必要で、お客様の同意を得ることが難しい場合</p>
          <p>
            （５）お客様からの申し出に対応するために、必要な範囲内で当社グループ会社やヴィレッジヴァンガードプレース株式会社、運送会社等その他委託先と共同利用する場合
            <br />
            <br />
          </p>
          <p>
            当社は、個人情報の開示、利用目的の通知をお客様が希望されるときは、法令の規定により適切に対応いたします。なお、個人情報の開示については費用をお客様にご負担いただきます。
          </p>
          <p>
            <br />
            当社は、お客様より、個人情報の訂正、利用停止等のお申し出があったときは、法令の規定によりお申し出がお客様本人からのものであることを確認した後に、遅滞なくその調査を行い、訂正・利用停止等を行います。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">6. 社内体制の整備</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            当社は、このプライバシーポリシーに基づき個人情報の保護に関する社内規定を整備し、当社の従業者に対し、個人情報の取扱いについて継続的な教育を行い、個人情報の保護に努めます。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">7. Cookieの利用について</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            当社ウェブサイトでは、一部のページにおいてCookieを利用している場合がございます。
            Cookieは、ウェブサイトのサーバーとブラウザ間でやりとりする技術です。
            Cookieによりお客様の使用するブラウザを特定しますが、お客様の個人情報を特定するものではありません。
          </p>
          <p>
            Cookieにより、お客様の当社ウェブサイトに対する閲覧の履歴などを記憶して、ウェブ利用をより有益なものにすることができます。
            Cookieはウェブの標準的な技術として一般に普及しており、多くのサイトで、お客様の利便性向上、お客様への広告配信、統計データを取得する目的で使用されています。
          </p>
          <p>
            当社ウェブサイトが提携先のサービスを利用している場合、Cookieを使用して得られた情報は、当社および提携先に対して、集計結果として報告されます。報告にはお客様の閲覧状況などが含まれ、これをもとにお客様が閲覧するサイト上に当社の広告が配信されることがあります。
          </p>
          <p>
            お客様がご自身でCookieの設定を変更することにより、
            Cookieを拒否することができます。ただし、Cookieを拒否した場合には、一部のサイトのサービスがご利用できない場合がございます。
            また、お客様の利用環境（ブラウザの設定、インターネット接続環境）によっては、Cookieを拒否できない場合があります。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">8. 見直し</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            当社の個人情報の保護取り組みについて適宜見直し、改善してまいります。
            当社は、お客様に事前に通知することなく、このプライバシーポリシーを変更することがありますので予めご了承ください。変更したプライバシーポリシーはこのページにてお知らせします。
          </p>
        </div>
      </div>
      <hr />
      <h3 className="inner__column__heading--third">9. お問い合わせ</h3>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            このプライバシーポリシーに関するお問い合わせ・ご意見、お客様の個人情報の開示、訂正、利用停止等のお求めは、当社お客様コールセンター（電話0120-911-790）で受け付けています。
          </p>
        </div>
      </div>
      <div className="section-block js-scroll-target">
        <div className="section-block__wysiwyg">
          <p>
            2023年05月01日 改定
            <br />
            株式会社ヴィレッジヴァンガードコーポレーション
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default PrivacyPolicy;
