import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AdminRouting from './admin/AdminRouting';
import { UserProvider } from './components/providers/UserProvider';
import Front from './Front';
import Commerce from './statics/Commerce';
import Help from './statics/Help';
import NotFound from './statics/NotFound';
import PrivacyPolicy from './statics/PrivacyPolicy';
import Terms from './statics/Terms';

const Routing = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        element={
          <UserProvider>
            <Front />
          </UserProvider>
        }
      />
      <Route
        path="/contents/:ipParam"
        element={
          <UserProvider>
            <Front />
          </UserProvider>
        }
      />
      <Route path="/about/" />
      <Route path="/help" element={<Help />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/commerce" element={<Commerce />} />
      <Route path={`/${process.env.REACT_APP_ADMINPAGE}/*`} element={<AdminRouting />} />
      <Route path="*" element={<NotFound />} 
      />
    </Routes>
  </BrowserRouter>
);

export default Routing;
