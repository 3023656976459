import './PreviewVideo.scss';
import { Button } from '@aws-amplify/ui-react';
import Modal from 'react-modal';

import close from '../img/components/preview/close.png';

import Video from './Video';

type PreviewVideoProps = {
  isOpen: boolean;
  hidePreviewVideo: () => void;
  previewSrc: string;
};

const PreviewVideo = (props: PreviewVideoProps) => {
  const { isOpen, hidePreviewVideo, previewSrc } = props;

  const digit2 = (num: number) => {
    const zeroAddNum = `00${num}`;
    return zeroAddNum.slice( -2 );
  }

  const download = () => {
    let ext;
    if (MediaRecorder.isTypeSupported('video/mp4')) {
      ext = 'mp4';
    } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
      ext = 'webm';
    } else {
      window.alert('動画の撮影ができません。')
    } 
    const url = previewSrc;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    const now = new Date();
    const year = now.getFullYear();
    const month = digit2(now.getMonth() + 1);
    const date = digit2(now.getDate());
    const hour = digit2(now.getHours());
    const min = digit2(now.getMinutes());
    const sec = digit2(now.getSeconds());

    a.download = `${year}${month}${date}${hour}${min}${sec}.${ext}`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    }, 100);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={hidePreviewVideo}
      style={{
        overlay: {
          inset: '10px',
          backgroundColor: 'transparent',
        },
        content: {
          inset: '0',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '40px',
          overflow: 'scroll',
        },
      }}
    >
      <div className="PreviewVideo">
        <div className="PreviewVideo__image">
          <button type="button" className="PreviewVideo__back" onClick={hidePreviewVideo}>
            <img src={close} alt="プレビューを閉じるボタン" />
          </button>
          <Video src={previewSrc} isMuted />
          <Button variation="primary" type='button' className="PreviewVideo__download" onClick={download}>
            ダウンロード
          </Button>
        </div>
        <div className="PreviewButtons">
          <div className="PreviewButtons__back" />
        </div>
      </div>
    </Modal>
  );
};
export default PreviewVideo;
